import {Component, OnInit} from '@angular/core';
import {OrganizationService} from "../../../../swagger/api/organization.service";
import {GeneralService} from "../../../../general.service";

@Component({
  selector: 'app-finances',
  templateUrl: './finances.component.html',
  styleUrls: ['./finances.component.scss']
})
export class FinancesComponent implements OnInit {

  public isLoading = false;
  constructor(private organizationService: OrganizationService, private generalService: GeneralService) {
  }

  ngOnInit(): void {
  }

  goDashboard() {
    this.isLoading = true;
    if (this.generalService?.getUserInfos()?.orgaId) {
      this.organizationService.goToStripeDashboard(this.generalService?.getUserInfos()?.orgaId!).subscribe(url => {
        window.open(url, "_blank");

      }).add(() => {
        this.isLoading = false;
      });
    }
  }
}
