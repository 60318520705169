import {Component, OnInit} from '@angular/core';
import {GamePageService} from "../../../swagger/api/gamePage.service";
import {GamePage} from "../../../swagger/model/gamePage";
import {RouteParamService} from "../../../services/route-param.service";
import {CreerGamepageComponent} from "../../jeu-element/choix-page-popup/creer-gamepage/creer-gamepage.component";
import {MatDialog} from "@angular/material/dialog";
import {
  IndiePassModalMessage,
  IndiePassModalTitle
} from "../../../services/indiepass-modals/indiepass-modals.component";

@Component({
  selector: 'app-gm-game-pages',
  templateUrl: './gm-game-pages.component.html',
  styleUrls: ['./gm-game-pages.component.scss']
})
export class GmGamePagesComponent implements OnInit {
  public gamePages: GamePage[];

  constructor(public gamePageService: GamePageService, private routeParamService: RouteParamService, public dialog: MatDialog,) {
  }
public idGame: number ;
  ngOnInit(): void {
    this.idGame= this.routeParamService.getRouteParameterNumber('idGame')!
    this.refreshGamePageList();
  }

  refreshGamePageList() {
    this.gamePageService.getGamePages(this.idGame).subscribe(gamePages => {
      this.gamePages = gamePages;
    })
  }

  createGamePage(): void {
    const dialogRef = this.dialog.open(CreerGamepageComponent, {
      width: '550px',
      data: this.idGame,
    });
    dialogRef.afterClosed().subscribe(gamePageCreated => {
      if (gamePageCreated) {
        this.refreshGamePageList();
      }
    })

  }
}
