import {Component, OnInit, ElementRef, HostListener, ViewChild} from '@angular/core';

import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";
import {MatSelect} from "@angular/material/select";
import {FeedbackService} from "../swagger/api/feedback.service";

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  @ViewChild(MatSelect) matSelect!: MatSelect;

  dropdownOpen = false;
  feedbackForm: FormGroup;
  showFeedbackForm = false;
  themes = [
    {id: 1, label: 'Report bug'},
    {id: 2, label: 'Suggestion'},
    {id: 3, label: 'Question'},
    {id: 4, label: 'My Account'},
    {id: 5, label: 'My Data'},
    {id: 6, label: 'Partnership/Collaboration'},
    {id: 7, label: 'User Experience'},
    {id: 8, label: 'Feature Request'},
    {id: 9, label: 'Performance Issue'},
    {id: 10, label: 'Security'},
    {id: 11, label: 'Others'}
  ];

  constructor(private formBuilder: FormBuilder,
              private el: ElementRef,
              private router: Router, private feedbackService: FeedbackService) {
  }

  ngOnInit(): void {
    this.feedbackForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      theme: ['', Validators.required],
      message: ['', [Validators.required, Validators.maxLength(1000)]],
      object: ['', [Validators.required, Validators.maxLength(40)]]
    });
  }

  toggleFeedbackForm(): void {
    this.showFeedbackForm = !this.showFeedbackForm;
  }

  reset(): void {
    this.feedbackForm.reset();
  }

  submitFeedback(): void {
    if (this.feedbackForm.valid) {
      const feedbackData = {
        email: this.feedbackForm.get('email')!.value,
        themeId: this.feedbackForm.get('theme')!.value,
        message: this.feedbackForm.get('message')!.value,
        object: this.feedbackForm.get('object')!.value,
        route: this.router.url
      };

      // Appel de l'API
      this.feedbackService.sendFeedback(feedbackData).subscribe(response => {
        this.toggleFeedbackForm();
        this.reset();
      }, error => {
        console.error('Erreur lors de l\'envoi du feedback:', error);
      });
    }
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event) {
    if (!this.el.nativeElement.contains(event.target) && !this.dropdownOpen) {
      this.showFeedbackForm = false;
    }
  }

  onDropdownOpenedChange(opened: boolean): void {
    this.dropdownOpen = opened;
  }

}
