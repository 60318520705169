<div class="notification-list white-theme " (click)="$event.stopPropagation();">
  <div *ngIf="notifications && notifications.length" class="flex-collum">
    <label class="clickable left-auto read-all" (click)="markAllAsRead()">{{"notifs.toutMarquerCommeLu" | translate}}</label>
    <div *ngFor="let notification of notifications" class="notification-item">
      <div (click)="markAsRead(notification)">
        <div class="flex" style="position: relative;">
          <span *ngIf="!notification.isRead" class="unread-indicator"></span>
          <p [class.unread]="!notification.isRead">{{ notification.title }}</p>
          <button #menuTrigger="matMenuTrigger" (click)="$event.stopPropagation();" class="left-auto" mat-icon-button
                  [matMenuTriggerFor]="notificationOptions" aria-label="More options">
            <mat-icon>more_vert</mat-icon>
          </button>
        </div>
        <p *ngIf="notification.description">{{ notification.description | translate }}</p>
        <p class="date">{{ notification.createdAt | date:'short' }}</p>
      </div>

      <mat-menu #notificationOptions="matMenu">
        <button mat-menu-item
                (click)="deleteNotification(notification); $event.stopPropagation(); menuTrigger.closeMenu(); $event.stopPropagation();">
          <mat-icon>delete</mat-icon>
          <span>{{"general.supprimer" | translate}}</span>
        </button>
        <button *ngIf="notification.isRead" mat-menu-item
                (click)="markAsNotRead(notification); menuTrigger.closeMenu(); $event.stopPropagation();">
          <mat-icon>done</mat-icon>
          <span>{{"notifs.marquerCommeNonLu" | translate}}</span>
        </button>

      </mat-menu>
    </div>
  </div>
  <div class="flex-row ip-center" *ngIf="!notifications || !notifications.length">
    <h1>{{"general.vide" | translate}}</h1>
  </div>
</div>
