<div class="main-wrapper">


  <div *ngIf="!generalService?.getUserId()">
    <p>{{"register.vousAvezEtezinviteARejoindreLorganisation" | translate}}{{organization}}</p>
    <div *ngIf="login">
      <app-connexion [emaillForce]="email"></app-connexion>
    </div>
    <div *ngIf="!login">
      <app-enregistrement [emaillForce]="email"></app-enregistrement>
      <br><br>
      Already got an account?
      <br><br>
      Log in now !
      <br><br>
      <button mat-raised-button color="primary" (click)="login = !login">
        Login
      </button>
    </div>
  </div>
  <div *ngIf="generalService?.getUserId()">
    <p> {{generalService.getUserInfos().pseudo}}, {{"register.vousAvezEtezinviteARejoindreLorganisation" | translate}} {{organization}}</p>
  <button [appDisableButton]="isWaiting" mat-flat-button color="primary" (click)="join()">
    {{"general.rejoindre" | translate}}
  </button>
</div>
</div>
