<div class="white-theme">
  <h1>    {{'popup.ajouterUnMembre' | translate}}
  </h1>
  <form  class="example-form">
    <mat-form-field class="form" >
      <mat-label>Email</mat-label>
      <input  type="text"
             placeholder="{{ 'popup.membreàAjouter' | translate }}"
             aria-label="Email"
             matInput
             [formControl]="formControl"
             [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{ option.email }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <button mat-raised-button color="primary" [disabled]="!membersToAdd || !membersToAdd.length" (click)="addMember()">{{'general.ajouter' | translate}}</button>
  </form>
</div>
