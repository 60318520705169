<form #achievementForm="ngForm" class="table-dark-theme">
  <mat-form-field appearance="legacy">
    <input matInput (keyup)="applyFilter($event)" placeholder="{{ 'general.rechercher' | translate }}">
  </mat-form-field>
  <button mat-raised-button (click)="addAchievement()" class="margin-left-15px">
    {{ 'succes.ajouterUnSucces' | translate }} +
  </button>
  <button [appDisableButton]="isSaving" class="white-btn float-right" [disabled]="!achievementForm.valid || !isAllAchievementsGotImage() || !hasChanges()" mat-button
          (click)="sauvegarderAchievement()">{{ 'general.save' | translate }}
  </button>

  <mat-table [dataSource]="dataSource" matSort>
    <!-- API Name Column -->
    <ng-container matColumnDef="apiName">
      <mat-header-cell *matHeaderCellDef>
        {{ 'general.cle' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element?.apiName }}
      </mat-cell>
    </ng-container>

    <!-- Title Column -->
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef [ngClass]="{'missing-data-header': isColumnDataMissing('title')}" mat-sort-header="title">
        {{ 'general.titre' | translate }}*
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field class="field-achievement" floatLabel="never">
          <input matInput maxlength="50" [(ngModel)]="element.title" name="title-{{element.id}}" required #title="ngModel">
          <mat-error *ngIf="title.invalid && (title.dirty || title.touched)">
            Required
          </mat-error>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef>
        {{ 'general.description' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field class="field-achievement" floatLabel="never">
          <input maxlength="100" matInput [(ngModel)]="element.description" name="description-{{element.id}}" #description="ngModel">
          <mat-error *ngIf="description.invalid && (description.dirty || description.touched)">
            Required
          </mat-error>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <!-- Image Column -->
    <ng-container matColumnDef="image">
      <mat-header-cell *matHeaderCellDef [ngClass]="{'missing-data-header': isColumnDataMissing('image')}">
        {{ 'general.image' | translate }}* (256x256)
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button *ngIf="!element?.image" class="button-edit icon-add" (click)="fileInputIcon.click()" type="button" mat-flat-button required>
          {{ 'general.uploaderImage' | translate }}
          <mat-icon class="icon-add">add</mat-icon>
        </button>
        <mat-spinner diameter="50" *ngIf="element?.image=='load'"></mat-spinner>
        <img *ngIf="element?.image && element?.image!='load'" class="image-32x32" [src]="environment.urlimgcdn + idGame + '/achievements/' + element?.image" alt="unlock_img" (click)="fileInputIcon.click()">
        <input style="display:none;" #fileInputIcon (change)="imageUpload($event, element)" type="file" name="image" accept="image/png, image/jpeg, image/jpg "/>
      </mat-cell>
    </ng-container>

    <!-- Hidden Column -->
    <ng-container matColumnDef="isHidden">
      <mat-header-cell *matHeaderCellDef>
        {{ 'achievements.estCache' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-checkbox [checked]="element.isHidden" (change)="onCheckboxChange(element)"></mat-checkbox>
      </mat-cell>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="deleteAchievement(element)">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
</form>
