<div class="table-dark-theme dashboard-size">

  <mat-form-field appearance="legacy">
    <input matInput (keyup)="applyFilter($event)" placeholder="{{ 'general.rechercher' | translate }}">
  </mat-form-field>
  <button *ngIf="this.organizationContextService.getMemberContext()!.role !== 'regular'" class="float-right"
          (click)="creerJeu()" mat-raised-button color="primary">{{ 'dashboard.editerUnNouveauJeu'| translate }}
  </button>

  <mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="title">
      <mat-header-cell mat-sort-header="title" mat-header-cell *matHeaderCellDef> {{ 'general.titre' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.title }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell mat-header-cell *matHeaderCellDef>{{ 'general.statut' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.state }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="publisher">
      <mat-header-cell mat-header-cell *matHeaderCellDef>{{ 'general.editeur' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.publisher.name }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="developer">
      <mat-header-cell mat-header-cell *matHeaderCellDef>{{ 'general.developpeur' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.developer.name }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">

      <mat-header-cell mat-header-cell *matHeaderCellDef>{{ 'general.action' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element" class="gap-15">
        <button *ngIf="element.lienAccepte" mat-raised-button
                (click)="goToGameManager(element)">{{ 'general.gerer' | translate }}
        </button>
        <button *ngIf="element.lienAccepte && !isGameInLibrary(element.id!)" mat-raised-button
                (click)="addToLibrarie(element)">{{ 'general.ajouterAMaLibrairie' | translate }}
        </button>
        <button *ngIf="!element.lienAccepte && orgid ==  element.developer?.id" color="primary" mat-raised-button
                (click)="updateLinkState(element,true)">{{ 'general.accepter' | translate }}
        </button>
        <button *ngIf="!element.lienAccepte && orgid ==  element.developer?.id" color="warn" mat-raised-button
                (click)="updateLinkState(element,false)">{{ 'general.refuser' | translate }}
        </button>
        <button *ngIf="!element.lienAccepte && orgid ==  element.publisher?.id" color="warn"  mat-raised-button
                (click)="updateLinkState(element,false)">{{ 'general.annuler' | translate }}
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[ 10, 20, 50]" showFirstLastButtons></mat-paginator>
</div>
