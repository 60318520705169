<div class="container">
  <br>
  <div fxLayoutGap="20px">
    <button mat-flat-button color="accent">
      Réinitialiser Stripe
    </button>
    <button [disabled]="waitingEditeurCreation" (click)="creerEditeurs()" mat-flat-button color="accent">
      Créer comptes éditeurs <mat-spinner diameter="24" *ngIf="waitingEditeurCreation"></mat-spinner>
    </button>
    <button   [disabled]="waitingPlayerCreation" (click)="creerJoueurs()" mat-flat-button color="accent">
      Créer comptes joueurs <mat-spinner diameter="24" *ngIf="waitingPlayerCreation"></mat-spinner>
    </button>
    <button [disabled]="waitingForceSub" (click)="forcerSubscribes()" mat-flat-button color="accent">
      Forcer sub <mat-spinner diameter="24" *ngIf="waitingForceSub"></mat-spinner>
    </button>
    <button [disabled]="waitingAddOneHourToAll" (click)="addOneHourToAll()" mat-flat-button color="accent">
     add one hour <mat-spinner diameter="24" *ngIf="waitingAddOneHourToAll"></mat-spinner>
    </button>
    <button [disabled]="waitingForcerPaiementAll" (click)="forcerPaiementAll()" mat-flat-button color="accent">
     forcer paiement <mat-spinner diameter="24" *ngIf="waitingForcerPaiementAll"></mat-spinner>
    </button>
  </div>
  <br>

  <mat-tab-group>
    <mat-tab label="Editeurs">
      <mat-form-field>
        <mat-label>Rechercher</mat-label>
        <input [(ngModel)]="filterEditeurs" matInput (keyup)="applyFilterEditeur(filterEditeurs)">
      </mat-form-field>
      <mat-table [dataSource]="dataSourceEditeur">
        <!-- Pseudo Column -->
        <ng-container matColumnDef="pseudo">
          <mat-header-cell *matHeaderCellDef> Pseudo</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.nickName}} </mat-cell>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef> Email</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
        </ng-container>

        <!-- Pays Column (Supposant que le champ pays existe) -->
        <ng-container matColumnDef="pays">
          <mat-header-cell *matHeaderCellDef> Pays</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.country}}</mat-cell>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> Actions</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div fxLayoutGap="20px">
              <button mat-flat-button color="primary" (click)="dashboard(element)">Stripe Dashboard</button>
              <button mat-flat-button color="primary" (click)="goFinish(element)">Stripe Inscription</button>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

      </mat-table>
    </mat-tab>



    <mat-tab label="Joueurs">
      <mat-form-field>
        <mat-label>Rechercher</mat-label>
        <input [(ngModel)]="filterGamers" matInput (keyup)="applyFilterGamers(filterGamers)">
      </mat-form-field>
      <mat-table [dataSource]="dataSourcePlayers">
        <!-- Pseudo Column -->
        <ng-container matColumnDef="pseudo">
          <mat-header-cell *matHeaderCellDef> Pseudo</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.nickName}} </mat-cell>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef> Email</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
        </ng-container>

        <!-- Pays Column (Supposant que le champ pays existe) -->
        <ng-container matColumnDef="pays">
          <mat-header-cell *matHeaderCellDef> Pays</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.country}}</mat-cell>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> Actions</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div fxLayoutGap="20px">
              <button mat-flat-button color="primary" (click)="dashboard(element)">Stripe Dashboard</button>
              <button mat-flat-button color="primary" (click)="goFinish(element)">Stripe Inscription</button>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

      </mat-table>
    </mat-tab>
  </mat-tab-group>

</div>
