<div class="main-wrapper white-theme">
  <div class="ip-content">
    <div class="ip-area">
      <h1>{{ "general.membres" | translate }}</h1>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-form-field class="white-theme">
          <input matInput (keyup)="applyFilter($event)" placeholder="{{'general.rechercher' | translate}}">
        </mat-form-field>
        <button *ngIf="this.organizationContextService.getMemberContext()!.role !== 'regular'"
                (click)="openPopupAddMember()" mat-raised-button color="primary">
          <mat-icon>group_add</mat-icon>
        </button>

      </div>
      <br>
      <div class="table-dark-theme">
        <div class="table-header" fxLayoutAlign="start">
        </div>
        <table mat-table [dataSource]="dataSource" matSort>


          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>

            <td mat-cell *matCellDef="let member">
              <mat-icon [matTooltip]="'Admin'" *ngIf="member.role === 'admin'">groups_3</mat-icon>
              <mat-icon [matTooltip]="'Owner'" *ngIf="member.role === 'owner'">military_tech</mat-icon>
              <mat-icon [matTooltip]="'Member'" *ngIf="member.role === 'regular'">person</mat-icon>
              <mat-icon [matTooltip]="'Invitation pending'" *ngIf="!member.role">hourglass_empty</mat-icon>
            </td>
          </ng-container>
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email</th>

            <td mat-cell *matCellDef="let member">

              {{ member.email }}
            </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
            <td mat-cell *matCellDef="let member">
              <button (click)="openPopupDeleteMember(member)" *ngIf="canDelete(member)" mat-icon-button>
                <mat-icon [matTooltip]="'Remove'">delete</mat-icon>
              </button>
              <button  (click)="openPopupModifyMember(member)" *ngIf="member.role && userInfos.memberRole?.toLowerCase() === 'owner' && member.role !== 'owner'"
                      mat-icon-button>
                <mat-icon [matTooltip]="'Infos'">badge</mat-icon>
              </button>
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div>

    </div>
  </div>
</div>
