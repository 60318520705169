<div id="main">

  <div class="layer">

  </div>

</div>

<div id="presentation" fxLayout="row">
  <div class="col-1">
    <h2 class="titre-sousligne" fxLayout="row" fxLayoutGap="5px">
      <div  [routerLink]="'/dashboard/' + this.generalService?.getOrganizationId() + '/games/'+idGame+'/manage/pages'"
           class="go-back z-index-1"> {{ game?.title }}
      </div>
      -
      <div>{{ gamePage?.title }}</div>
    </h2>
    <div class="container-iframe-video">
      <div *ngIf="!gamePage?.urlTrailer"
           class="game-cover-place-holder text-place-holder-video">
      </div>
      <div class="container-image-16-9 container-iframe-video" *ngIf=" creationJeu?.videoPercentage! >= 0">

        <mat-spinner [value]="creationJeu?.videoPercentage" [mode]="'determinate'"></mat-spinner>
      </div>
      <mat-spinner *ngIf="gamePage?.urlTrailer && !creationJeu?.videoPercentage && !canShowVideo"
                   [mode]="'indeterminate'"></mat-spinner>
      <video *ngIf="gamePage?.urlTrailer && !creationJeu?.videoPercentage && canShowVideo" class="iframe-video"
             controls>
        <source
          [src]="environment.urlimgcdn+idGame+'/video/'+gamePage?.urlTrailer">
        Your browser does not support the video tag.
      </video>
    </div>
    <div class="infos-trailer" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center">
      <label>
        Trailer -  {{ "gamepage.englishOnly" | translate }} <span class="required"
                                                                  [ngClass]="{'error': !gamePage?.urlTrailer}">*</span>
        <mat-icon [matTooltipPosition]="'right'" [matTooltip]="'helper.trailerPartagePourToutesLesLangues' | translate">
          help
        </mat-icon>
      </label>
      <div fxLayout="row" fxLayoutAlign="center">
        <button *ngIf="!viewOnly && languageSelected === 'EN'" (click)="fileInputVideo.click()" type="button" mat-button
                [disableRipple]="true">

          <mat-icon>edit</mat-icon>
          <input style="display:none;" #fileInputVideo (change)="imageUpload($event,'video')" type="file"
                 name="image" accept="video/mp4,video/avi,video/webm"/>
        </button>

      </div>
    </div>
    <div
      *ngIf=" !creationJeu?.gamePageTranslation?.graphics?.screenshots ||  creationJeu?.gamePageTranslation?.graphics?.screenshots!.length == 0"
      class="container-iframe-video screenShots infos-trailer">
      <div class="text-place-holder-video" *ngIf="creationJeu">

      </div>
    </div>
    <app-carrousel
      *ngIf="idGame && creationJeu?.gamePageTranslation?.graphics?.screenshots &&  creationJeu?.gamePageTranslation?.graphics?.screenshots!.length > 0"
      [gameId]="idGame" [images]="creationJeu?.gamePageTranslation?.graphics?.screenshots!"></app-carrousel>

    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div>
        {{ "general.galerie" | translate }} - 1280x720 <span class="required"
                                                             [ngClass]="{'error': !isValidScreenshotCount()}">*</span>
      </div>
      <div>
        Min 3   {{ "general.images" | translate }} - max 7
        <button *ngIf="!viewOnly" [matTooltip]="'minimum 3 images'" (click)="showModalUploadImages()"
                type="button"
                mat-button
                [disableRipple]="true">
          <mat-icon class="icon-edit">edit</mat-icon>
          <input multiple style="display:none;" (change)="imageUpload($event,'illustrations')"
                 type="file"
                 name="image" accept="image/png, image/jpeg, image/jpg"/>
        </button>
      </div>

    </div>
    <button class="button-duplicate-caroussel" *ngIf="languageSelected!=='EN' && !viewOnly" (click)="duplicateIllustrationsFromEnglish()" color="primary"
            type="button"
            mat-flat-button>{{ "gamepage.dupliquerDepuisLanglais" | translate }}
    </button>
  </div>

  <div class="col-2">
    <div>
      <mat-form-field appearance="fill">
        <mat-label>{{ "general.language" | translate }}</mat-label>
        <mat-select [disabled]="!this.areAllImagesLoaded() || videoLoading" [(ngModel)]="languageSelected"
                    (valueChange)="onLanguageSelect($event, languageSelected)">
          <mat-option *ngFor="let langue of availableLanguages" [value]="langue.code">
            {{ langue.libelle }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-icon-button *ngIf="languageSelected !== 'EN' && !viewOnly" (click)="deleteByLanguage()">
        <mat-icon>delete</mat-icon>
      </button>
      <button *ngIf="!viewOnly" class="button-save" [disabled]="!canSave()" (click)="sauvegarger()" color="primary"
              type="button"
              mat-flat-button>{{ "general.save" | translate }}
      </button>
    </div>
    <h2 class="titre-sousligne"> {{ "general.description" | translate }} <span class="required"
                                                                               [ngClass]="{'error': !isDescriptionValid()}">*</span>
    </h2>
    <div class="description">
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label style="color: whitesmoke">{{ "gamepage.decrivezVotreJeuEntre50Et512Caracteres" | translate }}
        </mat-label>
        <textarea [readonly]="viewOnly" #message [minlength]="50" maxlength="512" style="resize: none; height: 180px"
                  [(ngModel)]="creationJeu.gamePageTranslation!.description"
                  (input)="limitLineBreaks(message, $event)"
                  matInput></textarea>
        <mat-hint class="required" [class.error]="message && message!.value!.length!<50"
                  align="end">{{ message.value.length }} / 512
        </mat-hint>
      </mat-form-field>
    </div>

    <div class="titre-sousligne">
      <div  fxLayout="row" fxLayoutAlign="space-between end">
        <h2 class=" titre-images-promo">{{ "gamepage.imagesPromotionelles" | translate }}</h2>
        <button *ngIf="languageSelected!=='EN' && !viewOnly" (click)="duplicatePromoPicturesFromEnglish()"
                color="primary"
                class="z-index-1"
                type="button"
                mat-flat-button>{{ "gamepage.dupliquerDepuisLanglais" | translate }}
        </button>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">

    </div>

    <div fxLayout="row" fxLayoutAlign="space-between center" class="images-promo">
      <div class="container-image-16-9 image-separateur">
        <input style="display:none;" #fileInputCover (change)="imageUpload($event,'cover')" type="file"
               name="image" accept="image/png, image/jpeg, image/jpg"/>
        <div class="text-place-holder-video" *ngIf="creationJeu" (click)="fileInputCover.click()">
          <img
            *ngIf="creationJeu.gamePageTranslation?.graphics?.cover?.fileName && !creationJeu.gamePageTranslation!.graphics!.cover?.loading"
            [src]="environment.urlimgcdn+idGame+'/'+creationJeu.gamePageTranslation!.graphics!.cover!.fileName+'?class=screenshot'">
          <mat-spinner *ngIf="creationJeu.gamePageTranslation?.graphics?.cover?.loading"></mat-spinner>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <p>
            {{ "general.couverture" | translate }} <span class="required"
                                                         [ngClass]="{'error': !creationJeu.gamePageTranslation?.graphics?.cover?.fileName}">*</span><br>
            1280x720
          </p>
          <button *ngIf="!viewOnly" (click)="fileInputCover.click()" type="button" mat-button
                  [disableRipple]="true">
            <mat-icon class="icon-edit">edit</mat-icon>
          </button>
        </div>
      </div>
      <div class="container-image-1-1 image-separateur">
        <input style="display:none;" #fileInputIcon (change)="imageUpload($event,'icon')" type="file"
               name="image" accept="image/png, image/jpeg, image/jpg"/>
        <div class="text-place-holder-video" *ngIf="creationJeu" (click)="fileInputIcon.click()">
          <img
            *ngIf="creationJeu.gamePageTranslation?.graphics?.icon?.fileName && !creationJeu.gamePageTranslation!.graphics!.icon?.loading"
            [src]="environment.urlimgcdn+idGame+'/'+creationJeu.gamePageTranslation!.graphics!.icon!.fileName+'?class=screenshot'">
          <mat-spinner *ngIf="creationJeu.gamePageTranslation?.graphics?.icon?.loading"></mat-spinner>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <p>
            {{ "gamepage.icone" | translate }} <span class="required"
                                                     [ngClass]="{'error': !creationJeu.gamePageTranslation?.graphics?.icon?.fileName}">*</span><br>
            128x128
          </p>
          <button *ngIf="!viewOnly" (click)="fileInputIcon.click()" type="button" mat-button
                  [disableRipple]="true">
            <mat-icon class="icon-edit">edit</mat-icon>
          </button>
        </div>
      </div>
      <div class="container-image-16-9 image-separateur ">
        <input #fileInputBackGround style="display:none;" (change)="imageUpload($event,'background')" type="file"
               accept="image/png, image/jpeg, image/jpg"/>
        <div class="text-place-holder-video" *ngIf="creationJeu" (click)="fileInputBackGround.click()">
          <img
            *ngIf="creationJeu.gamePageTranslation?.graphics?.background?.fileName && !creationJeu.gamePageTranslation!.graphics!.background?.loading"
            [src]=" environment.urlimgcdn +idGame+'/'+creationJeu?.gamePageTranslation?.graphics?.background?.fileName+'?class=screenshot'">
          <mat-spinner *ngIf="creationJeu.gamePageTranslation?.graphics?.background?.loading"></mat-spinner>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <p>
            {{ "gamepage.backGround" | translate }} <span class="required"
                                                          [ngClass]="{'error': !creationJeu.gamePageTranslation?.graphics?.background?.fileName}">*</span><br>1280x720
          </p>
          <button *ngIf="!viewOnly" (click)="fileInputBackGround.click()" type="button" mat-button
                  [disableRipple]="true">
            <mat-icon class="icon-edit"> edit</mat-icon>
          </button>
        </div>
      </div>
      <div class="container-image-3-4">
        <input style="display:none;" #fileInputFeatured (change)="imageUpload($event,'featured')" type="file"
               name="image" accept="image/png, image/jpeg, image/jpg"/>
        <div class="text-place-holder-video" *ngIf="creationJeu" (click)="fileInputFeatured.click()">
          <img
            *ngIf="creationJeu.gamePageTranslation?.graphics?.featured?.fileName && !creationJeu.gamePageTranslation!.graphics!.featured?.loading"
            [src]=" environment.urlimgcdn+idGame +'/'+creationJeu?.gamePageTranslation?.graphics?.featured?.fileName+'?class=screenshot'">
          <mat-spinner *ngIf="creationJeu.gamePageTranslation?.graphics?.featured?.loading"></mat-spinner>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <p>
            {{ "general.couverture" | translate }} <span class="required"
                                                         [ngClass]="{'error': !creationJeu.gamePageTranslation?.graphics?.featured?.fileName}">*</span><br>
            720x960
          </p>
          <button *ngIf="!viewOnly" (click)="fileInputFeatured.click()" type="button" mat-button
                  [disableRipple]="true">
            <mat-icon class="icon-edit">edit</mat-icon>
          </button>
        </div>
      </div>
    </div>

  </div>
