<div class="main-wrapper white-theme">
  <div class="ip-content">
    <div class="ip-area">
      <h1>{{ 'mdp.reinitialisationDuMDP' | translate }}</h1>
      <br>
      <form class="example-form" [formGroup]="checkoutForm" *ngIf="canSowUp">
        <mat-form-field class="example-full-width  input-field" appearance="fill">
          <mat-label>{{ 'connexion.entrezVotreMdp' | translate }}</mat-label>
          <input autocomplete="new-password" maxlength="50" formControlName="password" required matInput
                 [type]="hide ? 'password' : 'text'">
          <mat-error *ngIf="!samePassword">{{ getErrorMessage("password")| translate }}</mat-error>
          <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide">
            <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
        </mat-form-field>
        <br>
        <mat-form-field class="example-full-width  input-field" appearance="fill">
          <mat-label>{{ 'connexion.entrezVotreMdp' | translate }}</mat-label>
          <input autocomplete="new-password" maxlength="50" required formControlName="passwordbis" matInput
                 [type]="hideConfirm ? 'password' : 'text'">
          <mat-error *ngIf="!samePassword">{{ getErrorMessage("passwordbis")| translate }}</mat-error>
          <button type="button" mat-icon-button matSuffix (click)="hideConfirm = !hideConfirm"
                  [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hideConfirm">
            <mat-icon>{{ hideConfirm ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>

        </mat-form-field>
      </form>
      <button [disabled]="!checkoutForm.valid" mat-raised-button color="primary" (click)="sendNewPassword()"
              class="btn-block">{{ 'general.valider' | translate }}
      </button>
    </div>
  </div>
</div>
