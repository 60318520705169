import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Utilisateur} from "../../../swagger/model/utilisateur";
import {OrganizationService} from "../../../swagger/api/organization.service";
import {AddMemberComponent} from "./add-member/add-member.component";
import {MatDialog} from "@angular/material/dialog";
import {RouteParamService} from "../../../services/route-param.service";
import {Member} from "../../../swagger/model/member";
import RoleEnum = Member.RoleEnum;
import {GeneralService} from "../../../general.service";
import {OrganizationContextService} from "../../../services/organization-context.service";
import {DeleteMemberComponent} from "./delete-member/delete-member.component";
import {UserInfos} from "../../../swagger/model/userInfos";
import {ModifyMemberRoleComponent} from "./modify-member-role/modify-member-role.component";

@Component({
  selector: 'app-membres',
  templateUrl: './membres.component.html',
  styleUrls: ['./membres.component.scss']
})
export class MembresComponent implements OnInit {
  displayedColumns: string[] = ['role', 'email', 'actions'];
  dataSource: MatTableDataSource<Member>;
  private id: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public userInfos: UserInfos;

  constructor(private organizationService: OrganizationService,
              public generalService: GeneralService,
              public organizationContextService: OrganizationContextService,
              public dialog: MatDialog, private routeParamService: RouteParamService) {
  }

  ngOnInit() {
    this.refreshMembers();
    this.userInfos = this.generalService.getUserInfos();
  }
refreshMembers(){
  this.organizationService.getMembers(this.routeParamService.getRouteParameterNumber('idOrganization')!).subscribe((members: Utilisateur[]) => {
    this.dataSource = new MatTableDataSource(members);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  });
}
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openPopupAddMember() {
    const dialogRef = this.dialog.open(AddMemberComponent, {
      width: '550px',
      data: this.id
    });
    dialogRef.afterClosed().subscribe(game => {
      this.refreshMembers();

    });
  }

  openPopupDeleteMember(member: Member) {
    const dialogRef = this.dialog.open(DeleteMemberComponent, {
      width: '550px',
      data: {idMember: member.id, email: member.email}
    });
    dialogRef.afterClosed().subscribe(game => {
      this.refreshMembers();


    });
  }

  openPopupModifyMember(member: Member) {
    const dialogRef = this.dialog.open(ModifyMemberRoleComponent, {
      width: '550px',
      data: {member: member}
    });
    dialogRef.afterClosed().subscribe(game => {
      this.refreshMembers();

    });
  }

  canDelete(member: Member): boolean {
    if (member.role === RoleEnum.Owner) {
      return false;
    }
    if (member.email === this.generalService.getUserInfos().email) {
      return true;
    }
    if (this.organizationContextService.getMemberContext()!.role === RoleEnum.Owner) {
      return true;
    }
    if (this.organizationContextService.getMemberContext()!.role === RoleEnum.Admin && member.role === RoleEnum.Regular) {
      return true;
    }
    return false;
  }
}
