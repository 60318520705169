<div fxLayoutAlign="space-around center">
  <mat-list>
    <mat-list-item *ngFor="let page of gamePages">
      <app-gm-game-page-element [parent]="this" [gamePage]="page" [idGame]="idGame"
                                [title]="page?.title!"></app-gm-game-page-element>
    </mat-list-item>
  </mat-list>
</div>
<div class="main-wrapper">

  <button [disabled]="!gamePages || gamePages.length>4" mat-raised-button (click)="createGamePage()">{{ 'general.nouveau' | translate }}</button>

</div>


