import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {catchError, finalize, from, mergeMap, Observable, switchMap, throwError, timer} from 'rxjs';
import {AuthService} from "../swagger/api/auth.service";
import {CookieService} from "../cookie.service";
import {Router} from "@angular/router";
import {GeneralService} from "../general.service";
import {environment} from "../../environments/environment";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private isRefreshing = false;

  constructor(
    private authService: AuthService,
    private cookieService: CookieService,
    private generalService: GeneralService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(environment.baseUrlAPI) && !req.url.includes("auth/login") && !this.generalService.getIsinclient() && !req.url.includes("auth/logout") && !req.url.includes("auth/refresh")) {
      if (this.cookieService.getCookie('is_auth') === 'true') {
        return next.handle(req);
      } else if (this.cookieService.getCookie('can_refresh') === 'true' && !this.isRefreshing) {
        console.log('refreshing token')
        this.isRefreshing = true;
        //  this.cookieService.deleteCookie('can_refresh');
        // Ajout d'un délai après refreshAuth et avant de continuer avec next.handle
        return this.authService.refreshAuth().pipe(
          switchMap(() => timer(100).pipe(switchMap(() => next.handle(req)))),
          catchError((error) => {
            return throwError(error);
          }),
          finalize(() => {
            this.isRefreshing = false; // S'exécute après le succès ou l'échec de refreshAuth
          })
        );
      } else {
        return next.handle(req);
      }
    } else if (this.generalService.getIsinclient() && req.url.startsWith(environment.baseUrlAPI)) {

            req = req.clone({
              setHeaders: {
                Authorization: `Bearer ${this.generalService.getJwtClient()}`
              }
            });
          return next.handle(req);

    } else {

      return next.handle(req);
    }
  }

}
