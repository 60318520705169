import {AfterContentInit, AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {GameLight} from "../../swagger/model/gameLight";
import {environment} from "../../../environments/environment";
import {ItemLibrary} from "../../swagger/model/itemLibrary";
import {GameService} from "../../swagger/api/game.service";
import {UserService} from "../../swagger/api/user.service";
import {MatDialog} from "@angular/material/dialog";
import {MatMenuTrigger} from "@angular/material/menu";
import {Router} from "@angular/router";
import {GameSearchResult} from "../../swagger/model/gameSearchResult";

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss']
})
export class GameComponent implements OnInit {
  imageLoaded = false;
  @Input() public game: GameSearchResult = {} as GameSearchResult;

  constructor(private router: Router, private gameService: GameService, public dialog: MatDialog) {

  }

  navigateToGame() {
    this.router.navigate(['/game/' + this.game.id]);
  }

  ngOnInit(): void {

  }


  public readonly environment = environment;
}
