import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ImageGamePage} from "../swagger/model/imageGamePage";
import {GameLight} from "../swagger/model/gameLight";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-carrousel',
  templateUrl: './carrousel.component.html',
  styleUrls: ['./carrousel.component.scss']
})
export class CarrouselComponent implements OnInit {

  @Input() images: ImageGamePage[] = [];
  @Input() video: string;
  videoUrl: SafeResourceUrl | undefined = undefined;
  videoUrlMiniature: string | undefined = undefined;
  @Input() gameId: number;
  imageSelected: ImageGamePage | undefined;
  @ViewChild('listImgPreview') listImgPreview: ElementRef;
  imageIsLoading = true;
  miniaturesLoading = true;
  constructor(public sanitizer: DomSanitizer,) {
  }
  imagesLoadedCount = 0;
  ngOnInit(): void {
    this.imageSelected = this.images[0];
    this.imagesLoadedCount
    if(this.video) {
      this.updateVideoUrl(this.video);
    }
  }
  onImageLoad(image : ImageGamePage) {
    this.imagesLoadedCount++
    if (this.imagesLoadedCount === this.images.length) {
    this.miniaturesLoading = false;
    }
  }
  selectImage(image: ImageGamePage | undefined): void {
    this.imageSelected = image;
    if (image)
      this.scrollToImage(image);
  }

  scrollToImage(image: ImageGamePage): void {
    const index = this.images.indexOf(image);
    const previewElement = this.listImgPreview.nativeElement.children[index] as HTMLElement;
    previewElement.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'});
  }

  updateVideoUrl(id: string) {
    let dangerousVideoUrl = 'https://www.youtube.com/embed/' + id;
    this.videoUrlMiniature = 'https://img.youtube.com/vi/' + id+"/0.jpg";
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(dangerousVideoUrl);
  }
}
