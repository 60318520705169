import { Pipe, PipeTransform } from '@angular/core';
import {environment} from "../../environments/environment";
import {ImageGamePage} from "../swagger/model/imageGamePage";
import {GameLight} from "../swagger/model/gameLight";

@Pipe({
  name: 'buildImageUrl'
})
export class BuildImageUrlPipe implements PipeTransform {

  transform(imageName: String, gameId: number): string {
    if (!imageName) return "";
    return `${environment.urlimgcdn}${gameId}/${imageName}?class=screenshot`;
  }
}

export enum ImageTypeEnum {
  COVER,
  BACKGROUND,
  FEATURED,
  SCREENSHOT
}
