<mat-sidenav-container class="sidenav-container" [class.side-nav-web]="this.isInWeb" *ngIf="!presentation">
  <mat-sidenav #sidenav [mode]="isLargeScreen ? 'side' : 'over'" [opened]="isLargeScreen">
    <app-filtres *ngIf="!isInLibrary" [class.side-nav-web]="this.isInWeb"></app-filtres>
  </mat-sidenav>
  <mat-sidenav-content>
    <button *ngIf="!sidenav.opened && !isInLibrary" mat-flat-button (click)="sidenav.toggle()" class="button-toggle">
      filters
    </button>
    <div class="flex row">
      <div class="list-games">
        <app-game *ngFor="let game of games" [game]="game"></app-game>
      </div>
      <div class="list-library" *ngIf="libraryItems?.length">
        <app-item-library *ngFor="let libraryItem of libraryItems" [libraryItem]="libraryItem"></app-item-library>
      </div>
    </div>
    <div *ngIf="isFetchingGames" class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!isFetchingGames  && !games.length && !libraryItems.length" class="spinner-container">
      <h1>{{ "search.AucunJeuTrouve" | translate }}</h1>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
<div *ngIf="presentation" class="comming-soon">
  <h1>Comming soon</h1>
</div>
