<div class="white-theme wrapper-popup">
  <h1 mat-dialog-title>{{ 'popup.supprimerUnMembre' | translate }}</h1>
  <div>
    {{ 'general.supprimer' | translate }} <b> {{ data?.email }} </b>{{ 'popup.deCetteOrganisation' | translate | lowercase}} ?
    <div mat-dialog-actions>
      <button (click)="onNoClick()" mat-flat-button color="warn">
        {{ 'general.annuler' | translate }}
      </button>
      <button (click)="deleteMember()" [appDisableButton]="isLoading" mat-flat-button color="primary">
        {{ 'general.valider' | translate }}
      </button>
    </div>
  </div>
</div>
