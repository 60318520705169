import {Component, ElementRef, Inject, inject, NgZone, OnInit, ViewChild} from '@angular/core';
import {MatButtonModule} from "@angular/material/button";
import {MAT_SNACK_BAR_DATA, MatSnackBarModule, MatSnackBarRef} from "@angular/material/snack-bar";

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],

})
export class SnackBarComponent {
  message: string;
  action: string;
  @ViewChild('rectangle') rectangle: ElementRef;
  constructor(
    public snackBarRef: MatSnackBarRef<SnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) {

    this.message = data.message;
    this.action = data.action;

  }
}
