import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {FiltresInterne} from "../../model/FiltresInterne";

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private filtersSource = new BehaviorSubject<any>({});
  currentFilters = this.filtersSource.asObservable();
  constructor() { }

  restoreFilters() :FiltresInterne {
    return this.filtersSource.value;
  }
  changeFilters(filters: FiltresInterne) {
    this.filtersSource.next(filters);
  }
}
