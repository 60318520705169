import {Component, Input, OnInit, HostListener} from '@angular/core';
import {Achievement} from 'src/app/swagger/model/achievement';
import {GameLight} from 'src/app/swagger/model/gameLight';
import {ImageGamePage} from "../../swagger/model/imageGamePage";

@Component({
  selector: 'app-achievements',
  templateUrl: './achievements.component.html',
  styleUrls: ['./achievements.component.scss']
})
export class AchievementsComponent implements OnInit {

  @Input() achievements: Achievement[] = [];
  @Input() gameLight: GameLight;

  visibleAchievements: Achievement[] = [];
  showMoreButton = false;
  maxVisibleAchievements = 6;
  loading = true;
  imagesLoadedCount = 0;

  constructor() {
  }

  ngOnInit(): void {
    this.updateVisibleAchievements();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.updateVisibleAchievements();
  }

  updateVisibleAchievements(): void {
    const screenWidth = window.innerWidth;

    if (screenWidth <= 1390) {
      this.maxVisibleAchievements = 3;
    } else if (screenWidth <= 1500) {
      this.maxVisibleAchievements = 5;
    } else {
      this.maxVisibleAchievements = 6;
    }

    if (this.achievements.length > this.maxVisibleAchievements) {
      this.visibleAchievements = this.achievements.slice(0, this.maxVisibleAchievements);
      this.showMoreButton = true;
    } else {
      this.visibleAchievements = this.achievements;
      this.showMoreButton = false;
    }
  }

  onImageLoad(image: ImageGamePage) {
    this.imagesLoadedCount++
    if (this.imagesLoadedCount === this.visibleAchievements.length) {
      this.loading = false;
    }
  }

  onShowMore(): void {
    console.log('Afficher plus cliqué');
    // Logique pour afficher tous les succès peut être ajoutée ici
  }
}
