<div class="wrapper-about">
  <div class="main">
    <h2>{{ 'aPropos.mentionsLegales' | translate }}</h2>
    <div class="borderMenu"></div>
    <div class="wrapper">
      <div class="wrapper-mentions">
        <div>
          <h2>IndiePass France</h2>
          <p>
            {{ 'general.adresse' | translate }} : BUREAU 3, 50 BD STALINGRAD, 06300 NICE<br>
            {{ 'general.contact' | translate }}  : contact@indiepass.com<br>
            RCS : 977 925 254 R.C.S. Nice<br>
            {{ 'general.TVA' | translate }} : FR07977925254<br>
          </p>
        </div>
        <div>
          <h2> {{ 'aPropos.hebergeur' | translate }} : Infomaniak Network SA</h2>
          <p>
            {{ 'general.adresse' | translate }} : Rue Eugène Marziano 25, 1227 Les Acacias (GE)<br>
            RCS : CH-660.0.059.996-1<br>
            {{ 'general.TVA' | translate }} : CHE-103.167.648<br>
          </p>
        </div>
      </div>
      <button (click)="showCookieOption()" class="btn-conditions"
              mat-stroked-button>{{ 'aPropos.gererLesCookies' | translate }}
      </button>
      <div id="center" class="centered-content">
        <div *ngIf="false" class="politique-wrapper">
          <h3>{{ 'aPropos.politiqueUtilisationCookies' | translate }}</h3>
          <div class="borderMenu"></div>
          <p class="politique">
            Les cookies sont des fichiers texte qui sont stockés sur votre ordinateur ou votre appareil mobile lorsque
            vous
            visitez un site Web. Ils sont largement utilisés pour que les sites Web fonctionnent ou fonctionnent plus
            efficacement. Ils peuvent également être utilisés pour fournir des informations aux propriétaires du site.
            Les cookies que nous utilisons sur notre site Web nous permettent de reconnaître les utilisateurs qui
            reviennent
            sur notre site et de nous aider à comprendre comment nos visiteurs utilisent notre site.
            Nous utilisons des cookies pour améliorer l'expérience de l'utilisateur, y compris pour stocker vos
            préférences,
            pour vous permettre de vous connecter et de rester connecté, pour fournir un contenu personnalisé et pour
            analyser l'utilisation de notre site.
            Nous utilisons également des cookies pour gérer notre programme d'affiliation.
            La plupart des navigateurs Web acceptent automatiquement les cookies, mais vous pouvez modifier les
            paramètres
            de votre navigateur pour refuser les cookies si vous préférez. Vous pouvez également supprimer les cookies
            déjà
            stockés sur votre ordinateur à tout moment.
            Pour plus d'informations sur les cookies, y compris la façon de voir quels cookies ont été stockés sur votre
            appareil et comment les gérer et les supprimer, visitez www.allaboutcookies.org.
        </div>
        <div class="politique-wrapper">
          <h3>{{ "general.CGU" | translate }}</h3>
          <div class="borderMenu"></div>
          <iframe
            class="iframe"
            title="TOU"
            height="600"
            [src]="urlCGU">
          </iframe>
        </div>
        <div class="politique-wrapper"  id="tou-beta">
          <h3>{{ "general.CGUBeta" | translate }}</h3>
          <div class="borderMenu"></div>
          <iframe
            class="iframe"
            title="TOUBeta"
            height="600"
            [src]="urlCGUBeta">
          </iframe>
        </div>
        <div *ngIf="false" class="politique-wrapper">
          <h3>CGV</h3>
          <div class="borderMenu"></div>
          <p class="politique">

        </div>
      </div>
    </div>
    <!-- Footer -->
    <footer>
      <div class="borderMenu"></div>
      © 2024 IndiePass France. All rights reserved
    </footer>
  </div>
</div>
