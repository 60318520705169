import {Component, Inject, OnInit} from '@angular/core';
import {GamePageService} from "../../../../swagger/api/gamePage.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Game} from "../../../../swagger/model/game";

@Component({
  selector: 'app-creer-gamepage',
  templateUrl: './creer-gamepage.component.html',
  styleUrls: ['./creer-gamepage.component.scss']
})
export class CreerGamepageComponent implements OnInit {
  titre = '';

  constructor(public gamePageService: GamePageService,
              public dialogRef: MatDialogRef<CreerGamepageComponent>,
              @Inject(MAT_DIALOG_DATA) public id: number) {
  }

  ngOnInit(): void {
  }

  creer() {
    if (this.id) {
      this.gamePageService.createGamePage(this.id, this.titre).subscribe(gamePage => {
        if (gamePage) {
          this.dialogRef.close(true)
        } else {
          this.dialogRef.close(false)
        }
      })
    }
    else {
      this.dialogRef.close(this.titre)
    }
  }
}
