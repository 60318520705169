<div class="white-theme">
<h1 mat-dialog-title>{{data?.titre}}</h1>
<div mat-dialog-content>
  <p>{{data?.text}}</p>
</div>
<div mat-dialog-actions>

  <label *ngIf="data.label">{{data?.label}}
    <mat-checkbox [(ngModel)]="accepte"></mat-checkbox>
  </label>
  <br>
  <br>
  <br>
  <button mat-flat-button color="warn" (click)="onNoClick()">Annuler</button>
  <button *ngIf="data.label" mat-flat-button color="primary" [disabled]="!accepte" [mat-dialog-close]="data">Valider</button>
  <button *ngIf="!data.label" mat-flat-button color="primary"   [mat-dialog-close]="data">Valider</button>
</div>
</div>
