import {Component, Inject, OnInit} from '@angular/core';
import {OrganizationService} from "../../../../swagger/api/organization.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {RouteParamService} from "../../../../services/route-param.service";
import {Member} from "../../../../swagger/model/member";
import {GeneralService} from "../../../../general.service";
import {SnackBarService} from "../../../../services/snack-bar.service";
import {IndiepassCodesEnum} from "../../../../../model/enums/indiepass-codes-enum";

@Component({
  selector: 'app-modify-member-role',
  templateUrl: './modify-member-role.component.html',
  styleUrls: ['./modify-member-role.component.scss']
})
export class ModifyMemberRoleComponent implements OnInit {

  constructor(private organizationService: OrganizationService, @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<ModifyMemberRoleComponent>, private routeParamService: RouteParamService,
              private generalService: GeneralService, private snackBarService: SnackBarService) {
  }

  public member: Member

  ngOnInit(): void {
    this.member = {...this.data.member} as Member;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  save() {
    this.organizationService.modifyMemberRole(this.generalService.getUserInfos().orgaId!, this.member.id!, this.member.role!).subscribe(url => {
      this.dialogRef.close();
    }, error => {
      this.snackBarService.openSnackBarErrorFallBack(IndiepassCodesEnum.UPDATE_KO, error);
      this.dialogRef.close();
    });
  }

}
