import {Pipe, PipeTransform} from '@angular/core';
import {languages} from "../../assets/list/languages";

@Pipe({
  name: 'filtreName'
})
export class FiltreNamePipe implements PipeTransform {

  transform(code: string): string {
    code = code.replace("-", " ");
    code = code.toLowerCase();
    return code.charAt(0).toUpperCase() + code.slice(1);

  }

}
