import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {GeneralService} from "../../general.service";
import {OrganizationService} from "../../swagger/api/organization.service";

@Component({
  selector: 'app-organization-invitation',
  templateUrl: './organization-invitation.component.html',
  styleUrls: ['./organization-invitation.component.scss']
})
export class OrganizationInvitationComponent implements OnInit {
  public email: string
  public temp_url_sig: string
  public organizationId: number
  public TTL: number
  public login: boolean = true
  public organization: string
  public isWaiting = false;

  constructor(private route: ActivatedRoute,
              public generalService: GeneralService, private organizationService: OrganizationService) {

    this.route.queryParams.subscribe(params => {
      this.email = params['email'];
      this.organization = params['organization'];
      this.organizationId = params['organizationId'];
      this.TTL = params['TTL'];
      this.temp_url_sig = params['temp_url_sig'];
      if (generalService?.getUserInfos()?.email !== null && generalService?.getUserInfos()?.email !== this.email) {
        this.generalService.logout();
      } else if (generalService?.getUserInfos()?.email !== null && generalService?.getUserInfos()?.email === this.organization) {

      }
    });
  }

  ngOnInit(): void {
  }

  join(): void {
    this.isWaiting = true;
    this.organizationService.validateMemberInvitation(this.organizationId, this.email, this.TTL, this.temp_url_sig).subscribe(
      data => {
        window.location.href = "";
      }, error => {
        this.isWaiting = false;
      }
    )
  }
}
