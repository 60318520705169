<div *ngIf="!cookiesAccepted || showOptions" class="cookie-banner">
  <div *ngIf="!showOptions" class="cookie-banner-content">
    <p class="pre-line">{{ "cookie.popupmessage" | translate }}

    <div class="cookie-buttons">
      <button mat-raised-button color="primary" (click)="acceptAll()">{{ "general.accepter" | translate }}</button>
      <button mat-raised-button (click)="declineAll()">{{ "general.refuser" | translate }}</button>
      <button mat-raised-button class="options-cookies-btn"
              (click)="openOptions()">{{ "general.options" | translate }}
      </button>
    </div>
  </div>

  <!-- Popup pour les options de cookies -->
  <div *ngIf="showOptions" class="cookie-popup">
    <div class="cookie-popup-content">
      <button mat-icon-button class="close" (click)="closeOptions()">
        <mat-icon>close</mat-icon>
      </button>
      <h2>Cookies manager
        <mat-icon>cookie</mat-icon>
      </h2>
      <h3>{{"cookie.vousChoisissezVosCookies" | translate}}</h3>

      <form (ngSubmit)="savePreferences()">
        <div class="cookie-option" *ngFor="let cookie of cookiesList">
          <h4>{{ cookie.name | translate }}</h4>
          <mat-radio-group [(ngModel)]="cookie.accepted" [disabled]="cookie.readOnly" name="{{cookie.name}}">
            <mat-radio-button [value]="true">{{"general.accepter" | translate}}</mat-radio-button>
            <mat-radio-button class="refuser" [value]="false">{{"general.refuser" | translate}}</mat-radio-button>
          </mat-radio-group>
          <p class="pre-line">{{ cookie.description | translate }}</p>
        </div>
        <button mat-flat-button color="primary" type="submit">{{"general.save" | translate}}</button>
      </form>
    </div>
  </div>
</div>
