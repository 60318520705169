import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class RouteParamService {

  constructor(private router: Router, private route: ActivatedRoute) {
  }

  getRouteParameterNumber(paramName: string): number | null {
    let route = this.route.root;

    while (route.firstChild) {
      route = route.firstChild;
      if (route.snapshot.params[paramName]) {
        return route.snapshot.params[paramName];
      }
    }
    return null;
  }

  getRouteParameterNumberInActivatedRouteSnapshot(paramName: string,
                                                  activatedRouteSnapshot: ActivatedRouteSnapshot): number | null {
    let route = activatedRouteSnapshot.root;

    while (activatedRouteSnapshot.firstChild) {
      if (route.firstChild)
        route = route.firstChild;
      if (route.params[paramName]) {
        return route.params[paramName];
      }
    }
    return null;
  }
}
