<div class="main-wrapper">
  <div class="ip-content">
    <div class="ip-area">
      <h1>{{'general.general' | translate}}</h1>
      <div *ngIf="organization?.tag">
        <div fxLayoutAlign="center start">
          {{'dashboard.TagDeLorganisation' | translate}} :
          <div fxLayoutAlign="center start" class="copy" [cdkCopyToClipboard]="organization.tag! "
               (click)="snackbarService.openSnackBar(IndiepassCodesEnum.COPY_OK,ActionsEnum.Ok,PannelsClassEnum.info)">{{ organization?.tag }}
            <mat-icon>content_copy</mat-icon>
          </div>
          <mat-icon #tooltip="matTooltip" (click)="tooltip.toggle()"  [matTooltipPosition]="'right'" [matTooltip]="'helper.organisationTag' | translate">help</mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>
