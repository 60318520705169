import {Component, Inject, OnInit} from '@angular/core';
import {OrganizationService} from "../../../../swagger/api/organization.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {RouteParamService} from "../../../../services/route-param.service";
import {FormControl, Validators} from "@angular/forms";

@Component({
  selector: 'app-delete-member',
  templateUrl: './delete-member.component.html',
  styleUrls: ['./delete-member.component.scss']
})
export class DeleteMemberComponent implements OnInit {


  public isLoading = false;

  constructor(private organizationService: OrganizationService, @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<DeleteMemberComponent>, private routeParamService: RouteParamService) {
  }

  emailFormControl = new FormControl('', [Validators.required, Validators.email]);

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  deleteMember() {
    this.isLoading = true;
    this.organizationService.deleteMemberFromOrganization(this.routeParamService.getRouteParameterNumber('idOrganization')!, this.data?.idMember).subscribe(url => {
      this.dialogRef.close();
    }).add(() => {
      this.isLoading = false;
    })
  }

}
