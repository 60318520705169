<div class="main-wrapper white-theme">
  <div class="ip-content">

    <div fxLayout="column" fxLayoutGap="30px" class="ip-area  mat-elevation-z8">
      <div *ngIf="!presentation">
        <h2>{{ 'profil.monAbonnement' | translate }}</h2>
        <div>
          {{ 'profil.AucunAbonnementEnCours' | translate }}
          <h3>  {{ 'profil.AbonnementIndiePass' | translate }}</h3>
          <h4>{{ 'general.prixMensuel' | translate }}</h4>
          <div [matTooltip]="'Il va falloir encore un peu de patience !'">
            <button *ngIf="!user?.abonnement" mat-flat-button color="primary" [appDisableButton]="isLoadingAbonnement"
                    (click)="subscribe()">
              <span> S'abonner</span>
            </button>
            <button *ngIf="user?.abonnement" mat-flat-button color="primary" [appDisableButton]="isLoadingAbonnement" [disabled]="false" (click)="manage()">
              {{ 'profil.gererMonAbonnement' | translate }}
            </button>
          </div>
        </div>
      </div>
      <div>
        <mat-divider *ngIf="!presentation" class="horizontal"></mat-divider>
        <h2>{{ 'profil.GererMonCompte' | translate }}</h2>
        <div fxLayout="column" fxLayoutAlign="space-around center" fxLayoutGap="20px">
          <button [appDisableButton]="isLoadingResetPassword" mat-flat-button color="primary" id="resetPasword"
                  type="button" (click)="resetPasword()">
            {{ 'profil.changerMonMdp' | translate }}
          </button>
          <button mat-flat-button color="primary" id="deleteAccount" type="button" (click)="askDeleteAccount()">
            {{ 'profil.SupprimerMonCompte' | translate }}
          </button>

        </div>
      </div>
      <div>
        <mat-divider class="horizontal"></mat-divider>
        <h2>{{ 'general.mesPreferences' | translate }}</h2>
        <mat-form-field>
          <mat-label> {{ 'general.language' | translate }}</mat-label>
          <mat-select [value]="this.translate.currentLang" (valueChange)="useLanguage($event)">
            <mat-option value="en">English</mat-option>
            <mat-option value="fr">Français</mat-option>
            <!--<mat-option value="sp">Espanol</mat-option> -->
          </mat-select>
        </mat-form-field>
        <div fxLayout="column" *ngIf="user">
          <mat-checkbox [checked]="user.newsletter" class="example-
         argin" (change)="updateNewsletter($event)">
            {{ 'profil.newsletter' | translate }}
          </mat-checkbox>
          <mat-checkbox *ngIf="false" [checked]="user.newsletterDev" (change)="updateNewsletterDev($event)" class="example-margin">
            {{ 'profil.newsletterDesDeveloppeurs' | translate }}
          </mat-checkbox>
        </div>

      </div>
      <div fxLayout="column" fxLayoutAlign="space-around center" fxLayoutGap="20px">
        <button mat-flat-button color="primary" id="logout" type="button" (click)="logout()">
          {{ 'general.seDeconnecter' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
