<div *ngIf="achievements?.length! > 0 && gameLight?.id!">
  <div [class.display-none]="loading">
    <div class="borderMenu">

    </div>
    <h2>{{ achievements.length }}  {{ "general.succes" | translate | lowercase }}</h2>
    <div class="list-success">
      <div *ngFor="let achievement of visibleAchievements">
        <img (load)="onImageLoad(achievement)"
             [src]="'achievements/'+achievement?.image | buildImageUrl: gameLight?.id!"
             alt="404">
      </div>
      <button mat-flat-button *ngIf="showMoreButton" class="show-more-btn" (click)="onShowMore()">
        ...
      </button>
    </div>
  </div>
  <div *ngIf="loading" class="success-placeholder animated-placeholder">

  </div>
</div>
