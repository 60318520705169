import {Injectable} from '@angular/core';
import {
  IndiePassModalAction,
  IndiePassModalData,
  IndiePassModalMessage,
  IndiepassModalsComponent,
  IndiePassModalTitle
} from "./indiepass-modals/indiepass-modals.component";
import {MatDialog} from "@angular/material/dialog";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class IndiepassModalsService {

  constructor(private dialog: MatDialog) {
  }

  private indiePassModalData: IndiePassModalData
  public needCheckbox: boolean = false;

  confirmationQuitWithoutSave(): Observable<any> {
    this.indiePassModalData = {
      title: IndiePassModalTitle.MD_TITLE_NOT_SAVE,
      message: IndiePassModalMessage.MD_MSG_NOT_SAVE,
      action: IndiePassModalAction.CONFIRMATION
    }
    const dialogRef = this.dialog.open(IndiepassModalsComponent, {
      width: '550px',
      data: this.indiePassModalData
    });
    return dialogRef.afterClosed();
  }

  confirmationDeleteMember(): Observable<any> {
    this.indiePassModalData = {
      title: IndiePassModalTitle.MD_TITLE_CONFIRM_DELETE,
      message: IndiePassModalMessage.MD_MSG_CONFIRM_DELETE_MEMBER,
      action: IndiePassModalAction.CONFIRMATION
    }
    const dialogRef = this.dialog.open(IndiepassModalsComponent, {
      width: '550px',
      data: this.indiePassModalData
    });
    return dialogRef.afterClosed();
  }
  confirmationDelete(): Observable<any> {
    this.indiePassModalData = {
      title: IndiePassModalTitle.MD_TITLE_CONFIRM_DELETE,
      message: IndiePassModalMessage.MD_MSG_CONFIRM_DELETE,
      action: IndiePassModalAction.CONFIRMATION
    }
    const dialogRef = this.dialog.open(IndiepassModalsComponent, {
      width: '550px',
      data: this.indiePassModalData
    });
    return dialogRef.afterClosed();
  }

  confirmationCustom(message: IndiePassModalMessage, title: IndiePassModalTitle, needCheckbox: boolean): Observable<any> {
    this.indiePassModalData = {
      title: title,
      message: message,
      action: IndiePassModalAction.CONFIRMATION,
      needCheckbox: needCheckbox
    }
    const dialogRef = this.dialog.open(IndiepassModalsComponent, {
      width: '550px',
      data: this.indiePassModalData
    });
    return dialogRef.afterClosed();
  }
}
