<div class="wrapper-dashboard">
  <div class="main">
<div class="dash-board-header dashboard-size" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
  <h1>{{ organizationLibele }} Dashboard</h1>
  <div *ngIf="userInfos && userInfos.organizations && userInfos.organizations.length>1">

    <mat-form-field appearance="standard">
      <mat-label>{{ 'dashboard.changeContexte' | translate }}</mat-label>
      <mat-select (selectionChange)="changeOrganizationContext($event)">
        <mat-option [disabled]="organization.id == userInfos.orgaId"
                    *ngFor="let organization of userInfos.organizations" [value]="organization.id">
          {{ organization.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </div>
</div>
<div *ngIf="organizationContextService.getMemberContext()">

  <mat-tab-group animationDuration="0ms" [color]="'warn'" mat-align-tabs="center" class="text-primary dashboard-size"
                 [(selectedIndex)]="selectedIndex" (selectedIndexChange)="onTabChange($event)">
    <mat-tab label="{{ 'general.jeux' | translate }}">

    </mat-tab>
    <mat-tab label="{{ 'dashboard.monOrganisation' | translate }}" class="text-primary">

    </mat-tab>

  </mat-tab-group>
  <router-outlet></router-outlet>
</div>

  </div>
</div>
