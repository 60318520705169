import {Injectable} from '@angular/core';
import jwt_decode from 'jwt-decode';
import {CookieService} from "./cookie.service";
import {Member} from "./swagger/model/member";
import RoleEnum = Member.RoleEnum;
import {UserInfos} from "./swagger/model/userInfos";

var isConnected: boolean = false;
var userInfo: UserInfos;
var jwt: any;

@Injectable({
  providedIn: 'root'

})
export class GeneralService {
  private isInClient: boolean = false;
  private jwtClient: string;

  constructor(public cookie: CookieService) {
  }

  public getIsConnected() {
    return (this?.getUserInfos()?.id !== null && this?.getUserInfos()?.id);
  }

  //logout
  public logout() {
    this.cookie.deleteCookie("auth_token");
    this.cookie.deleteCookie("refresh_token");
    this.cookie.delete_cookie("user_infos");
    this.cookie.delete_cookie("auth_token");
    this.setIsConnected(false)
  }

  public isDeveloppeur() {
    var cookie = this.cookie.getCookie("user_infos");
    if (cookie) {
      jwt = jwt_decode(cookie)
      return jwt.developpeur;
    } else {
      null;
    }
  }

  public isEditeur() {
    var cookie = this.cookie.getCookie("user_infos");
    if (cookie) {
      jwt = jwt_decode(cookie)
      return jwt.editeur;
    } else {
      null;
    }
  }

  public setIsinclient(value: boolean) {
    console.log("setInClient")
    this.isInClient = value
  }

  public setJwtClient(jwt: string) {
    this.jwtClient = jwt
  }

  public getJwtClient() {
    return this.jwtClient
  }

  public getIsinclient() {
    return this.isInClient
  }

  public getUserInfos() {

    var cookie = this.cookie.getCookie("user_infos");
    if (cookie) {
      userInfo = JSON.parse(this.base64DecodeUnicode(cookie)) as UserInfos;
    }
    return userInfo;
  }

  //return the id of the organization in the context or the first organization in organization list
  public getOrganizationId(): number | null {

    var cookie = this.cookie.getCookie("user_infos");
    if (cookie) {

      userInfo = JSON.parse(this.base64DecodeUnicode(cookie)) as UserInfos;
      if (userInfo?.orgaId !== undefined) {
        return userInfo.orgaId;
      } else
        return userInfo.organizations![0].id!;
    }
    return null;
  }

  public getMemberId(): number | null {

    var cookie = this.cookie.getCookie("user_infos");
    if (cookie) {
      userInfo = JSON.parse(this.base64DecodeUnicode(cookie)) as UserInfos;
      if (userInfo?.memberId !== undefined) {
        return userInfo.memberId;
      }
    }
    return null;
  }

  public getMemberRole(): RoleEnum | null {

    var cookie = this.cookie.getCookie("user_infos");
    if (cookie) {
      userInfo = JSON.parse(this.base64DecodeUnicode(cookie)) as UserInfos;
      if (userInfo?.memberRole !== undefined) {
        return userInfo.memberRole.toLowerCase() as RoleEnum;
      }
    }
    return null;
  }

  public getUserId() {
    var jwt: any;
    if (!this.getIsinclient()) {

      var cookie = this.cookie.getCookie("user_infos");

      if (cookie) {
        jwt = jwt_decode(cookie)
        this.setIsConnected(true)
        return jwt.jti;
      } else {
        this.setIsConnected(false)
      }
    } else if (this.getJwtClient()) {
      console.log(this.getJwtClient())
      jwt = jwt_decode(this.getJwtClient())
      return jwt.iss;

    }
  }

  public setIsConnected(value: boolean) {
    isConnected = value
  }

  /*public base64DecodeUnicode(str: string) {

    const token = str;
    const parts = token.split('.');
    const payloadBase64 = parts[1];
    return atob(payloadBase64);
  }*/

  private base64DecodeUnicode(str: string): string {
    try {
      const token = str;
      const parts = token.split('.');
      const payloadBase64 = parts[1];
      const base64 = payloadBase64.replace(/-/g, '+').replace(/_/g, '/');
      const decodedStr = decodeURIComponent(
        Array.prototype.map.call(atob(base64), c =>
          '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        ).join('')
      );
      return decodedStr;
    } catch (e) {
      console.error('Failed to decode base64 string', e);
      return '';
    }
  }
}
