<div class="main-wrapper white-theme" fxLayout="row" fxLayoutAlign="center center">
  <div class="ip-max-width">
    <div class="ip-area">
      <button class="float-right" mat-flat-button color="primary" [disabled]="!canSave() || !canEdit" (click)="save()">
        {{ 'general.save' | translate }}
      </button>
      <br>
      <h1> {{ 'general.infosDuJeu' | translate }}</h1>

      <div class="checkbox-list">
        <mat-checkbox [disabled]="!canEdit"
                      [(ngModel)]="game.sensitiveContent">{{ 'gamegeneral.CeJeuComporteDuContenuSensible' | translate }}
        </mat-checkbox>
        <mat-checkbox [disabled]="!canEdit"
                      [(ngModel)]="game.financialTransaction">{{ 'gamegeneral.CeJeuComportedDesTransactionsFinancièresExternes' | translate }}
        </mat-checkbox>
        <div class="checkbox-list">
          <h3>{{ 'general.typeDeJeu' | translate }} <span [ngClass]="{'required': !canPublish()}">*</span> :</h3>
          <mat-checkbox [disabled]="!canEdit" [(ngModel)]="game.solo">{{ 'general.solo' | translate }}</mat-checkbox>
          <mat-checkbox [disabled]="!canEdit" [(ngModel)]="game.localMulti">{{ 'general.multiLocal' | translate }}
          </mat-checkbox>
          <mat-checkbox [disabled]="!canEdit" [(ngModel)]="game.onlineMulti">{{ 'general.multiEnLigne' | translate }}
          </mat-checkbox>
        </div>
      </div>
      <br>
      <mat-form-field class="genres-input" appearance="fill">
        <mat-label>{{ 'general.genres' | translate }}</mat-label>
        <mat-chip-list #chipList>
          <mat-chip [disabled]="!canEdit" *ngFor="let genre of  game.genres" (change)="updateListeGenre()"
                    (removed)="remove(genre)">
            {{ genre.name }}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
          <input #input_genre placeholder="{{ 'general.genres' | translate }}"
                 [formControl]="genreCtrl"
                 [matAutocomplete]="auto"
                 [disabled]="!canEdit"
                 [matChipInputFor]="chipList"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [matChipInputAddOnBlur]="addOnBlur">
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event);">
          <mat-option *ngFor="let genre of filteredGenres | async" [value]="input_genre.value">
            {{ genre.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <h2>{{ 'general.languages' | translate }}</h2>
      <mat-form-field class="genres-input" appearance="fill">
        <mat-label>{{ 'general.languages' | translate }}</mat-label>
        <input #input_lang placeholder="{{ 'general.languages' | translate }}" matInput
               [disabled]="!canEdit"
               [formControl]="langCtrl"
               [matAutocomplete]="autoLang">
        <mat-autocomplete #autoLang="matAutocomplete" (optionSelected)="selectedLangue($event);">
          <mat-option [disabled]="!canEdit" *ngFor="let lang of filteredLanguages | async" [value]="lang.nameEn">
            {{ lang?.nameEn! }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <div class="table-container">
        <mat-table *ngIf="showTable && game.languages &&  game.languages.length" [dataSource]="game.languages!"
                   class="mat-elevation-z8">
          <ng-container matColumnDef="code">
            <mat-header-cell *matHeaderCellDef>{{ 'general.langue' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let langue"> {{ langue.code | languageName }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="interface">
            <mat-header-cell *matHeaderCellDef> {{ 'general.interface' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let langue">
              <mat-checkbox [disabled]="!canEdit" [(ngModel)]="langue.interfaceSupported"
                            (change)="updateLanguage()"></mat-checkbox>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="audio">
            <mat-header-cell *matHeaderCellDef> {{ 'general.audio' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let langue">
              <mat-checkbox [disabled]="!canEdit" (change)="updateLanguage()"
                            [(ngModel)]="langue.audioSupported"></mat-checkbox>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="subtitle">
            <mat-header-cell *matHeaderCellDef> {{ 'general.sous-titre' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let langue">
              <mat-checkbox [disabled]="!canEdit" (click)="updateLanguage()"
                            [(ngModel)]="langue.subtitlesSupported"></mat-checkbox>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef> {{ 'general.actions' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let langue; let i = index">
              <button [disabled]="!canEdit" mat-icon-button (click)="removeLang(langue)">
                <mat-icon>delete</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns" sticky></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>
      <div *ngIf="game?.state === 'DRAFT'">
        <p>
          <strong>{{ 'gamegeneral.afinDePublierVotreJeuVousDevezAvoir' | translate }} :</strong> <br>
          - {{ 'gamegeneral.gameInfosUpToDate' | translate }} :  {{ (!canSave() && canPublish()) ? '✔️' : '❌' }}<br>
          - {{ 'gamegeneral.uneGamePageEnProduction' | translate }} : {{ isGamePageComplete ? '✔️' : '❌' }} ️<br>
          - {{ 'gamegeneral.unBuildEnProduction' | translate }} :  {{ isReleaseReady ? '✔️' : '❌' }}️<br>
        </p>
        <button [disabled]="canSave() || !isReleaseReady || !isGamePageComplete ||  !canPublish()"
                (click)="publishGame()" color="primary"
                mat-flat-button> {{ 'gamegeneral.soumettreMonJeuPourPublication' | translate }}
        </button>
      </div>

      <div *ngIf="game?.state === 'VALIDATION_IN_PROGRESS'">
        <p> {{ 'gamegeneral.votreGameEstEnCoursDeverification' | translate }}</p>
        <p>{{ 'gamegeneral.dateDeSoumission' | translate }} : {{ game.dateSubmited | date:'dd/MM/yyyy hh:mm' }} </p>
        <button (click)="cancelPublishGame()" color="warn" mat-flat-button>{{ 'general.annuler' | translate }}</button>
      </div>

      <div *ngIf="game?.state === 'EXPLOITED'">
        <button (click)="publishGame()" color="warn"
                mat-flat-button>{{ 'gamegeneral.demanderLeRetraitDuJeu' | translate }}
        </button>
      </div>


    </div>
  </div>
</div>
