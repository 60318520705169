/**
 * IndiePass API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ErrorResponse } from '../model/errorResponse';
import { Tokens } from '../model/tokens';
import { Utilisateur } from '../model/utilisateur';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AuthService {

    protected basePath = 'https://api.indiepass.com/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Request to reset password
     * 
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public askResetPassword(email: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public askResetPassword(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public askResetPassword(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public askResetPassword(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling askResetPassword.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/auth/password/reset`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change organization context
     * 
     * @param idOrganization 
     * @param idGame 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changeOrganizationContext(idOrganization: number, idGame?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public changeOrganizationContext(idOrganization: number, idGame?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public changeOrganizationContext(idOrganization: number, idGame?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public changeOrganizationContext(idOrganization: number, idGame?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idOrganization === null || idOrganization === undefined) {
            throw new Error('Required parameter idOrganization was null or undefined when calling changeOrganizationContext.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idOrganization !== undefined && idOrganization !== null) {
            queryParameters = queryParameters.set('idOrganization', <any>idOrganization);
        }
        if (idGame !== undefined && idGame !== null) {
            queryParameters = queryParameters.set('idGame', <any>idGame);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/auth/organization`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check if username exists
     * 
     * @param username 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkUsername(username: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public checkUsername(username: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public checkUsername(username: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public checkUsername(username: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (username === null || username === undefined) {
            throw new Error('Required parameter username was null or undefined when calling checkUsername.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (username !== undefined && username !== null) {
            queryParameters = queryParameters.set('username', <any>username);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/check-username`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User login
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public login(body: Utilisateur, observe?: 'body', reportProgress?: boolean): Observable<Tokens>;
    public login(body: Utilisateur, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Tokens>>;
    public login(body: Utilisateur, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Tokens>>;
    public login(body: Utilisateur, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling login.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Tokens>('post',`${this.basePath}/auth/login`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * User login
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginClient(body: Utilisateur, observe?: 'body', reportProgress?: boolean): Observable<Tokens>;
    public loginClient(body: Utilisateur, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Tokens>>;
    public loginClient(body: Utilisateur, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Tokens>>;
    public loginClient(body: Utilisateur, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling loginClient.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Tokens>('post',`${this.basePath}/auth/client/login`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Disconnect the user
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public logout(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public logout(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public logout(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public logout(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/auth/logout`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Refresh token
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public refreshAuth(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public refreshAuth(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public refreshAuth(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public refreshAuth(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/auth/refresh`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Refresh token
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public refreshClientAuth(observe?: 'body', reportProgress?: boolean): Observable<Tokens>;
    public refreshClientAuth(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Tokens>>;
    public refreshClientAuth(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Tokens>>;
    public refreshClientAuth(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Tokens>('post',`${this.basePath}/auth/refresh/client`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Register a user
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerUser(body: Utilisateur, observe?: 'body', reportProgress?: boolean): Observable<Utilisateur>;
    public registerUser(body: Utilisateur, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Utilisateur>>;
    public registerUser(body: Utilisateur, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Utilisateur>>;
    public registerUser(body: Utilisateur, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling registerUser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Utilisateur>('post',`${this.basePath}/auth/register`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reset password
     * 
     * @param body 
     * @param email 
     * @param TTL 
     * @param tempUrlSig 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetPassword(body: Utilisateur, email: string, TTL: number, tempUrlSig: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public resetPassword(body: Utilisateur, email: string, TTL: number, tempUrlSig: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public resetPassword(body: Utilisateur, email: string, TTL: number, tempUrlSig: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public resetPassword(body: Utilisateur, email: string, TTL: number, tempUrlSig: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling resetPassword.');
        }

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling resetPassword.');
        }

        if (TTL === null || TTL === undefined) {
            throw new Error('Required parameter TTL was null or undefined when calling resetPassword.');
        }

        if (tempUrlSig === null || tempUrlSig === undefined) {
            throw new Error('Required parameter tempUrlSig was null or undefined when calling resetPassword.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }
        if (TTL !== undefined && TTL !== null) {
            queryParameters = queryParameters.set('TTL', <any>TTL);
        }
        if (tempUrlSig !== undefined && tempUrlSig !== null) {
            queryParameters = queryParameters.set('temp_url_sig', <any>tempUrlSig);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/auth/password/reset`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permet de changer le jeu dans le token JWT
     * 
     * @param idGame 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTokenToPlay(idGame?: number, observe?: 'body', reportProgress?: boolean): Observable<Tokens>;
    public updateTokenToPlay(idGame?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Tokens>>;
    public updateTokenToPlay(idGame?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Tokens>>;
    public updateTokenToPlay(idGame?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idGame !== undefined && idGame !== null) {
            queryParameters = queryParameters.set('idGame', <any>idGame);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Tokens>('put',`${this.basePath}/auth/game`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Validate user registration
     * 
     * @param email 
     * @param TTL 
     * @param tempUrlSig 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateNewAccount(email: string, TTL: number, tempUrlSig: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public validateNewAccount(email: string, TTL: number, tempUrlSig: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public validateNewAccount(email: string, TTL: number, tempUrlSig: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public validateNewAccount(email: string, TTL: number, tempUrlSig: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling validateNewAccount.');
        }

        if (TTL === null || TTL === undefined) {
            throw new Error('Required parameter TTL was null or undefined when calling validateNewAccount.');
        }

        if (tempUrlSig === null || tempUrlSig === undefined) {
            throw new Error('Required parameter tempUrlSig was null or undefined when calling validateNewAccount.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }
        if (TTL !== undefined && TTL !== null) {
            queryParameters = queryParameters.set('TTL', <any>TTL);
        }
        if (tempUrlSig !== undefined && tempUrlSig !== null) {
            queryParameters = queryParameters.set('temp_url_sig', <any>tempUrlSig);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/auth/validate`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
