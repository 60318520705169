import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../swagger/api/auth.service";
import {SnackBarService} from "../../services/snack-bar.service";
import {IndiepassCodesEnum} from "../../../model/enums/indiepass-codes-enum";
import {ActionsEnum} from "../../snack-bar/enum-actions";
import {PannelsClassEnum} from "../../snack-bar/pannels-class-enum";

@Component({
  selector: 'app-validate-account',
  templateUrl: './validate-account.component.html',
  styleUrls: ['./validate-account.component.scss']
})
export class ValidateAccountComponent implements OnInit {

  public email: string
  public temp_url_sig: string
  public TTL: number

  hide = true;

  constructor(private router: Router,
              private snackBarService: SnackBarService, private route: ActivatedRoute, private authService: AuthService) {
    this.route.queryParams.subscribe(params => {
      this.email = params['email'];
      this.TTL = params['TTL'];
      this.temp_url_sig = params['temp_url_sig'];
      this.authService.validateNewAccount(this.email, this.TTL, this.temp_url_sig).subscribe(() => {
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 1000);
        this.snackBarService.openSnackBar(IndiepassCodesEnum.ACCOUNT_VALIDATION_OK, ActionsEnum.Ok, PannelsClassEnum.info)
      }, error => {
        this.snackBarService.openSnackBarErrorFallBack(IndiepassCodesEnum.ACCOUNT_VALIDATION_KO, error)
        setTimeout(() => {
          this.router.navigate(['/']);
        }, 2000);


      });
    });
  }

  ngOnInit(): void {
  }

}
