import { Pipe, PipeTransform } from '@angular/core';
import {languages} from "../../assets/list/languages";

@Pipe({
  name: 'languageName'
})
export class LanguageNamePipe implements PipeTransform {


  transform(code: string): string {
    const language = languages.find(lang => lang.code === code);
    return language ? language.nameEn : code;
  }

}
