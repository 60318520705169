<div class="wrapper">
  <div class="image-view ">
    <iframe [class.display-none]="imageIsLoading" *ngIf="video && videoUrl && !imageSelected" [src]="videoUrl" title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    <img (load)="imageIsLoading = false" *ngIf="imageSelected" [class.display-none]="imageIsLoading"
         [src]="imageSelected?.fileName! | buildImageUrl : gameId!"
         alt="game image">
    <div *ngIf="imageIsLoading" class="animated-placeholder animated-background"></div>

  </div>
  <div #listImgPreview class="list-img-preview">
    <div [class.display-none]="miniaturesLoading" *ngIf="video" class="image-preview" (click)="selectImage(undefined)" [class.selected]="!imageSelected">
      <mat-icon class="video-preview-icon">play_circle</mat-icon>
      <img class="video-preview" [src]='videoUrlMiniature' title="YouTube min">
    </div>
    <div class="image-preview" *ngFor="let image of images"
         [class.display-none]="miniaturesLoading" [class.selected]="image === imageSelected">
      <img (load)="onImageLoad(image)" (click)="selectImage(image)" [src]="image?.fileName! | buildImageUrl : gameId!" alt="game image">

    </div>
    <div *ngIf="miniaturesLoading" class="animated-placeholder placeHolder-miniatures"></div>

  </div>
</div>
