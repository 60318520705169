<h1>{{ 'general.equipe' | translate }}</h1>
<div dir="ltr" fxLayoutGap="20px">
  <button (click)="addMember()" *ngIf="this.generalService.getMemberRole() !== 'regular'"
          mat-raised-button
          color="primary">
    <mat-icon>group_add</mat-icon>
  </button>
  <button [disabled]="!canSave()" *ngIf="this.generalService.getMemberRole()  !== 'regular'" mat-raised-button
          color="primary" (click)="save()">
    {{ 'general.save' | translate }}
  </button>
</div>
<mat-form-field>
  <input matInput (keyup)="applyFilter($event)" placeholder="{{'general.rechercher' | translate}}">
</mat-form-field>
<div class="table-dark-theme">
  <mat-table *ngIf="membres && membres.data.length > 0" (mousedown)="onMouseDown()" (mouseup)="onMouseUp()"
             [dataSource]="membres" class="mat-elevation-z8">
    <!-- Colonne Membre -->
    <ng-container matColumnDef="members">
      <mat-header-cell *matHeaderCellDef> {{ 'general.membre' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let membre"> {{ membre.email }}</mat-cell>
    </ng-container>

    <!-- Colonnes Droits -->
    <ng-container *ngFor="let droit of droits" matColumnDef="{{droit}}">
      <mat-header-cell *matHeaderCellDef>
        <div class="rotatedRight">{{ droit | translate }}</div>
      </mat-header-cell>
      <mat-cell *matCellDef="let membre">
        <mat-checkbox [disabled]="!isEditThis(membre)"
                      (mouseleave)="onCheckboxEnter(membre,droit)"
                      [(ngModel)]="membre.gameRights[droit]"></mat-checkbox>
      </mat-cell>
    </ng-container>

    <!-- Colonne Membre -->
    <ng-container matColumnDef="all">
      <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
      <mat-cell *matCellDef="let membre">
        <button [disabled]="!isEditThis(membre)" (click)="allRights(membre)" mat-icon-button>
          <mat-icon>done_all</mat-icon>
        </button>
        <button [disabled]="!isEditThis(membre)" (click)="delete(membre)" mat-icon-button>
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>
