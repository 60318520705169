<div class="wrapper-dashboard">
  <div class="main">
    <div class="dashboard-size">
      <h1 class="game-title">{{ gameName }}</h1>

      <mat-tab-group [selectedIndex]="selectedIndex" color="warn" animationDuration="0ms"
                     (selectedIndexChange)="onTabChange($event)">
        <mat-tab label="{{ 'general.general' | translate }}"></mat-tab>
        <mat-tab *ngIf="canEdit" label="{{ 'general.gamePages' | translate }}"></mat-tab>
        <mat-tab *ngIf="canEdit" label="{{ 'general.succes' | translate }}"></mat-tab>
        <mat-tab *ngIf="canEdit" label="{{ 'general.development' | translate }}"></mat-tab>
        <mat-tab label="{{ 'general.equipe' | translate }}"></mat-tab>
      </mat-tab-group>
    </div>

    <router-outlet></router-outlet>
  </div>
</div>
