export enum IndiepassCodesEnum {
  GAMEPAGE_SUPPRIME_OK = "GAMEPAGE_SUPPRIME_OK",
  GAMEPAGE_SUPPRIME_KO = "GAMEPAGE_SUPPRIME_KO",
  COPY_OK = "COPY_OK",
  MEMBER_ADD_OK = "MEMBER_ADD_OK",
  MEMBER_ADD_KO = "MEMBER_ADD_KO",
  UPDATE_OK = "UPDATE_OK",
  UPDATE_KO = "UPDATE_KO",
  ACCOUNT_VALIDATION_OK = "ACCOUNT_VALIDATION_OK",
  ACCOUNT_VALIDATION_KO = "ACCOUNT_VALIDATION_KO",
  MAIL_SEND_OK = "MAIL_SEND_OK",
  MAIL_SEND_KO = "MAIL_SEND_KO",
  IMAGE_SIZE_KO = "IMAGE_SIZE_KO",
  COUNTRY_ONLY_DEV = "COUNTRY_ONLY_DEV",
}
