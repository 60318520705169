import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {HttpHeaders} from "@angular/common/http";
import {ActivatedRoute} from "@angular/router";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {environment} from "../../../../environments/environment";
import {AchievementService} from "../../../swagger/api/achievement.service";
import {RouteParamService} from "../../../services/route-param.service";
import {FileService} from "../../../services/file.service";
import {ImageService} from "../../../services/image.service";
import {Achievement} from "../../../swagger/model/achievement";

@Component({
  selector: 'app-achievements-management',
  templateUrl: './achievements-management.component.html',
  styleUrls: ['./achievements-management.component.scss']
})
export class AchievementsManagementComponent implements OnInit {
  dataSource: MatTableDataSource<Achievement>;
  initialData: Achievement[] = [];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  displayedColumns: string[] = ['apiName', 'title', 'description', 'image', 'actions'];
  readonly environment = environment;
  idGame: number;
  public isSaving = false
  private tempId = 0;

  constructor(private routeParamService: RouteParamService, private changeDetectorRefs: ChangeDetectorRef,
              private achievementService: AchievementService, private fileService: FileService, private imageService: ImageService) {
    this.idGame = this.routeParamService.getRouteParameterNumber('idGame')!;
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.achievementService.getAchievement(this.idGame).subscribe((value) => {
      this.dataSource.data = value;
      this.initialData = JSON.parse(JSON.stringify(value)); // Deep copy of initial data
    });
    this.dataSource.filterPredicate = (data: Achievement, filter: string) => {
      return data?.title!.trim().toLowerCase().includes(filter) ||
        data?.description!.trim().toLowerCase().includes(filter) ||
        data?.apiName!.trim().toLowerCase().includes(filter);
    };
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  sauvegarderAchievement(): void {
    this.dataSource.data.forEach((element) => {
      if (element.id! < 0) {
        element.id = undefined;
      }
    });
    this.isSaving = true;
    this.achievementService.updateAchievement(this.dataSource.data, this.idGame).subscribe((result) => {
      this.dataSource.data = result;
      this.initialData = JSON.parse(JSON.stringify(result)); // Update initial data after save
    }).add(() => {
      this.isSaving = false;
    });
  }

  isAllAchievementsGotImage(): boolean {
    return this.dataSource.data.every((element) => this.imageLoaded(element));
  }

  addAchievement(): void {
    const newAchievement: Achievement = {id: --this.tempId};

    this.dataSource.data = [{...newAchievement}, ...this.dataSource.data];
    this.changeDetectorRefs.detectChanges();
  }

  imageLoaded(element: Achievement): boolean {
    return !!element.image && element.image !== "load";
  }

  onCheckboxChange(element: Achievement) {
    element.isHidden = !element.isHidden;
    this.changeDetectorRefs.detectChanges();
  }

  isValid(achievement: Achievement): boolean {
    return achievement?.title?.length! <= 256 && achievement?.title?.length! >= 1 &&
      achievement?.description?.length! <= 256 &&
      this.imageLoaded(achievement);
  }

  deleteAchievement(element: Achievement): void {
    this.dataSource.data = this.dataSource.data.filter((value) => value !== element);
  }

  imageUpload(event: any, element: Achievement) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file]: File[] = event.target.files;
      const headers = new HttpHeaders().set('Content-Type', 'image/jpeg');
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.fileService.checkImageSize(file, 256, 256).then(() => {
          this.achievementService.uploadAchievementsRessource(this.idGame).subscribe(response => {
            element.image = "load";
            this.imageService.uploadImage(file, response.linkUpload!, headers).subscribe((progress) => {
              if (progress === 100) {
                this.checkImage(element!, response.path!);
              }
            });
          });
        });
      };
    }
  }

  checkImage = (achievement: Achievement, path: string) => {
    const img = new Image();
    img.src = environment.urlimgcdn + this.idGame + "/achievements/" + path + "?class=screenshot";
    img.onload = () => {
      achievement.image = path;
    };
    img.onerror = () => {
      setTimeout(this.checkImage, 2500, achievement, path);
    };
  };

  isColumnDataMissing(column: string): boolean {
    return this.dataSource.data.some((element: Achievement) => {
      if (column === 'title') {
        return !element.title || element.title.trim().length === 0;
      } else if (column === 'description') {
        return !element.description || element.description.trim().length === 0;
      } else if (column === 'image') {
        return !element.image;
      }
      return false;
    });
  }

  hasChanges(): boolean {
    return JSON.stringify(this.initialData) !== JSON.stringify(this.dataSource.data);
  }
}
