import { Component, Input, OnInit } from '@angular/core';
import { GameLight } from "../../swagger/model/gameLight";
import {Language} from "../../swagger/model/language";

@Component({
  selector: 'app-game-details',
  templateUrl: './game-details.component.html',
  styleUrls: ['./game-details.component.scss']
})
export class GameDetailsComponent implements OnInit {
  @Input() game: GameLight;
  showAllLanguages = false;

  visibleLanguages:  Array<Language> = [];

  constructor() { }

  ngOnInit(): void {
    this.updateVisibleLanguages();
  }

  toggleLanguages(): void {
    this.showAllLanguages = !this.showAllLanguages;
    this.updateVisibleLanguages();
  }

  updateVisibleLanguages(): void {
    if (this.showAllLanguages) {
      this.visibleLanguages = this.game.languages!;
    } else {
      this.visibleLanguages = this.game.languages!.slice(0, 3);
    }
  }
}
