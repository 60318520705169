import {HttpClient, HttpEventType, HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  constructor(private http: HttpClient) {
  }

  uploadImage(file: File, uploadUrl: string, headers: HttpHeaders): Observable<number> {
    const req = new HttpRequest('PUT', uploadUrl, file, {
      headers: headers,
      reportProgress: true,
    });

    return this.http.request(req).pipe(
      tap((event) => {
        if (event.type === HttpEventType.UploadProgress && event.total) {
          // Calculate the upload progress percentage
          const percentDone = Math.round((100 * event.loaded) / event.total);
        }
      }),
      map((event) => {
        if (event instanceof HttpResponse) {
          return 100; // Upload completed
        }
        if (event.type === HttpEventType.UploadProgress && event.total) {
          return Math.round((100 * event.loaded) / event.total); // Upload progress percentage
        }
        return 0;
      })
    );
  }
}
