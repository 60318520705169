<div class="parent">
  <div cdkDropList cdkDropListOrientation="horizontal" class="example-list"
       (cdkDropListDropped)="drop($event)">
    <!-- <div class="example-box" *ngFor="let timePeriod of timePeriods" cdkDrag>{{timePeriod}}</div> -->
    <div cdkDrag class="parent example-box" *ngFor="let image of listeImages">
      <button class='child addToList' (click)="deleteElement(image.fileName!)" mat-icon-button>
        <mat-icon class="icon-delete">delete</mat-icon>
      </button>
      <img *ngIf="!image?.percentage && image?.percentage!==0 && !image.loading " class="screenShots"
           [src]="environment.urlimgcdn+data.idJeu+'/'+image?.fileName+'?class=screenshot'"
           alt="gameCover">
      <mat-spinner *ngIf="image.loading"></mat-spinner>
      <div *ngIf="image!== undefined && image?.percentage! >= 0">
        <div>
          {{ image?.percentage }} %
        </div>
        <mat-spinner [value]="image?.percentage" [mode]="'determinate'"></mat-spinner>
      </div>
    </div>
  </div>
  <br>
  <button [matTooltip]="numberOfImages >=6  ? 'LIMIT_REACH' : ''" [disabled]="numberOfImages >=6"
          class="button-edit icon-add" (click)="fileInputCover.click()" type="button"
          mat-flat-button>
    Upload image
    <mat-icon *ngIf="numberOfImages <6" class="icon-add">add</mat-icon>
    <input multiple style="display:none;" #fileInputCover (change)="imageUpload($event)" type="file"
           name="image" accept="image/png, image/jpeg, image/jpg "/>
  </button>
  <div mat-dialog-actions>
    <button mat-raised-button color="warn" (click)="onNoClick()">Cancel</button>
    <button [disabled]="!areAllImagesLoaded()" mat-raised-button class="validate" [mat-dialog-close]="listeImages" cdkFocusInitial>Validate</button>
  </div>
</div>
