import {Genre} from "../../app/swagger/model/genre";

export interface GenreDatas extends Genre {
  popular: boolean
  graphic: boolean
  actif: boolean
}

export const gameGenres: GenreDatas[] = [
  {name: 'action', popular: true, graphic: false,actif : false},
  {name: 'adventure', popular: true, graphic: false,actif : false},
  {name: 'arcade', popular: false, graphic: false,actif : false},
  {name: 'art-game', popular: false, graphic: false,actif : false},
  {name: 'augmented-reality', popular: false, graphic: false,actif : false},
  {name: 'auto-battler', popular: false, graphic: false,actif : false},
  {name: 'battle-royale', popular: false, graphic: false,actif : false},
  {name: 'battle-simulator', popular: false, graphic: false,actif : false},
  {name: 'board-game', popular: false, graphic: false,actif : false},
  {name: 'bullet-hell', popular: false, graphic: false,actif : false},
  {name: 'card-game', popular: false, graphic: false,actif : false},
  {name: 'cinematic-platformer', popular: false, graphic: false,actif : false},
  {name: 'city-building', popular: false, graphic: false,actif : false},
  {name: 'clicker', popular: false, graphic: false,actif : false},
  {name: 'crafting', popular: false, graphic: false,actif : false},
  {name: 'dating-simulator', popular: false, graphic: false,actif : false},
  {name: 'deck-building', popular: false, graphic: false,actif : false},
  {name: 'drone', popular: false, graphic: false,actif : false},
  {name: 'dungeon-crawler', popular: false, graphic: false,actif : false},
  {name: 'educational', popular: false, graphic: false,actif : false},
  {name: 'endless-runner', popular: false, graphic: false,actif : false},
  {name: 'episodic', popular: false, graphic: false,actif : false},
  {name: 'escape-room', popular: false, graphic: false,actif : false},
  {name: 'esports', popular: false, graphic: false,actif : false},
  {name: 'experimental', popular: false, graphic: false,actif : false},
  {name: 'farming', popular: true, graphic: false,actif : false},
  {name: 'fighting', popular: false, graphic: false,actif : false},
  {name: 'fitness', popular: false, graphic: false,actif : false},
  {name: 'god-game', popular: false, graphic: false,actif : false},
  {name: 'hack-and-slash', popular: false, graphic: false,actif : false},
  {name: 'horror', popular: true, graphic: false,actif : false},
  {name: 'hidden-object', popular: false, graphic: false,actif : false},
  {name: 'idle', popular: false, graphic: false,actif : false},
  {name: 'interactive-fiction', popular: false, graphic: false,actif : false},
  {name: 'jrpg', popular: false, graphic: false,actif : false},
  {name: 'life-simulation', popular: false, graphic: false,actif : false},
  {name: 'management', popular: false, graphic: false,actif : false},
  {name: 'match-three', popular: false, graphic: false,actif : false},
  {name: 'metroidvania', popular: true, graphic: false,actif : false},
  {name: 'mmo', popular: false, graphic: false,actif : false},
  {name: 'moba', popular: false, graphic: false,actif : false},
  {name: 'music-rhythm', popular: false, graphic: false,actif : false},
  {name: 'narrative', popular: true, graphic: false,actif : false},
  {name: 'open-world', popular: false, graphic: false,actif : false},
  {name: 'party', popular: false, graphic: false,actif : false},
  {name: 'physics', popular: false, graphic: false,actif : false},
  {name: 'pinball', popular: false, graphic: false,actif : false},
  {name: 'platformer', popular: false, graphic: false,actif : false},
  {name: 'point-and-click', popular: false, graphic: false,actif : false},
  {name: 'puzzle', popular: true, graphic: false,actif : false},
  {name: 'racing', popular: false, graphic: false,actif : false},
  {name: 'roguelike', popular: false, graphic: false,actif : false},
  {name: 'roguelite', popular: false, graphic: false,actif : false},
  {name: 'role-playing', popular: false, graphic: false,actif : false},
  {name: 'rpg', popular: true, graphic: false,actif : false},
  {name: 'rts', popular: false, graphic: false,actif : false},
  {name: 'sandbox', popular: false, graphic: false,actif : false},
  {name: 'shooting', popular: false, graphic: false,actif : false},
  {name: 'side-scroller', popular: false, graphic: false,actif : false},
  {name: 'simulation', popular: true, graphic: false,actif : false},
  {name: 'social-deduction', popular: false, graphic: false,actif : false},
  {name: 'souls-like', popular: false, graphic: false,actif : false},
  {name: 'sports', popular: false, graphic: false,actif : false},
  {name: 'stealth', popular: false, graphic: false,actif : false},
  {name: 'strategy', popular: true, graphic: false,actif : false},
  {name: 'survival', popular: true, graphic: false,actif : false},
  {name: 'tactical-rpg', popular: false, graphic: false,actif : false},
  {name: 'tbs', popular: false, graphic: false,actif : false},
  {name: 'text-based', popular: false, graphic: false,actif : false},
  {name: 'tower-defense', popular: false, graphic: false,actif : false},
  {name: 'tower-rush', popular: false, graphic: false,actif : false},
  {name: 'trivia', popular: false, graphic: false,actif : false},
  {name: 'visual-novel', popular: false, graphic: false,actif : false},
  {name: 'vr', popular: false, graphic: false,actif : false},
  {name: 'walking-simulator', popular: false, graphic: false,actif : false},
  {name: 'western-rpg', popular: false, graphic: false,actif : false}
];
