/**
 * IndiePass API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GameExecutablePath } from './gameExecutablePath';

export interface GameRelease { 
    id?: number;
    major?: number;
    minor?: number;
    patch?: number;
    libelle?: string;
    dateUpload?: Date;
    state?: GameRelease.StateEnum;
    region?: string;
    releaseNotes?: string;
    uploadedBy?: string;
    paths?: Array<GameExecutablePath>;
}
export namespace GameRelease {
    export type StateEnum = 'exploited' | 'available' | 'inprogress' | 'error' | 'waiting' | 'deleted' | 'virus' | 'uploading' | 'history' | 'fileexe' | 'beta';
    export const StateEnum = {
        Exploited: 'exploited' as StateEnum,
        Available: 'available' as StateEnum,
        Inprogress: 'inprogress' as StateEnum,
        Error: 'error' as StateEnum,
        Waiting: 'waiting' as StateEnum,
        Deleted: 'deleted' as StateEnum,
        Virus: 'virus' as StateEnum,
        Uploading: 'uploading' as StateEnum,
        History: 'history' as StateEnum,
        Fileexe: 'fileexe' as StateEnum,
        Beta: 'beta' as StateEnum
    };
}