import {
  Directive,
  Input,
  HostBinding,
  Renderer2,
  ElementRef,
  ViewContainerRef,
  ComponentRef,
  ComponentFactoryResolver, OnChanges
} from '@angular/core';
import {MatButton} from "@angular/material/button";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@Directive({
  selector: '[appDisableButton]'
})
export class DisableButtonDirective implements OnChanges {
  @Input() set appDisableButton(value: boolean) {
    this._appDisableButton = value;
    this.updateButtonState();
  }

  @Input() set disabled(value: boolean) {
    this._disabled = value;
    this.updateButtonState();
  }

  private _appDisableButton: boolean;
  private _disabled: boolean;
  private spinner: ComponentRef<MatProgressSpinner> | null = null;

  @HostBinding('class.mat-button-disabled') get isDisabled() {
    return this._disabled || this._appDisableButton;
  }

  constructor(
    private button: MatButton,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnChanges() {
    this.updateButtonState();
  }

  private updateButtonState() {
    if (this._appDisableButton) {
      this.showSpinner();
    } else {
      this.hideSpinner();
    }

    this.button.disabled = this._disabled || this._appDisableButton;
  }

  private showSpinner() {
    if (!this.spinner) {
      const factory = this.componentFactoryResolver.resolveComponentFactory(MatProgressSpinner);
      this.spinner = this.viewContainerRef.createComponent(factory);
      this.spinner.instance.diameter = 30;
      this.spinner.instance.mode = 'indeterminate';
      this.spinner.instance._elementRef.nativeElement.style.position = 'absolute';
      this.spinner.instance._elementRef.nativeElement.style.top = '50%';
      this.spinner.instance._elementRef.nativeElement.style.left = '50%';
      this.spinner.instance._elementRef.nativeElement.style.transform = 'translate(-50%, -50%)';

      const buttonContent = this.button._elementRef.nativeElement.querySelector('.mat-button-wrapper');
      buttonContent.style.visibility = 'hidden';
      buttonContent.style.position = 'relative';

      this.button._elementRef.nativeElement.style.position = 'relative';
      this.button._elementRef.nativeElement.appendChild(this.spinner.instance._elementRef.nativeElement);
    }
  }

  private hideSpinner() {
    if (this.spinner) {
      this.spinner.destroy();
      this.spinner = null;
    }

    const buttonContent = this.button._elementRef.nativeElement.querySelector('.mat-button-wrapper');
    buttonContent.style.visibility = 'visible';
  }
}
