import {Component, OnInit} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {GameService} from "../../../swagger/api/game.service";
import {Genre} from "../../../swagger/model/genre";
import {ActivatedRoute} from "@angular/router";
import {OrganizationService} from "../../../swagger/api/organization.service";
import {Game} from "../../../swagger/model/game";
import {ActionsEnum} from "../../../snack-bar/enum-actions";
import {IndiepassCodesEnum} from "../../../../model/enums/indiepass-codes-enum";
import {PannelsClassEnum} from "../../../snack-bar/pannels-class-enum";
import {IndiepassModalsService} from "../../../services/indiepass-modals.service";
import {
  IndiePassModalMessage,
  IndiePassModalTitle
} from "../../../services/indiepass-modals/indiepass-modals.component";
import {gameGenres} from "../../../../assets/list/game-genres";
import {Language} from "../../../swagger/model/language";
import {LanguageCode, languages} from "../../../../assets/list/languages";
import {COUNTRIES} from "../../../../model/CountryCode";


@Component({
  selector: 'app-gm-game',
  templateUrl: './gm-game.component.html',
  styleUrls: ['./gm-game.component.scss']
})
export class GmGameComponent implements OnInit {
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  genres: Genre[] = [];
  showTable: boolean = false;
  gameId: number;
  isGamePageComplete: boolean = false;
  isReleaseReady: boolean = false;
  canEdit: boolean = false;
  idOrganization: number;
  modes: any[] = [
    {"name": "Solo", id: 0},
    {"name": "Multi", id: 1},
    {"name": "Solo and Multi", id: 2}
  ]
  allGenres: Genre[] = gameGenres;
  allLanguages: LanguageCode[] = languages;
  displayedColumns: string[] = ['code', 'interface', 'audio', 'subtitle', 'actions'];
  game: Game = {};
  gameInit: Game = {};
  date: Date = new Date();
  genreCtrl = new FormControl();
  langCtrl = new FormControl();
  filteredGenres: Observable<Genre[]>;
  filteredLanguages: Observable<LanguageCode[]>;

  constructor(private gameService: GameService, private organizationService: OrganizationService, private route: ActivatedRoute,
              private modalService: IndiepassModalsService) {


    this.filteredGenres = this.genreCtrl.valueChanges.pipe(
      startWith(''),
      map(name => this._filter(name|| ''))
    );

    this.filteredLanguages = this.langCtrl.valueChanges.pipe(
      startWith(''),
      map(code => this._filterLangue(code || ''))
    );
  }

  ngOnInit(): void {
    this.gameId = +this.route.parent?.snapshot.paramMap.get('idGame')!;
    this.idOrganization = +this.route.parent?.snapshot.paramMap.get('idOrganization')!;
    this.refreshGame();
  }

  canPublish(): boolean {
    return this?.game?.solo! || this?.game?.localMulti! || this?.game?.onlineMulti!;
  }

  canSave(): boolean {
    return JSON.stringify(this.game) !== JSON.stringify(this.gameInit);
  }

  refreshGame() {
    this.organizationService.getGamesByIdForPartner(this.idOrganization, this.gameId).subscribe((game) => {
      this.game = game;
      this.gameInit = JSON.parse(JSON.stringify(game)); // Deep copy
      this.langCtrl.patchValue("")
      this.genreCtrl.patchValue('');

      this.sortLanguages();
      this.showTable = true;
      if (this.game.state === "DRAFT") {
        this.isOneGamePageExploited();
        this.isOneReleaseExploited();
      }
      if (this.game.state === "VALIDATION_IN_PROGRESS") {
        this.canEdit = false;
      } else
        this.canEdit = true;
    });

  }

  isOneGamePageExploited() {
    if (this.game.gamePages?.some(gamePage => gamePage?.state === "EXPLOITED"))
      this.isGamePageComplete = true;
    else
      this.isGamePageComplete = false;
  }

  isOneReleaseExploited() {
    this.gameService.getGameRelease(this.gameId).subscribe((gameReleases) => {
      if (gameReleases && gameReleases.length > 0) {
        if (gameReleases.some(gameRelease => gameRelease?.state === "exploited"))
          this.isReleaseReady = true;
        else
          this.isReleaseReady = false;
      }
    });
  }

  updateListeGenre() {
    this.game.genres = [... this.game.genres!];
  }

  sortLanguages() {
    if (this.game.languages) {
      this.game.languages.sort((a, b) => {
        const languageA = this.allLanguages.find(lang => lang.code === a.code)?.nameEn || '';
        const languageB = this.allLanguages.find(lang => lang.code === b.code)?.nameEn || '';
        return languageA.localeCompare(languageB);
      });
    }
  }

  save() {
    this.gameService.updateGamesInfos(this.game, this.game.id!).subscribe(g => {
      this.organizationService.getGamesByIdForPartner(this.idOrganization, this.gameId).subscribe((game) => {
        this.game = game;
        this.gameInit = JSON.parse(JSON.stringify(game)); // Deep copy
      });
    });
  }

  publishGame() {
    this.modalService.confirmationCustom(IndiePassModalMessage.MD_MSG_ASK_PUBLISH_GAME, IndiePassModalTitle.MD_TITLE_ASK_PUBLISH, true).subscribe((result) => {
    if(result)
    {
      this.gameService.publishGame(this.game.id!).subscribe(() => {
        this.refreshGame();
      });
    }
    });

  }
  cancelPublishGame() {
    this.modalService.confirmationCustom(IndiePassModalMessage.MD_MSG_CONFIRM_CANCEL_PUBLISH_GAME, IndiePassModalTitle.MD_TITLE_CANCEL_PUBLISH_GAME, true).subscribe((result) => {
    if(result)
    {
      this.gameService.publishGame(this.game.id!).subscribe(() => {
        this.refreshGame();
      });
    }
    });

  }

  addGenre(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.viewValue;
    if(!this.game.genres)
    {
      this.game.genres = [];
    }
    if (! this.game.genres.some(genre => genre?.name!.toLowerCase() === value.toLowerCase()) &&  this.game.genres.length < 5) {

      this.game.genres!.push({name: value});
    }
    this.genreCtrl.patchValue('');

  }

  addLanguage(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.viewValue;
    if (this.game.languages === undefined || !this.game.languages) {
      this.game.languages = [];
    }
    const languageCodes = this.allLanguages.filter(langue => langue?.nameEn!.toLowerCase().match(value.toLowerCase()));
    if (languageCodes.length == 1) {
      if (!this.game.languages!.some(langue => langue?.code!.toLowerCase() === languageCodes[0]!.code!.toLowerCase())) {
        this.game.languages!.push({code: languageCodes[0]!.code!});
        this.updateLanguage();
        this.sortLanguages();
      }
    }
    this.langCtrl.patchValue("")
  }

  updateLanguage() {
    console.log("updateLanguage")
    this.game.languages = [...this.game.languages!];
    this.game = {...this.game};

  }

  remove(genre: Genre): void {
    const index =  this.game.genres!.indexOf(genre);
    if (index >= 0) {
      this.game.genres!.splice(index, 1);
    }
    this.genreCtrl.patchValue('');

  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.addGenre(event);
  }

  selectedLangue(event: MatAutocompleteSelectedEvent): void {
    this.addLanguage(event);
  }

  private _filter(value: string): Genre[] {
    const filterValue = value.toLowerCase();
    const availableGenres = this.allGenres.filter(genre =>
      !this.game.genres?.some(selectedGenre => selectedGenre.name === genre.name)
    );
    const filteredGenres = availableGenres.filter(genre =>
      genre.name!.toLowerCase().includes(filterValue)
    )
    console.log(availableGenres)
    return filteredGenres;
  }

  private _filterLangue(value: string): LanguageCode[] {
    const filterValue = value.toLowerCase();
    // Exclure les langues déjà sélectionnées dans this.game.languages
    const availableLanguages = this.allLanguages.filter(langue =>
      !this.game.languages?.some(selectedLang => selectedLang.code === langue.code)
    );

    // Filtrer et trier les langues
    const filteredLanguages = availableLanguages.filter(langue =>
      langue.nameEn!.toLowerCase().includes(filterValue)
    );

    // Tri alphabétique par nameEn
    filteredLanguages.sort((a, b) => {
      if (a.nameEn! < b.nameEn!) return -1;
      if (a.nameEn! > b.nameEn!) return 1;
      return 0;
    });

    return filteredLanguages;
  }

  updateListeLang() {
    //this.game.languages = [...this.game.languages!];
  }

  removeLang(lang: Language) {
    const index = this.game.languages!.indexOf(lang);
    if (index >= 0) {
      this.game.languages!.splice(index, 1);
      this.game.languages = [...this.game.languages!];
      this.langCtrl.patchValue("")

    }
  }

  displayFn(genre: Genre): string {
    return genre && genre.name ? genre.name : '';
  }

  protected readonly ActionsEnum = ActionsEnum;
  protected readonly IndiepassCodesEnum = IndiepassCodesEnum;
  protected readonly PannelsClassEnum = PannelsClassEnum;
}
