<div class="main-wrapper black-theme">
  <div class="login-zone">
    <div *ngIf="!reussi">
      <h1>{{ 'register.enregistrement' | translate }}</h1>
      <form class="example-form" [formGroup]="checkoutForm" (ngSubmit)="onSubmit()">
        <mat-form-field class="example-full-width  input-field" appearance="fill">
          <mat-label>{{ 'general.nomUtilisateur' | translate }}</mat-label>
          <input required id="nickName" maxlength="25" formControlName="nickName" matInput
                 (blur)="checkUsernameAvailability()">
          <mat-error *ngIf="checkoutForm.invalid">{{ getErrorMessage("nickName") | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width  input-field" appearance="fill">
          <mat-label>{{ 'general.entrezVotreEmail' | translate }}</mat-label>
          <input [readonly]="emaillForce" required id="email" maxlength="250" formControlName="email" matInput
                 [name]="'email'">
          <mat-error *ngIf="checkoutForm.invalid">{{ getErrorMessage("email") | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width input-field" appearance="fill">
          <mat-label>{{ 'register.selectionnezVotrePays' | translate }}</mat-label>
          <input type="text"
                 placeholder="{{ 'register.selectionnezVotrePays' | translate }}"
                 matInput
                 (change)="checkCountry()"
                 (blur)="checkCountry()"
                 formControlName="countryCode"
                 [matAutocomplete]="auto">
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.nameEn">
              {{ option.nameEn }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div *ngIf="countryCodeSelected && !countryCodeSelected.isConsummerCountry"
             class="message-pas-dispo message-warning">
          {{ 'register.votrePaysNestPasEncoreDisponniblePourEtreJoueurVousPouvezEnRevanchePublierVosJeux' | translate }}
        </div>
        <div *ngIf="countryCodeSelected && !countryCodeSelected.isDevelopperCountry"
             class="message-pas-dispo message-warning">
          {{ 'register.paysPasDispoPourEtreDev' | translate }}
        </div>
        <mat-form-field class="example-full-width  input-field" appearance="fill">
          <mat-label>{{ 'connexion.entrezVotreMdp' | translate }}</mat-label>
          <input autocomplete="new-password" maxlength="50" formControlName="password" required matInput
                 [type]="hide ? 'password' : 'text'">
          <mat-error *ngIf="!samePassword">{{ getErrorMessage("password")| translate }}</mat-error>
          <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide">
            <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field class="example-full-width  input-field" appearance="fill">
          <mat-label>{{ 'connexion.entrezVotreMdp' | translate }}</mat-label>
          <input autocomplete="new-password" maxlength="50" required formControlName="passwordbis" matInput
                 [type]="hideConfirm ? 'password' : 'text'">
          <mat-error *ngIf="!samePassword">{{ getErrorMessage("passwordbis")| translate }}</mat-error>
          <button type="button" mat-icon-button matSuffix (click)="hideConfirm = !hideConfirm"
                  [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hideConfirm">
            <mat-icon>{{ hideConfirm ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>

        </mat-form-field>
        <div class="list-check-box" fxLayout="column" fxLayoutAlign="start start">
          <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
            <mat-checkbox
              formControlName="checkBoxNewsLetter">{{ 'register.JeSouhaiteMinscrireNewsletter' | translate }}
            </mat-checkbox>
          </div>
          <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
            <mat-checkbox required
                          formControlName="checkBox">{{ 'register.jaiLuEtJaccepte' | translate }}
            </mat-checkbox>
            <a
              target="_blank" href="/about">{{ 'register.lesConditionsDutilisation' | translate }}</a>
          </div>
          <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
            <mat-checkbox required
                          formControlName="checkBoxBeta">{{ 'register.jaiLuEtJaccepte' | translate }}
            </mat-checkbox>
            <a
              target="_blank" href="/about#tou-beta">{{ 'register.lesConditionsDutilisationDeLaBeta' | translate }}</a>
          </div>
        </div>
        <br>
        <button [appDisableButton]="loading" [disabled]="!checkoutForm.valid" type="submit" mat-stroked-button
                class="btn-block">{{ 'register.senregistrer' | translate }}
        </button>
        <br><br>
      </form>
      {{ 'register.vousAvezDejaUnCompte?' | translate }}<a
      routerLink="/login"> {{ 'register.seConnecter' | translate }}</a>
      <br><br>
    </div>
    <div *ngIf="reussi">
      <h1>{{ 'register.enregistrementReussi' | translate }}</h1>
      <p>
        {{ 'register.verifiezVosMails' | translate }}
      </p>
    </div>
  </div>
</div>
