import {Component, OnInit} from '@angular/core';
import {GeneralService} from "../../general.service";
import {CreerJeuPopupComponent} from "./editeur/creer-jeu-popup/creer-jeu-popup.component";
import {MatDialog} from "@angular/material/dialog";
import {HttpClient} from "@angular/common/http";
import {Jeu} from "../../../model/jeu";
import {environment} from "../../../environments/environment";
import {OrganizationService} from "../../swagger/api/organization.service";
import {Game} from "../../swagger/model/game";
import {GameService} from "../../swagger/api/game.service";
import {ActivatedRoute, Router} from "@angular/router";
import {OrganizationContextService} from "../../services/organization-context.service";
import {Utilisateur} from "../../swagger/model/utilisateur";
import {MatTableDataSource} from "@angular/material/table";
import {RouteParamService} from "../../services/route-param.service";
import {AuthService} from "../../swagger/api/auth.service";
import {UserInfos} from "../../swagger/model/userInfos";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public jeuxPublisher: Jeu[] = [];
  public selectedIndex: number = 0;
  public organizationLibele: string = "";
  public userInfos: UserInfos;

  constructor(public generalService: GeneralService,
              private organizationService: OrganizationService,
              private routeParamService: RouteParamService,
              private authService: AuthService,
              public organizationContextService: OrganizationContextService,
              public dialog: MatDialog,
              private router: Router, private route: ActivatedRoute) {
    this.userInfos = this.generalService.getUserInfos()
    if(this.userInfos.organizations && this.userInfos.organizations.length > 0) {
      if(this.userInfos.organizations.find(org => org.id === this.userInfos.orgaId)!?.name !== undefined) {
        this.organizationLibele = this.userInfos.organizations.find(org => org.id === this.userInfos.orgaId)!?.name!;
        console.log(this.organizationLibele)
      }
    }
    if (this.generalService.getMemberId() == null) {
      this.authService.changeOrganizationContext(this.routeParamService.getRouteParameterNumber('idOrganization')!).subscribe(response => {
      })
    }
    this.organizationContextService.delMemberContext();
    this.organizationService.getMembers(this.routeParamService.getRouteParameterNumber('idOrganization')!).subscribe((members: Utilisateur[]) => {
      for (let member of members) {
        if (member.email === this.generalService.getUserInfos().email) {
          this.organizationContextService.setMemberContext(member);
        }
      }
    });
  }

  changeOrganizationContext(value : any) {
   this.router.navigate(['/dashboard/'+value.value+"/games"])
      .then(() => {
        window.location.reload();
      });

  }

  ngOnInit(): void {

    this.getJeuxPublisher();

    if (this.route.firstChild) {
      this.route.firstChild.url.subscribe(urlPath => {
        this.setActiveTabBasedOnRoute(urlPath[0].path);
      });
    } else {
      this.router.navigate(['./games'], {relativeTo: this.route});
    }
  }

  setActiveTabBasedOnRoute(path: string) {
    if (path.startsWith('organization')) {
      this.selectedIndex = 1; // Index de l'onglet "Mon organisation"
    } else if (path === 'games') {
      this.selectedIndex = 0; // Index de l'onglet "Jeux"
    } else if (path === 'editor') {
      this.selectedIndex = 2; // Index de l'onglet "Jeux"
    } else if (path === 'developer') {
      this.selectedIndex = 3; // Index de l'onglet "Jeux"
    }
  }


  onTabChange(index: number) {
    switch (index) {
      case 0:
        this.router.navigate(['./games'], {relativeTo: this.route});
        break;
      case 1:
        this.router.navigate(['./organization/members'], {relativeTo: this.route});
        break;
      case 2:
        this.router.navigate(['./editor'], {relativeTo: this.route});
        break;
      case 3:
        this.router.navigate(['./developer'], {relativeTo: this.route});
        break;
    }
  }


  getJeuxPublisher() {

  }
}
