import {Component, Input, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {GameSearchResult} from "../../swagger/model/gameSearchResult";
import {Router} from "@angular/router";
import {GameService} from "../../swagger/api/game.service";
import {MatDialog} from "@angular/material/dialog";
import {ItemLibrary} from "../../swagger/model/itemLibrary";
import {IndiepassCodesEnum} from "../../../model/enums/indiepass-codes-enum";
import {ActionsEnum} from "../../snack-bar/enum-actions";
import {PannelsClassEnum} from "../../snack-bar/pannels-class-enum";
import {UserService} from "../../swagger/api/user.service";
import {GeneralService} from "../../general.service";
import {SnackBarService} from "../../services/snack-bar.service";
import {TranslatePipe} from "@ngx-translate/core";

@Component({
  selector: 'app-item-library',
  templateUrl: './item-library.component.html',
  styleUrls: ['./item-library.component.scss']
})
export class ItemLibraryComponent implements OnInit {
  imageLoaded = false;
  @Input() public libraryItem: ItemLibrary = {} as ItemLibrary;

  constructor(private translatePipe: TranslatePipe, private router: Router, private userService: UserService,private generalService: GeneralService,private snackBarService: SnackBarService, public dialog: MatDialog) {

  }

  navigateToGame() {
    this.router.navigate(['/game/' + this.libraryItem.idGame]);
  }

  ngOnInit(): void {

  }
  removeFromLibrary(): void {
    console.log("removeFromLibrary")
    this.userService.deleteGameInUserLibrary(this.generalService.getUserId(), this.libraryItem.idGame!).subscribe(() => {
      this.libraryItem = {};
    }, error => {
      this.snackBarService.openSnackBar(IndiepassCodesEnum.UPDATE_KO, ActionsEnum.Ok, PannelsClassEnum.error);
    });
  }
  public convertSeconds(seconds: number): string {
    const minutes = seconds / 60;

    if (minutes < 60) {
      return `${minutes.toFixed(2)} `+this.translatePipe.transform("general.minutes").toLowerCase();
    }

    const hours = minutes / 60;
    return `${hours.toFixed(2)} `+this.translatePipe.transform("general.heures").toLowerCase();
  }
  public readonly environment = environment;
}
