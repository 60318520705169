<div class="white-theme">
  <h1 *ngIf="data?.title" mat-dialog-title>{{ data.title! | translate }}</h1>
  <p *ngIf="data?.message" class="pre-line justify">
    {{ data?.message! | translate }}
  </p>
  <div *ngIf="data?.action === IndiePassModalAction.CONFIRMATION" fxLayoutGap="20px">
    <div *ngIf="data?.needCheckbox">
      <mat-checkbox [(ngModel)]="checked" color="primary">
        {{ 'MD_jaiLuEtJaccepteCesConditions' | translate }}
      </mat-checkbox>
      <br>
      <br>

    </div>

    <button mat-raised-button color="warn" (click)="annuler()">{{ 'general.annuler' | translate }}</button>
    <button [disabled]="!checked" mat-raised-button (click)="Valider()" color="primary">
      {{ 'general.valider' | translate }}
    </button>
  </div>
</div>
