import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {AuthService} from 'src/app/swagger/api/auth.service';
import {Utilisateur} from "../../swagger/model/utilisateur";

import {SnackBarService} from "../../services/snack-bar.service";
import {CookieService} from "../../cookie.service";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public canSowUp: boolean = false;
  public canSubPassword: boolean = false;
  hide = true;
  hide1 = true;
  hideConfirm = true;
  checkoutForm: FormGroup;
  private url: string
  public email: string
  public temp_url_sig: string
  public TTL: number
  samePassword: boolean = false;

  constructor(private snackBarService: SnackBarService, private formBuilder: FormBuilder, private router: Router, private cookieService: CookieService,
              private authService: AuthService, private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.email = params['email'];
      this.TTL = params['TTL'];
      this.temp_url_sig = params['temp_url_sig'];
      this.canSowUp = true;
    })
  }

  ngOnInit(): void {
    this.checkoutForm = this.formBuilder.group({
      password: new FormControl(null, [Validators.minLength(7), Validators.maxLength(50)]),
      passwordbis: new FormControl(null)
    }, {validators: this.checkPasswords});
  }

  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    const pass = this.checkoutForm?.get('password')?.value;
    const confirmPass = group.get('passwordbis')?.value;
    if (pass && confirmPass && pass === confirmPass) {
      this.samePassword = true;
      group.get('passwordbis')?.setErrors(null);
      return null;
    } else {
      this.samePassword = false;
      group.get('passwordbis')?.setErrors({notSame: true});
      return {notSame: true};
    }
  }

  sendNewPassword() {
    let user: Utilisateur = {};
    user.password = this?.checkoutForm?.get('password')!.value;
    this.authService.resetPassword(user, this.email, this.TTL, this.temp_url_sig).subscribe(() => {
      this.authService.logout().subscribe(() => {
        this.cookieService.delete_cookie("user_infos");
        this.cookieService.delete_cookie("access_token");
        this.router.navigate(['/login']).then(() => window?.location.reload());
      });
    });
  }

  getErrorMessage(check: string) {
    if (check === "email" && this.checkoutForm.controls["email"].hasError('required')) {
      return 'general.vousDevezEntrerUneValeur';
    } else if (check === "email" && this.checkoutForm.controls["email"].hasError('email')) {
      return 'register.emailInvalid';
    } else if (check === "nickName" && this.checkoutForm.controls["nickName"].hasError('required')) {
      return 'general.vousDevezEntrerUneValeur';
    } else if (check === "nickName" && this.checkoutForm.controls["nickName"].hasError('minlength')) {
      return 'register.nomutilisateurTropCourt';
    } else if (check === "nickName" && this.checkoutForm.controls["nickName"].hasError('invalidUsername')) {
      return 'register.nomutilisateurFormatInvalide';
    } else if (check === "nickName" && this.checkoutForm.controls["nickName"].hasError('usernameTaken')) {
      return 'register.nomutilisateurDejaPris';
    } else if (check === "nickName" && this.checkoutForm.controls["nickName"].hasError('maxlength')) {
      return 'register.nomutilisateurTropLong';
    } else if (check === "passwordbis" && this.checkoutForm.controls['passwordbis']?.hasError('notSame')) {
      return 'register.mdpNeMatchPas';
    } else if (check === "password" && this.checkoutForm.controls['password']?.hasError('minlength')) {
      return 'register.mdpTropcourt';
    } else if (check === "password" && this.checkoutForm.controls['password']?.hasError('required')) {
      return 'general.vousDevezEntrerUneValeur';
    } else if (check === "password" && this.checkoutForm.controls['password']?.hasError('maxlength')) {
      return 'register.mdpTropLong';
    }
    return '';
  }
}
