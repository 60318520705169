import {Component, OnInit} from '@angular/core';
import {CookieService} from "../cookie.service";
import {TranslateService} from "@ngx-translate/core";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  urlCGU: SafeResourceUrl;
  urlCGUBeta: SafeResourceUrl;

  constructor(private cookieService: CookieService, public translateService: TranslateService,public sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    let cguUrl
    let cguBetaUrl
    switch (this.translateService.currentLang) {
      case "fr" : {
        cguUrl = "https://cgu.pages.dev/cgu-fr";
        cguBetaUrl = "https://cgu.pages.dev/cgu-beta-fr";
        break;
      }
      default : {
        cguUrl = "https://cgu.pages.dev/cgu-en";
        cguBetaUrl = "https://cgu.pages.dev/cgu-beta-en";
      }
    }
    this.urlCGU = this.sanitizer.bypassSecurityTrustResourceUrl(cguUrl)
    this.urlCGUBeta = this.sanitizer.bypassSecurityTrustResourceUrl(cguBetaUrl)

  }
  showCookieOption() {
    this.cookieService.setShowOptions(true);

  }
}
