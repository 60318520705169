import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {OrganizationService} from "../../../../swagger/api/organization.service";
import {Member} from "../../../../swagger/model/member";
import {map, Observable, startWith} from "rxjs";
import {FormControl} from "@angular/forms";
import {GameService} from "../../../../swagger/api/game.service";
import {SnackBarService} from "../../../../services/snack-bar.service";
import {IndiepassCodesEnum} from "../../../../../model/enums/indiepass-codes-enum";
import {ActionsEnum} from "../../../../snack-bar/enum-actions";
import {PannelsClassEnum} from "../../../../snack-bar/pannels-class-enum";

@Component({
  selector: 'app-add-member-popup',
  templateUrl: './add-member-popup.component.html',
  styleUrls: ['./add-member-popup.component.scss']
})
export class AddMemberPopupComponent implements OnInit {
  public membersToAdd: Member[] = [];
  formControl = new FormControl('');
  filteredOptions: Observable<Member[]>;

  constructor(private organizationService: OrganizationService, private gameServices: GameService,
              public dialogRef: MatDialogRef<AddMemberPopupComponent>, public snackBarService: SnackBarService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.data = data;
  }

  displayFn(member: Member): string {
    return member && member.email ? member.email : '';
  }

  ngOnInit(): void {
    this.organizationService.getMembers(this.data.idOrganization).subscribe(members => {
      this.membersToAdd = members;
      let membersInit: Member[] = this.data.members;
      //for eache member in data.members, remove it from membersToAdd if email is the same
      membersInit.forEach(member => {
        this.membersToAdd = this.membersToAdd.filter(m => m.email !== member.email && m.valide);
      })
      this.filteredOptions = this.formControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '')),
      );
    });

  }

  private _filter(value: string): Member[] {
    const filterValue = value.toLowerCase();

    return this.membersToAdd.filter(option => option.email?.toLowerCase().includes(filterValue));
  }

  addMember() {
    this.gameServices.addMemberGameRightsByEmail(this.data.idGame, this.formControl.value.email).subscribe(member => {
        this.data.son.fetchMembers();
        this.membersToAdd = {...this.membersToAdd.filter(m => m.email !== this.formControl.value.email)};
        this.formControl.setValue('');

        this.snackBarService.openSnackBar(IndiepassCodesEnum.MEMBER_ADD_OK, ActionsEnum.Ok, PannelsClassEnum.info);
      },
      error => {
        this.snackBarService.openSnackBar(IndiepassCodesEnum.MEMBER_ADD_KO, ActionsEnum.Ok, PannelsClassEnum.error);
      })
  }
}
