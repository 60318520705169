<div class="white-theme">
<!-- Bouton d'activation -->
<button mat-fab color="primary" aria-label="GiveFeedback" (click)="toggleFeedbackForm()">
  <mat-icon>feedback</mat-icon>
</button>

<!-- Formulaire de feedback -->
<div *ngIf="showFeedbackForm" class="feedback-form">
  <div class="feedback-header">
    <h2>Feedback</h2>
    <button mat-icon-button (click)="showFeedbackForm = false">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="feedbackForm" (ngSubmit)="submitFeedback()">
    <mat-form-field appearance="fill">
      <mat-label>Email</mat-label>
      <input autocomplete="email" matInput formControlName="email" type="email"/>
      <mat-error *ngIf="feedbackForm.get('email')!.hasError('email')">
        Invalid email format
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Object</mat-label>
      <input name="disable-pwd-mgr-1" type="password" id="disable-pwd-mgr-1" style="display: none;" value="disable-pwd-mgr-1" />
      <input name="disable-pwd-mgr-2" type="password" id="disable-pwd-mgr-2" style="display: none;" value="disable-pwd-mgr-2" />
      <input name="disable-pwd-mgr-3" type="password" id="disable-pwd-mgr-3" style="display: none;" value="disable-pwd-mgr-3" />
      <input autocomplete="off" aria-autocomplete="none" matInput formControlName="object" type="text" maxlength="40"/>
      <mat-hint align="end">{{ feedbackForm.get('object')!.value?.length || 0 }}/40</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Theme</mat-label>
      <mat-select formControlName="theme" (openedChange)="onDropdownOpenedChange($event)">
        <mat-option *ngFor="let theme of themes" [value]="theme.id">{{ theme.label }}</mat-option>
      </mat-select>
    </mat-form-field>


    <mat-form-field appearance="fill">
      <mat-label>Message</mat-label>
      <textarea matInput formControlName="message" maxlength="1000"></textarea>
      <mat-hint align="end">{{ feedbackForm.get('message')!.value?.length || 0 }}/1000</mat-hint>
    </mat-form-field>

    <button mat-raised-button color="primary" [disabled]="!feedbackForm.valid">{{ "general.envoyer" | translate }}</button>
  </form>
</div>
</div>
