<div class="parent">
  <div #rectangle class="rectangle">
    <div class="content">
    <span matSnackBarLabel>
  {{message | translate}}
</span>
      <br>
      <br>

      <span class="span-button-action" matSnackBarActions>
  <button class="button-action-btn" mat-button (click)="snackBarRef.dismissWithAction()">{{action}}</button>
</span>
    </div>
  </div>
</div>
