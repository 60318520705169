import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, switchMap} from 'rxjs';
import {AuthService} from "../swagger/api/auth.service";
import {GeneralService} from "../general.service";
import {RouteParamService} from "../services/route-param.service";
import {UserInfos} from "../swagger/model/userInfos";


@Injectable({
  providedIn: 'root'
})
export class GameGuard implements CanActivate {
  constructor(private authService: AuthService, private generalService: GeneralService, private router: Router,
              private routeParamService: RouteParamService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let userInfos: UserInfos = this.generalService.getUserInfos();
    let idGame: number = this.routeParamService.getRouteParameterNumberInActivatedRouteSnapshot("idGame", next)!;
    let idOrganization: number = this.routeParamService.getRouteParameterNumberInActivatedRouteSnapshot("idOrganization", next)!;

    if (userInfos != null && userInfos.memberId != null
      && idOrganization === userInfos.orgaId
      && idGame === userInfos.gameId) {
      return true;
    }
    return this.authService.changeOrganizationContext(
      idOrganization, idGame).pipe(source => source.pipe(
      switchMap(() => {
        let userInfosResult: UserInfos = this.generalService.getUserInfos();
        if (userInfosResult.orgaId != null && userInfosResult.memberId != null && userInfosResult.gameId != null
          && idOrganization === userInfosResult.orgaId && idGame === userInfosResult.gameId) {
          return [true];
        } else {
          this.router.navigate(['/']);
          return [false];
        }
      })
    ))
  }


}
