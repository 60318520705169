import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Organization} from "../../swagger/model/organization";
import {GeneralService} from "../../general.service";
import {HttpClient} from "@angular/common/http";
import {OrganizationService} from "../../swagger/api/organization.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {RouteParamService} from "../route-param.service";
import {DialogData} from "../../profil/profil.component";

@Component({
  selector: 'app-indiepass-modals',
  templateUrl: './indiepass-modals.component.html',
  styleUrls: ['./indiepass-modals.component.scss']
})
export class IndiepassModalsComponent implements OnInit {

  jeuForm: FormGroup;
  private organization: Organization;
  // @ts-ignore
  myControl = new FormControl<string | Developper>('');
  public checked: boolean = false;

  constructor(public generalService: GeneralService,
              private organizationService: OrganizationService, public dialog: MatDialog, private routeParamService: RouteParamService,
              public dialogRef: MatDialogRef<IndiepassModalsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: IndiePassModalData, private fb: FormBuilder) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  ngOnInit(): void {
this.checked = !this.data?.needCheckbox;
  }

  Valider() {
    this.dialogRef.close(true);
  }

  annuler() {
    this.dialogRef.close(false);
  }

  readonly IndiePassModalAction = IndiePassModalAction;
}

export interface IndiePassModalData {
  title?: IndiePassModalTitle;
  message?: IndiePassModalMessage;
  action?: IndiePassModalAction;
  needCheckbox?: boolean;
}

export enum IndiePassModalTitle {
  MD_TITLE_NOT_SAVE = 'MD_TITLE_NOT_SAVE',
  MD_TITLE_CONFIRM_DELETE = 'MD_TITLE_CONFIRM_DELETE',
  MD_TITLE_DELETE_ACCOUNT = 'MD_TITLE_DELETE_ACCOUNT',
  MD_TITLE_ASK_PUBLISH = 'MD_TITLE_ASK_PUBLISH',
  MD_TITLE_CANCEL_PUBLISH_GAME = 'MD_TITLE_CANCEL_PUBLISH_GAME',
}

export enum IndiePassModalMessage {
  MD_MSG_NOT_SAVE = 'MD_MSG_NOT_SAVE',
  MD_MSG_CONFIRM_PUBLICATION_GAMEPAGE = 'MD_MSG_CONFIRM_PUBLICATION_GAMEPAGE',
  MD_MSG_CONFIRM_DELETE_MEMBER = 'MD_MSG_CONFIRM_DELETE_MEMBER',
  MD_MSG_CONFIRM_DELETE = 'MD_MSG_CONFIRM_DELETE',
  MD_MSG_CONFIRM_CANCEL_PUBLISH_GAME = 'MD_MSG_CONFIRM_CANCEL_PUBLISH_GAME',
  MD_MSG_CONFIRM_DELETE_ACCOUNT = 'MD_MSG_CONFIRM_DELETE_ACCOUNT',
  MD_MSG_ASK_PUBLISH_GAME = 'MD_MSG_ASK_PUBLISH_GAME',
}

export enum IndiePassModalAction {
  CONFIRMATION = 'CONFIRMATION',
}
