import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {DomSanitizer} from "@angular/platform-browser";
import {environment} from "../../environments/environment";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {FormControl} from "@angular/forms";
import {Subscription} from "rxjs";
import {SnackBarService} from "../services/snack-bar.service";
import {MatDialog} from "@angular/material/dialog";
import {GameLight} from "../swagger/model/gameLight";
import {GameService} from "../swagger/api/game.service";
import {UserService} from "../swagger/api/user.service";
import {GeneralService} from "../general.service";
import {IndiepassCodesEnum} from "../../model/enums/indiepass-codes-enum";
import {ActionsEnum} from "../snack-bar/enum-actions";
import {PannelsClassEnum} from "../snack-bar/pannels-class-enum";
import {AchievementService} from "../swagger/api/achievement.service";
import {Achievement} from "../swagger/model/achievement";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-gamepage',
  templateUrl: './gamepage.component.html',
  styleUrls: ['./gamepage.component.scss'],
})

export class GamepageComponent implements OnInit, AfterViewInit {
  private canSlide = true;
  @Input()
  gameLight: GameLight | undefined;
  videoUrl: any;
  canAddGameToLibrary: boolean = false;
  videoForm = new FormControl();
  imageSrc: string = "";
  isHovering: boolean = false;
  public userId: number | undefined;
  private navigationSubscription: Subscription
  public achievements: Achievement[] | undefined;
  @ViewChild('screenShots', {static: false})
  private screenShots: HTMLElement;
  isLoading = true;
  coverIsLoading = true;

  constructor(private translate: TranslateService, public sanitizer: DomSanitizer, private route: ActivatedRoute, private generalService: GeneralService, private gameService: GameService,
              public snackBarService: SnackBarService, public userService: UserService, public router: Router, public achievementService: AchievementService,
              public dialog: MatDialog) {
    this.navigationSubscription = this.route.params.subscribe(params => {
      this.gameLight = undefined;
      this.achievements = undefined;
      this.loadGame(params['id']);
    });
  }

  ngOnInit(): void {
    this.loadGame(this.route.snapshot.params["id"]);

  }
  ngAfterViewInit() {
    this.isLoading = false;

  }
  loadGame(gameId: number): void {
    if (!this.gameLight || this.gameLight.id !== gameId) {
      this.gameService.getGameById(gameId, false, this.translate.currentLang).subscribe((game) => {
        this.gameLight = {...game};
        this.checkGameIsInLibrary();

      }, error => {
        this.router.navigate(['/']);
      });

      this.achievementService.getAchievement(gameId).subscribe((achievements) => {
        this.achievements = [...achievements];
      });
    } else {
      this.checkGameIsInLibrary();
    }
  }

  checkGameIsInLibrary(): void {

    this.userId = this.generalService.getUserId();
    if (this.generalService.getUserId() && this.gameLight!.id != null) {
      console.log("checkGameIsInLibrary");

      this.userService.gameIsInUserLibrary(this.generalService.getUserId(), this.gameLight!.id!).subscribe((isInLibrary) => {
        this.canAddGameToLibrary = !isInLibrary;

      }, error => {
        this.canAddGameToLibrary = true;

      });
    } else {
      this.canAddGameToLibrary = true;
    }

  }

  addToLibrary(): void {
    if (!this.generalService.getIsConnected() && !this.generalService.getIsinclient()) {
      window.open(environment.mainDomain + "/login", "_blank");

    } else {
      this.userService.addGameToLibrary(this.generalService.getUserId(), this.gameLight!.id!).subscribe(() => {
        this.checkGameIsInLibrary();
      }, error => {
        this.snackBarService.openSnackBar(IndiepassCodesEnum.UPDATE_KO, ActionsEnum.Ok, PannelsClassEnum.error);
      });
    }
  }

  removeFromLibrary(): void {
    console.log("removeFromLibrary")
    this.userService.deleteGameInUserLibrary(this.generalService.getUserId(), this.gameLight!.id!).subscribe(() => {
      this.checkGameIsInLibrary();
    }, error => {
      this.snackBarService.openSnackBar(IndiepassCodesEnum.UPDATE_KO, ActionsEnum.Ok, PannelsClassEnum.error);
    });
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }


  readonly environment = environment;
}
