import {Component, OnInit, ViewChild} from '@angular/core';
import {GeneralService} from "../../../general.service";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router, RouterOutlet} from "@angular/router";
import {OrganizationContextService} from "../../../services/organization-context.service";
import {environment} from "../../../../environments/environment";


@Component({
    selector: 'app-organization',
    templateUrl: './organization.component.html',
    styleUrls: ['./organization.component.scss']

})
export class OrganizationComponent implements OnInit {
    public selectedIndex: number = 0;
    constructor(public generalService: GeneralService,
                public organizationContextService: OrganizationContextService,
                public dialog: MatDialog, private router: Router,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        if (this.route.firstChild) {
            this.route.firstChild.url.subscribe(urlPath => {
                this.setActiveTabBasedOnRoute(urlPath[0].path);
            });
        }

    }

    setActiveTabBasedOnRoute(path: string) {
        if (path.startsWith('"general"')) {
            this.selectedIndex = 0; // Index de l'onglet "Mon organisation"
        } else if (path === 'members') {
            this.selectedIndex = 1; // Index de l'onglet "Jeux"
        }else if (path === 'finances') {
            this.selectedIndex = 2; // Index de l'onglet "Jeux"
        }
    }

    onTabChange(index: number) {
        switch (index) {
            case 0:
                this.router.navigate(['./general'], {relativeTo: this.route});
                break;
            case 1:
                this.router.navigate(['./members'], {relativeTo: this.route});
                break;
            case 2:
                this.router.navigate(['./finances'], {relativeTo: this.route});
                break;

        }

    }

  isBeta: boolean = environment.isBeta;

}
