<mat-card [style.background-image]="'linear-gradient( to right, rgb(1, 3, 14), rgb(0 0 255 / 0%) ),' +
 'url('+getBanniere(gamePage.id)+')'">

  <mat-card-title>{{ title }}</mat-card-title>

  <mat-card-subtitle>
    <div fxLayout="row">
      <div>{{ 'general.etat' | translate }} :</div>
      <div class="{{gamePage?.state}}">{{ gamePage?.state }}</div>
    </div>
    <div fxLayoutGap="10px" fxLayout="row">
      <div>{{ 'general.languages' | translate }} :</div>
      <div *ngFor="let lang of gamePage?.translations" fxLayout="row" fxLayoutAlign="start center">
        <div [matTooltip]="lang?.message!" class="{{lang?.complete ? 'OK' : 'KO'}}">{{ lang.languageCode }}</div>
      </div>

    </div>
  </mat-card-subtitle>
  <mat-card-actions>
    <button *ngIf="gamePage?.state?.toString() === 'DRAFT'" (click)="editGamePage()" mat-raised-button class="editer"
            color="primary">{{ 'general.editer' | translate }}
    </button>
    <button *ngIf="gamePage?.state?.toString() === 'DRAFT'" [disabled]="canValidate" (click)="demanderValidation()"
            mat-raised-button
            class="demanderValidation" color="primary">{{ 'general.publierPageDeJeu' | translate }}
    </button>
    <button *ngIf="gamePage?.state?.toString() === 'EXPLOITED'" (click)="view()"
            mat-raised-button
            class="demanderValidation" color="primary">{{ 'general.voir' | translate }}
    </button>

    <button [disabled]="parent.gamePages?.length! >4" (click)="duplicate()" mat-raised-button
            class="annuler" color="primary"> {{ 'general.dupliquer' | translate }}
    </button>
    <button *ngIf="gamePage?.state?.toString() === 'DRAFT'" (click)="deleteGamePage()" mat-raised-button
            class="annuler" >{{ 'general.supprimer' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
