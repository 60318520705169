import {Envs} from "../model/enums/envs";

export const environment = {
  urlimgcdn : "https://imgcdn.indiepass.com/prod/",
  googleTagManagerId : "G-HF89RH8RYZ",
  googleTagManagerIdCookie : "_ga_HF89RH8RYZ",
  mainDomain : "https://indiepass.com",
  basePath: "https://api.indiepass.com/v1",
  urlClientDDL: "https://client.indiepass.com/IndiePass Setup 0.0.2.exe",
  environment: Envs.PROD,
  presentation: true,
  production: true,
  baseUrlAPI :"https://api.indiepass.com/v1",
  isBeta: true,
};
