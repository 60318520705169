import {Component, Input, OnInit} from '@angular/core';
import {Game} from "../../../../swagger/model/game";
import {OrganizationService} from "../../../../swagger/api/organization.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {ChoixPagePopupComponent} from "../../../jeu-element/choix-page-popup/choix-page-popup.component";
import {ConditionsPopupComponent} from "../../../jeu-element/conditions-popup/conditions-popup.component";
import {GamePage} from "../../../../swagger/model/gamePage";
import {GamePageService} from "../../../../swagger/api/gamePage.service";
import {CreerGamepageComponent} from "../../../jeu-element/choix-page-popup/creer-gamepage/creer-gamepage.component";
import {GmGamePagesComponent} from "../gm-game-pages.component";
import {IndiepassCodesEnum} from "../../../../../model/enums/indiepass-codes-enum";
import {ActionsEnum} from "../../../../snack-bar/enum-actions";
import {PannelsClassEnum} from "../../../../snack-bar/pannels-class-enum";
import {SnackBarService} from "../../../../services/snack-bar.service";
import {environment} from "../../../../../environments/environment";
import {IndiepassModalsService} from "../../../../services/indiepass-modals.service";
import {
  IndiePassModalMessage,
  IndiePassModalTitle
} from "../../../../services/indiepass-modals/indiepass-modals.component";

@Component({
  selector: 'app-gm-game-page-element',
  templateUrl: './gm-game-page-element.component.html',
  styleUrls: ['./gm-game-page-element.component.scss']
})
export class GmGamePageElementComponent implements OnInit {

  @Input()
  title: string = ""
  @Input()
  gamePage: GamePage;

  public canValidate: boolean = false;

  @Input()
  parent: GmGamePagesComponent;

  @Input()
  idGame: number;


  constructor(private organizationService: OrganizationService, private router: Router, public dialog: MatDialog, private indiePassModalService: IndiepassModalsService,
              private gamePageService: GamePageService, private snackBarService: SnackBarService, private indiepassModalsService: IndiepassModalsService) {

  }

  ngOnInit(): void {

    //for each gamePageTranslation, check if it's complete
    //if it's complete, check if it's validated

    this.canValidate = !this.gamePage?.translations?.every(translation => {
      return translation?.complete!;
    })!;
  }

  deleteGamePage(): void {
    if (this.gamePage.id !== undefined) {
      this.indiepassModalsService.confirmationDelete().subscribe(result => {
        if (result) {
          this.gamePageService.deleteGamePageById(this.gamePage.id!).subscribe(response => {
            this.parent.refreshGamePageList();
          }, error => {
            this.snackBarService.openSnackBar(IndiepassCodesEnum.GAMEPAGE_SUPPRIME_KO, ActionsEnum.Ok, PannelsClassEnum.error)

          })
        }
      });
    }

  }

  demanderValidation() {

    const dialogRef = this.indiePassModalService.confirmationCustom(IndiePassModalMessage.MD_MSG_CONFIRM_PUBLICATION_GAMEPAGE, IndiePassModalTitle.MD_TITLE_ASK_PUBLISH, true)

    dialogRef.subscribe(result => {
      if (result) {
        this.gamePageService.submitGamePageById(this.gamePage.id!).subscribe(response => {
          this.parent.refreshGamePageList();
        });

      }
    });
  }

  duplicate() {
    const dialogRef = this.dialog.open(CreerGamepageComponent, {
      width: '550px'});
    dialogRef.afterClosed().subscribe(titre => {
      if (titre) {
        this.gamePageService.createGamePage(this.idGame!, titre).subscribe(response => {
          // for each translation, create a new translation
          this.gamePage.translations?.forEach(translation => {
            this.gamePageService.updateGamePageTranslation(translation, response.id!).subscribe(response => {
              this.parent.refreshGamePageList();

            });
          });
        }, error => {

        })
      }
    })

  }



  editGamePage(): void {
    if (this.gamePage.id !== undefined) {
      this.router.navigate([this.idGame + "/create/" + this.gamePage.id])

    }
  }
  view(): void {
    if (this.gamePage.id !== undefined) {
      this.router.navigate([this.idGame + "/create/" + this.gamePage.id])

    }
  }

  demanderRetrait() {
    const dialogRef = this.dialog.open(ConditionsPopupComponent, {
      width: '550px',
      data: {
        titre: 'Demande de retrait',
        text: 'Vous êtes sur le point de demander le retrait de votre jeu'
      },

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {

      }
    });
  }


  updateLinkState(value: boolean) {
    this.organizationService.acceptLink(this.gamePage?.id!, value).subscribe();
  }

  getBanniere(id: number | undefined) {
    let dangerousVideoUrl = "";
    //get english translation
    this?.gamePage.translations?.forEach(translation => {
      if (translation.languageCode === "EN") {
         dangerousVideoUrl = environment.urlimgcdn + this.idGame + "/" + translation?.graphics?.cover?.fileName;
      }
    });
    return dangerousVideoUrl;
  }
}
