import {Component, Inject, OnInit} from '@angular/core';
import { CookieService } from "../cookie.service";

interface CookieOption {
  name: string;
  description: string;
  accepted: boolean;
  readOnly: boolean;
}

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent implements OnInit {

  cookiesAccepted: boolean;
  showOptions: boolean = false;
  cookiesList: CookieOption[] = [
    { name: 'cookie.necessaire', description: 'cookie.necessaireDesc', accepted: true, readOnly: true },
    { name: 'cookie.analtiques', description: 'cookie.analtiquesDesc', accepted: false, readOnly: false },
  ];

  constructor(private cookieService: CookieService,@Inject('googleTagManagerId') private gtmId: string,@Inject('googleTagManagerIdCookie') private gtmIdCookie: string,) {
  }

  ngOnInit(): void {
    //this.cookiesAccepted = this.cookieService.getCookie('consent') === 'true';
    this.getCookiesPreferences();
    const analyticsCookie = this.cookiesList.find(cookie => cookie.name === 'cookie.analtiques');

    if (analyticsCookie && analyticsCookie.accepted) {
      this.loadGoogleTagManager();  // Charger Google Analytics si accepté
    } else {
      this.disableGoogleAnalytics(); // Désactiver Google Analytics si refusé
    }

    this.cookieService.showOptions$.subscribe(show => {
      this.getCookiesPreferences();
      this.showOptions = show;
    });
  }

  getCookiesPreferences(): void {
    const storedPreferences = localStorage.getItem('cookiePreferences');
    if (storedPreferences) {
      const storedCookiesList: CookieOption[] = JSON.parse(storedPreferences);
      this.cookiesList = this.cookiesList.map(defaultCookie => {
        const storedCookie = storedCookiesList.find(cookie => cookie.name === defaultCookie.name);
        return storedCookie ? { ...defaultCookie, accepted: storedCookie.accepted } : defaultCookie;
      });
    }
    this.cookiesAccepted = storedPreferences !== null;
  }

  giveConsent(): void {
    this.cookieService.setCookieWithName('consent', 'true', 365);
    this.cookiesAccepted = true;
  }

  savePreferences(): void {
    localStorage.setItem('cookiePreferences', JSON.stringify(this.cookiesList));
    this.cookiesAccepted = true;
    this.showOptions = false;

    const analyticsCookie = this.cookiesList.find(cookie => cookie.name === 'cookie.analtiques');

    if (analyticsCookie && analyticsCookie.accepted) {
      this.loadGoogleTagManager();  // Charger Google Analytics si accepté
    } else {
      this.disableGoogleAnalytics(); // Désactiver Google Analytics si refusé
    }


    this.giveConsent();
  }
  public disableGoogleAnalytics(): void {
    // Supprimer les cookies liés à Google Analytics
    document.cookie = '_ga=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = '_gid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = this.gtmIdCookie+'=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

    // Décharger Google Tag Manager en supprimant le script et en vidant le dataLayer
    const gtmScript = document.querySelector(`script[src="https://www.googletagmanager.com/gtag/js?id=${this.gtmId}"]`);
    if (gtmScript) {
      gtmScript.remove();
    }

    // Réinitialiser le dataLayer pour arrêter le suivi
    // @ts-ignore
    if (window.dataLayer) {
      // @ts-ignore
      window.dataLayer = [];
    }
  }
  acceptAll(): void {
    this.cookiesList.forEach(cookie => {
      if (!cookie.readOnly) {
        cookie.accepted = true;
      }
    });
    this.savePreferences();
  }

  declineAll(): void {
    this.cookiesList.forEach(cookie => {
      if (!cookie.readOnly) {
        cookie.accepted = false;
      }
    });
    this.savePreferences();
  }

  openOptions(): void {
    this.showOptions = true;
  }

  closeOptions(): void {
    this.showOptions = false;
  }

  public loadGoogleTagManager(): void {
    if (!this.isGtmLoaded()) {
      const gtmScript = document.createElement('script');
      gtmScript.src = `https://www.googletagmanager.com/gtag/js?id=${this.gtmId}`;
      gtmScript.async = true;
      document.head.appendChild(gtmScript);

      // Initialiser dataLayer si elle n'existe pas déjà
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];

      // Fonction gtag
      function gtag() {
        // @ts-ignore
        window.dataLayer.push(arguments);
      }
      // @ts-ignore
      gtag('js', new Date());
      // @ts-ignore
      gtag('config', this.gtmId);
    }
  }

  private isGtmLoaded(): boolean {
    return !!document.querySelector(`script[src="https://www.googletagmanager.com/gtag/js?id=${this.gtmId}"]`);
  }
}
