<div class="top">
  <img class="back-ground" src="assets/images/home.webp">
  <div class="text  pre-line">
    <h1>
      {{ "accueil.title" |translate }}
    </h1>
    <div>
      <img class="kickstarter-logo"
           src="https://i.kickstarter.com/tq0sfld-kickstarter-logo-green.png?origin=static&sig=f4aHZ5ZiQhwZCgzBFzJqR3WIwCUT6jVw6mALfOHvmu0%3D">
      <img class="kickstarter-logo"
           [src]="'accueil.imgComingSoon' | translate">
    </div>
  </div>

</div>
<div fxLayout="column" fxLayoutAlign="center center" class="presentation">
  <div class="content pre-line">
    <h1>{{ "accueil.t1" | translate }}</h1>
    <p>{{ "accueil.m1" | translate }}
    </p>
    <h1>{{ "accueil.t2"| translate }}</h1>
    <p>{{ "accueil.m2"| translate }} </p>
  </div>
</div>
