import {Component, Input, OnInit} from '@angular/core';
import {gameGenres} from "../../../assets/list/game-genres";
import {FilterService} from "../../services/filter.service";
import {FiltresInterne} from "../../../model/FiltresInterne";

@Component({
  selector: 'app-filtres',
  templateUrl: './filtres.component.html',
  styleUrls: ['./filtres.component.scss']
})
export class FiltresComponent implements OnInit {
  public expanded = false;
  public filterNew = false;
  public filterLeavingSoon = false;
  public filterSolo = false;
  public filterMultiplayer = false;
  public gameGenres = gameGenres
    .map(genre => ({
      name: genre.name,
      checked: false,
      isPopular: genre.popular
    }));


  constructor(private filterService: FilterService) {
  }

  ngOnInit(): void {
    if (this.filterService.restoreFilters()) {
      this.filterNew = this.filterService.restoreFilters().new;
      this.filterLeavingSoon = this.filterService.restoreFilters().leavingSoon;
      this.filterSolo = this.filterService.restoreFilters().solo;
      this.filterMultiplayer = this.filterService.restoreFilters().multiplayer;
      const restoredGenres = this.filterService.restoreFilters().genres;
      if (restoredGenres && restoredGenres.length) {
        this.gameGenres.forEach(genre => {
          if (restoredGenres.includes(genre?.name!)) {
            genre.checked = true;
          }
        });
      }
    }
    this.orderGenres();
  }

  resetFilters() {
    this.filterNew = false;
    this.filterLeavingSoon = false;
    this.filterSolo = false;
    this.filterMultiplayer = false;
    this.gameGenres.forEach(genre => genre.checked = false);
    this.updateFilters();

  }

  orderGenres() {
    let allGenres = [...this.gameGenres];

    // Trier les genres : cochés en premier, puis populaires, puis le reste
    allGenres = allGenres.sort((a, b) => {
      if (a.name! < b.name!) {
        return -1; // Les cochés avant les non cochés
      } if (a.name! > b.name!) {
        return 1; // Les cochés avant les non cochés
      }
      return 0;
    });    // Trier les genres : cochés en premier, puis populaires, puis le reste
    allGenres = allGenres.sort((a, b) => {
      if (a.checked && !b.checked) {
        return -1; // Les cochés avant les non cochés
      }
      if (!a.checked && b.checked) {
        return 1; // Les non cochés après les cochés
      }
      if (a.isPopular && !b.isPopular) {
        return -1; // Les populaires avant les non populaires
      }
      if (!a.isPopular && b.isPopular) {
        return 1; // Les non populaires après les populaires
      }
      return 0;
    });
    this.gameGenres = allGenres;
  }

  public updateFilters() {
    this.orderGenres();
    let genres = this.gameGenres.filter(g => g.checked && g?.name).map(g => g.name!);
    const filters: FiltresInterne = {
      new: this.filterNew,
      leavingSoon: this.filterLeavingSoon,
      solo: this.filterSolo,
      multiplayer: this.filterMultiplayer,
      genres: genres
    };
    this.filterService.changeFilters(filters);
  }

  toggleExpansion() {
    this.expanded = !this.expanded;
  }

}
