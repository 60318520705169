import {Injectable} from '@angular/core';
import {SnackBarComponent} from "../snack-bar/snack-bar.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ActionsEnum} from "../snack-bar/enum-actions";
import {PannelsClassEnum} from "../snack-bar/pannels-class-enum";
import {IndiepassCodesEnum} from "../../model/enums/indiepass-codes-enum";

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private _snackBar: MatSnackBar) {
  }

  openSnackBar(message: IndiepassCodesEnum, action: ActionsEnum, pannelClass: PannelsClassEnum) {
    this._snackBar.openFromComponent(SnackBarComponent, {
      politeness: "assertive",
      duration: 5 * 1000,
      data: {
        message: message.toString(),
        action: action.toString()
      },
      panelClass: pannelClass.toString()
    });
  }
  openSnackBarWithSeconds(message: IndiepassCodesEnum, action: ActionsEnum, pannelClass: PannelsClassEnum, seconds : number) {
    this._snackBar.openFromComponent(SnackBarComponent, {
      politeness: "assertive",
      duration: seconds * 1000,
      data: {
        message: message.toString(),
        action: action.toString()
      },
      panelClass: pannelClass.toString()
    });
  }

  openSnackBarErrorFallBack(messageFallback: IndiepassCodesEnum, error: any) {
    if (!error?.message) {
      this._snackBar.openFromComponent(SnackBarComponent, {
        politeness: "assertive",
        duration: 5 * 1000,
        data: {
          message: messageFallback.toString(),
          action: ActionsEnum.Ok.toString()
        },
        panelClass: PannelsClassEnum.error.toString()
      });
    }
  }
}
