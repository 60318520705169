<div class="white-theme">
  <div fxLayout="column" fxLayoutAlign="space-around center">
    <h1>{{ "gamepage.nouvellePageDejeu" | translate }}</h1>
    <mat-form-field class="example-form-field">
      <mat-label>{{ "gamepage.libelleDeLaPageDeJeu" | translate }}</mat-label>
      <input matInput type="text" [(ngModel)]="titre">
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center">
    <button [disabled]=" !titre || titre.length<3" (click)="creer()" mat-raised-button class="editer" color="primary">
      {{ "general.valider" | translate }}
    </button>
  </div>
</div>
