<div class="game">
  <a  class="no-style-a" [routerLink]="'/game/' + game.id" routerLinkActive>
    <img  [class.display-none]="!imageLoaded"
         [src]="environment.urlimgcdn+game?.id+'/'+game?.cover+'?class=screenshot'"
         (load)="imageLoaded = true">
    <div *ngIf="!imageLoaded" [ngClass]="{'placeholder': true, 'animated-placeholder': !imageLoaded}"></div>

    <div fxLayoutAlign="space-between center">
      <div>{{ game?.title }}</div>
    </div>
  </a>
</div>



