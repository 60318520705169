import {Component, OnInit} from '@angular/core';
import {OrganizationService} from "../../../../swagger/api/organization.service";
import {Organization} from "../../../../swagger/model/organization";
import {SnackBarService} from "../../../../services/snack-bar.service";
import {PannelsClassEnum} from "../../../../snack-bar/pannels-class-enum";
import {ActionsEnum} from "../../../../snack-bar/enum-actions";
import {IndiepassCodesEnum} from "../../../../../model/enums/indiepass-codes-enum";
import {ActivatedRoute} from "@angular/router";
import {RouteParamService} from "../../../../services/route-param.service";

@Component({
  selector: 'app-org-general',
  templateUrl: './org-general.component.html',
  styleUrls: ['./org-general.component.scss']
})
export class OrgGeneralComponent implements OnInit {
  public organization: Organization;
  public readonly PannelsClassEnum = PannelsClassEnum;
  public readonly ActionsEnum = ActionsEnum;
  public readonly IndiepassCodesEnum = IndiepassCodesEnum;
  private idOrganization: number;

  constructor(private organizationService: OrganizationService,
              public snackbarService: SnackBarService, private routeParamService: RouteParamService) {
  }

  ngOnInit(): void {
    this.organizationService.getOrganization(this.routeParamService.getRouteParameterNumber('idOrganization')!)
      .subscribe(organization => {
        this.organization = organization;
      });
  }

}
