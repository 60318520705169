import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {catchError, Observable, tap, throwError} from 'rxjs';
import {Router} from "@angular/router";
import {SnackBarComponent} from "../snack-bar/snack-bar.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CookieService} from "../cookie.service";

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {

  constructor(private _snackBar: MatSnackBar, private router: Router, private cookieService: CookieService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // Erreur côté client
          errorMessage = `Erreur côté client : ${error.error.message}`;
        } else {
          // Erreur côté serveur
          errorMessage = `Code d'état : ${error.status}\nMessage : ${error.message}`;
        }
        console.error('Error Interceptor:', {
          message: errorMessage,
          error: error,
          request: request,
        });
        if (error.status == 429) {
          this._snackBar.openFromComponent(SnackBarComponent, {
            duration: 5 * 1000,
            data: {
              message: "TOO_MANY_REQUESTS",
              action: "Ok",
            },
            panelClass: ['error-snackbar']
          });
        }
        console.log("error", error);

        if (error?.error?.message) {
          this._snackBar.openFromComponent(SnackBarComponent, {
            duration: 5 * 1000,
            data: {
              message: error?.error.message,
              action: "Ok",
            },
            panelClass: ['error-snackbar']
          });
        } else if (error.status == 500) {

          this._snackBar.openFromComponent(SnackBarComponent, {
            duration: 5 * 1000,
            data: {
              message: "GENERIC_ERROR",
              action: "Ok",
            },
            panelClass: ['error-snackbar']
          });
        }
        if (error.status == 303) {
          if (error.headers.get("URIINTERNE")) {
            //  this.router.navigate([error.headers.get("URIINTERNE")]);
          } else {
            if (error.headers.get("URI")) {
              //   window.location.href = error.headers.get("URI")!
            }
          }

        }
        if (error.status == 401 || error.status == 403) {
          console.log("devrait recharger");
          this.cookieService.deleteCookie('is_auth');
          if (!this.cookieService.getCookie('can_refresh')) {
            this.cookieService.deleteCookie('user_infos');

          }
          this.router.navigate(['/home']).then(() => window?.location.reload());
        }
        console.log("error", error);
        console.log("request", request);
        return throwError(errorMessage);
      }),
      tap({
          next: (event) => {
            console.log("event", event);
            if (event instanceof HttpResponse) {
              if (event.status == 303) {
              }
              if (event.status == 401) {
                this.router.navigate(['/login']);
              }

            }
            return event;
          },
          error: (error: HttpErrorResponse) => {


          }

        },
      )
    );
  }
}
