import {Component, OnInit} from '@angular/core';
import {GameRights} from "../../../swagger/model/gameRights";
import {OrganizationContextService} from "../../../services/organization-context.service";
import {Member} from "../../../swagger/model/member";
import {GameService} from "../../../swagger/api/game.service";
import {RouteParamService} from "../../../services/route-param.service";
import {MatDialog} from "@angular/material/dialog";
import {AddMemberPopupComponent} from "./add-member-popup/add-member-popup.component";
import {MatTableDataSource} from "@angular/material/table";
import {SnackBarService} from "../../../services/snack-bar.service";
import {IndiepassCodesEnum} from "../../../../model/enums/indiepass-codes-enum";
import {ActionsEnum} from "../../../snack-bar/enum-actions";
import {PannelsClassEnum} from "../../../snack-bar/pannels-class-enum";
import {GeneralService} from "../../../general.service";
import {IndiepassModalsService} from "../../../services/indiepass-modals.service";

@Component({
  selector: 'app-rights-management',
  templateUrl: './rights-management.component.html',
  styleUrls: ['./rights-management.component.scss']
})
export class RightsManagementComponent implements OnInit {
  isDragging = false;
  membres: MatTableDataSource<Member>;
  membresInit: Member[] = [];
  displayedColumns: string[] = [""]; // Votre tableau de droits
  droits = [""];
  assignations = new Map(); // Pour stocker les états des cases à cocher

  constructor(private routeParamService: RouteParamService, public organizationContextService: OrganizationContextService,
              public generalService: GeneralService,    public indiePassModalService: IndiepassModalsService,
              private gameService: GameService, private dialog: MatDialog, private snackBarService: SnackBarService) {
  }

  onMouseDown() {
    this.isDragging = true;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.membres.filter = filterValue.trim().toLowerCase();

    if (this.membres.paginator) {
      this.membres.paginator.firstPage();
    }
  }

  onMouseUp() {
    this.isDragging = false;
  }


  onCheckboxEnter(membre: Member, droit: string) {
    if (this.isDragging && this.isEditThis(membre)) {
      // @ts-ignore
      membre.gameRights[droit] = !membre.gameRights[droit];
    }
  }

  isEditThis(membre: Member) {
    return !((this.generalService.getMemberRole() === 'regular') || (
      this.generalService.getMemberRole() === 'admin' && membre.role !== 'regular') || membre.role === 'owner');

  }

  addMember() {
    const dialogRef = this.dialog.open(AddMemberPopupComponent, {
      width: '550px',
      data: {
        idOrganization: this.routeParamService.getRouteParameterNumber('idOrganization')!,
        idGame: this.routeParamService.getRouteParameterNumber('idGame')!,
        members: this.membres.data,
        son: this
      }

    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {

      }
    });
  }

  save(): void {
    this.gameService.updateMemberGameRightsByEmail(this.getMembersWithDifferentRights(),
      this.routeParamService.getRouteParameterNumber('idGame')!).subscribe(() => {
      this.gameService.getTeamByGameId(this.routeParamService.getRouteParameterNumber('idGame')!).subscribe((value) => {
        this.snackBarService.openSnackBar(IndiepassCodesEnum.UPDATE_OK, ActionsEnum.Ok, PannelsClassEnum.info);
        this.membresInit = JSON.parse(JSON.stringify(value));

      }, error => {
        this.snackBarService.openSnackBar(IndiepassCodesEnum.UPDATE_KO, ActionsEnum.Ok, PannelsClassEnum.error);
      });
    });
  }

  delete(member: Member): void {
    this.indiePassModalService.confirmationDeleteMember().subscribe(value => {
        if (value) {
          this.gameService.deleteMemberGameRights(this.routeParamService.getRouteParameterNumber('idGame')!,
            member?.id!).subscribe(() => {
            this.snackBarService.openSnackBar(IndiepassCodesEnum.UPDATE_OK, ActionsEnum.Ok, PannelsClassEnum.info);
            this.fetchMembers();
          }, error => {
            this.snackBarService.openSnackBar(IndiepassCodesEnum.UPDATE_KO, ActionsEnum.Ok, PannelsClassEnum.error);
          });
        }
      }
    )
  }

  ngOnInit(): void {
    this.membres = new MatTableDataSource<Member>();
    this.membres.filterPredicate = (data: Member, filter: string) => {
      return data.email ? data.email.toLowerCase().includes(filter) : false;
    };
    this.fetchMembers();
    const tempGameRights: GameRights = {
      editGamePage: false,
      deleteGamePage: false,
      publishGamePage: false,
      readGameStats: false,
      editAchievements: false,
      uploadBuild: false,
      deleteBuild: false,
      publishBuild: false,
      editGameInfos: false
    };
    this.droits = Object.keys(tempGameRights);
    this.displayedColumns = ["members", ...this.droits, "all"];

  }

  //if all rights are true, set them to false, else set them to true
  allRights(membre: Member) {
    // Assurez-vous que membre.gameRights est initialisé
    if (!membre.gameRights) {
      membre.gameRights = {};
    }

    // Vérifiez si au moins un droit est false
    const anyFalse = Object.values(membre.gameRights).some(value => value === false);

    // Si au moins un droit est false, réglez tous sur true, sinon réglez tous sur false
    for (const droit in membre.gameRights) {
      if (membre.gameRights.hasOwnProperty(droit)) {
        // @ts-ignore
        membre.gameRights[droit] = anyFalse;
      }
    }
  }

  //return true if membresInit is not equal to membres.data
  canSave(): boolean {
    return JSON.stringify(this.membresInit) !== JSON.stringify(this.membres.data);
  }

//return all the members in this.members.data that have at least one right different from this.membersInit
  getMembersWithDifferentRights(): Member[] {
    return this.membres.data.filter(member => {
      return JSON.stringify(member.gameRights) !== JSON.stringify(this.membresInit.find(m => m.email === member.email)!.gameRights);
    });
  }

  fetchMembers() {
    this.gameService.getTeamByGameId(this.routeParamService.getRouteParameterNumber('idGame')!).subscribe(membres => {
      this.membresInit = JSON.parse(JSON.stringify(membres));
      this.membres.data = membres;
    });
  }
}
