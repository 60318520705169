<div class="main-wrapper black-theme">
  <div class="login-zone mat-elevation-z8">
    <h1>{{ 'connexion.bonRetour' | translate }}</h1>
    <form class="example-form" [formGroup]="checkoutForm" (ngSubmit)="onSubmit()">
      <mat-form-field class="full-width-field input-field" appearance="fill">
        <mat-label>{{ 'connexion.entrezVotreMail' | translate }}</mat-label>
        <input [readonly]="emaillForce" id="email" formControlName="email" matInput required>
      </mat-form-field>

      <mat-form-field class="full-width-field input-field" appearance="fill">
        <mat-label>{{ 'connexion.entrezVotreMdp' | translate }}</mat-label>
        <input id="password" formControlName="password" matInput [type]="hide1 ? 'password' : 'text'">
        <button type="button" mat-icon-button matSuffix (click)="hide1 = !hide1" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
          <mat-icon>{{ hide1 ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
      </mat-form-field>

      <div class="btn-login">
        <button [disabled]="!checkoutForm.valid" [appDisableButton]="loading" type="submit" mat-stroked-button class="btn-block full-width-field">{{ 'connexion.connexion' | translate }}</button>
      </div>
      <div class="pwd-forgotten " *ngIf="checkoutForm.valid && !sendingEmail" (click)="sendMailResetPassword()">{{ 'connexion.motDePasseOublie?' | translate }}</div>
      <mat-spinner *ngIf="sendingEmail" [diameter]="20"></mat-spinner>
    </form>
    <div class="no-account">
      {{ 'connexion.pasDeCompte?' | translate }}
      <a class="pwd-forgotten" routerLink="/register">{{ 'connexion.signUpHere' | translate }}</a>
    </div>
  </div>
</div>
