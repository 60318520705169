import {Component, Input, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {AuthService} from "../../swagger/api/auth.service";
import {COUNTRIES, Country} from "../../../model/CountryCode";
import {Utilisateur} from "../../swagger/model/utilisateur";
import {map, Observable, startWith} from "rxjs";
import {SnackBarService} from "../../services/snack-bar.service";


@Component({
  selector: 'app-enregistrement',
  templateUrl: './enregistrement.component.html',
  styleUrls: ['./enregistrement.component.scss']
})
export class EnregistrementComponent implements OnInit {
  hide = true;
  hideConfirm = true;
  checkoutForm: FormGroup;
  samePassword: boolean = false;
  countryCodeSelected: Country;
  USERNAME_PATTERN: string = "^[A-zÀ-ú0-9-]*$";
  public reussi: boolean = false;
  public loading = false;
  @Input() emaillForce?: string = undefined;
  countries: Country[] = COUNTRIES;

  constructor(private snackBarService: SnackBarService, private authService: AuthService, private formBuilder: FormBuilder) {
  }

  filteredOptions: Observable<Country[]> | undefined;

  ngOnInit(): void {

    this.checkoutForm = this.formBuilder.group({
      email: new FormControl(null, [Validators.email, Validators.maxLength(250)]),
      nickName: new FormControl(null, [Validators.minLength(3), Validators.maxLength(25), usernameValidator()]),
      password: new FormControl(null, [Validators.minLength(7), Validators.maxLength(50)]),
      passwordbis: new FormControl(null),
      checkBox: new FormControl(null),
      checkBoxBeta: new FormControl(null),
      checkBoxNewsLetter: new FormControl(null),
      countryCode: new FormControl(null, [Validators.required])
    }, {validators: this.checkPasswords});
    this.filteredOptions = this.checkoutForm.get("countryCode")?.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    this.checkoutForm.get("email")?.setValue(this.emaillForce);
  }

  private _filter(value: string): Country[] {
    const filterValue = value.toLowerCase();
    const result = COUNTRIES.filter(option => option.nameEn.toLowerCase().includes(filterValue));
    return result;
  }

  getErrorMessage(check: string) {
    if (check === "email" && this.checkoutForm.controls["email"].hasError('required')) {
      return 'general.vousDevezEntrerUneValeur';
    } else if (check === "email" && this.checkoutForm.controls["email"].hasError('email')) {
      return 'register.emailInvalid';
    } else if (check === "nickName" && this.checkoutForm.controls["nickName"].hasError('required')) {
      return 'general.vousDevezEntrerUneValeur';
    } else if (check === "nickName" && this.checkoutForm.controls["nickName"].hasError('minlength')) {
      return 'register.nomutilisateurTropCourt';
    } else if (check === "nickName" && this.checkoutForm.controls["nickName"].hasError('invalidUsername')) {
      return 'register.nomutilisateurFormatInvalide';
    } else if (check === "nickName" && this.checkoutForm.controls["nickName"].hasError('usernameTaken')) {
      return 'register.nomutilisateurDejaPris';
    } else if (check === "nickName" && this.checkoutForm.controls["nickName"].hasError('maxlength')) {
      return 'register.nomutilisateurTropLong';
    } else if (check === "passwordbis" && this.checkoutForm.controls['passwordbis']?.hasError('notSame')) {
      return 'register.mdpNeMatchPas';
    } else if (check === "password" && this.checkoutForm.controls['password']?.hasError('minlength')) {
      return 'register.mdpTropcourt';
    } else if (check === "password" && this.checkoutForm.controls['password']?.hasError('required')) {
      return 'general.vousDevezEntrerUneValeur';
    } else if (check === "password" && this.checkoutForm.controls['password']?.hasError('maxlength')) {
      return 'register.mdpTropLong';
    }
    return '';
  }

  onSubmit(): void {
    this.loading = true;
    const formValue = this.checkoutForm.value;
    const user: Utilisateur = {
      ...formValue,
      countryCode: this.countryCodeSelected.code,
      newsletter: formValue.checkBoxNewsLetter,
    };
    this.authService.registerUser(user).subscribe(response => {
      this.reussi = true;
    }).add(() => {
      this.loading = false;
    });
  }

  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    const pass = this.checkoutForm?.get('password')?.value;
    const confirmPass = group.get('passwordbis')?.value;
    if (pass && confirmPass && pass === confirmPass) {
      this.samePassword = true;
      group.get('passwordbis')?.setErrors(null);
      return null;
    } else {
      this.samePassword = false;
      group.get('passwordbis')?.setErrors({notSame: true});
      return {notSame: true};
    }
  }

  checkCountry() {
    console.log(this.checkoutForm.get("countryCode")?.value)
    const result = COUNTRIES.filter(option => option.nameEn.toLowerCase().match(this.checkoutForm.get("countryCode")?.value.toLowerCase()));
    if (!result.length || result.length > 1) {
      this.checkoutForm.controls['countryCode'].setErrors({'noCountry': true});
    } else {
      this.countryCodeSelected = result[0];
    }
  }

  checkUsernameAvailability() {
    const username = this.checkoutForm.get('nickName')!.value;
    if (username && username.length >= 4) {
      this.authService.checkUsername(username).subscribe(
        response => {
        },
        error => {
          this.checkoutForm.controls['nickName'].setErrors({'usernameTaken': true});
        }
      );
    }
  }
}

export function usernameValidator(): ValidatorFn {
  const USERNAME_PATTERN = /^[A-zÀ-ú0-9-]*$/;
  return (control: AbstractControl): ValidationErrors | null => {
    const valid = USERNAME_PATTERN.test(control.value);
    return valid ? null : {invalidUsername: true};
  }
}
