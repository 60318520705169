import {Component, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {MatTableDataSource} from "@angular/material/table";
import {UserInfos} from "../../swagger/model/userInfos";

@Component({
  selector: 'app-main-tool',
  templateUrl: './main-tool.component.html',
  styleUrls: ['./main-tool.component.scss']
})
export class MainToolComponent implements OnInit {

  public waitingPlayerCreation: boolean = false;
  public waitingEditeurCreation: boolean = false;
  public waitingForceSub: boolean = false;
  public waitingAddOneHourToAll: boolean = false;
  public waitingForcerPaiementAll: boolean = false;

  dataSourceEditeur = new MatTableDataSource<UserInfos>();
  dataSourcePlayers = new MatTableDataSource<UserInfos>();
  userInfosEditeurs: UserInfos[] = [];
  userInfosPlayer: UserInfos[] = [];
  displayedColumns: string[] = ['pseudo', 'email', 'pays', 'actions'];
  public baseUrl = environment.baseUrlAPI;
  public filterGamers: string = "";
  public filterEditeurs: string = "";

  constructor(private http: HttpClient) {
  }

  ngOnInit(): void {

    this.fetchGamers();
    this.fetchEditeurs();
  }

  fetchGamers() {
    this.http.get(this.baseUrl + "/devtool/gamers").subscribe(data => {
      this.userInfosPlayer = data as UserInfos[];
      this.dataSourcePlayers.data = this.userInfosPlayer;
    });
  }

  fetchEditeurs() {
    this.http.get(this.baseUrl + "/devtool/editeurs").subscribe(data => {
      this.userInfosEditeurs = data as UserInfos[];
      this.dataSourceEditeur.data = this.userInfosEditeurs;
    });
  }

  dashboard(user: UserInfos): void {
    this.http.get(this.baseUrl + "/devtool/stripe-dashboard?id=" + user.id, {responseType: 'text'}).subscribe(data => {
      this.openNewTab(data)
    })
  }

  goFinish(user: UserInfos): void {
    this.http.get(this.baseUrl + "/devtool/stripe-end?id=" + user.id, {responseType: 'text'}).subscribe(data => {
      this.openNewTab(data)
    })
  }

  creerEditeurs(): void {
    this.waitingEditeurCreation = true;
    this.http.post(this.baseUrl + "/devtool/editeurs", null).subscribe(data => {
      this.waitingEditeurCreation = false;
      this.fetchEditeurs();
    })
  }

  forcerSubscribes(): void {
    this.waitingForceSub = true;
    this.http.post(this.baseUrl + "/devtool/forceSubscribes", null).subscribe(data => {
      this.waitingForceSub = false;
      this.fetchGamers();
    })
  }
  addOneHourToAll(): void {
    this.waitingAddOneHourToAll = true;
    this.http.post(this.baseUrl + "/devtool/addOneHourToAll", null).subscribe(data => {
      this.waitingAddOneHourToAll = false;
    })
  }
  forcerPaiementAll(): void {
    this.waitingForcerPaiementAll = true;
    this.http.post(this.baseUrl + "/devtool/forcerPaiementAll", null).subscribe(data => {
      this.waitingForcerPaiementAll = false;
    })
  }

  creerJoueurs(): void {
    this.waitingPlayerCreation = true;
    this.http.post(this.baseUrl + "/devtool/gamers", null).subscribe(data => {
      this.waitingPlayerCreation = false;
      this.fetchGamers();
    })
  }

  openNewTab(url: string): void {
    window.open(url, '_blank');
  }

  applyFilterEditeur(filterValue: string) {
    this.dataSourceEditeur.filter = filterValue.trim().toLowerCase();
  }

  applyFilterGamers(filterValue: string) {
    this.dataSourcePlayers.filter = filterValue.trim().toLowerCase();
  }
}
