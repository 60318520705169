import {Language} from "../../app/swagger/model/language";
export interface LanguageCode extends Language {
  nameEn: string
}

export const languages: LanguageCode[] = [

  {code: 'EN', nameEn: 'English'},
  {code: 'ES', nameEn: 'Spanish'},
  {code: 'ZH', nameEn: 'Chinese'},
  {code: 'HI', nameEn: 'Hindi'},
  {code: 'AR', nameEn: 'Arabic'},
  {code: 'PT', nameEn: 'Portuguese'},
  {code: 'BN', nameEn: 'Bengali'},
  {code: 'RU', nameEn: 'Russian'},
  {code: 'JA', nameEn: 'Japanese'},
  {code: 'DE', nameEn: 'German'},
  {code: 'KO', nameEn: 'Korean'},
  {code: 'FR', nameEn: 'French'},
  {code: 'IT', nameEn: 'Italian'},
  {code: 'TR', nameEn: 'Turkish'},
  {code: 'VI', nameEn: 'Vietnamese'},
  {code: 'PL', nameEn: 'Polish'},
  {code: 'UK', nameEn: 'Ukrainian'},
  {code: 'RO', nameEn: 'Romanian'},
  {code: 'NL', nameEn: 'Dutch'},
  {code: 'EL', nameEn: 'Greek'},
  {code: 'HE', nameEn: 'Hebrew'},
  {code: 'SV', nameEn: 'Swedish'},
  {code: 'NO', nameEn: 'Norwegian'},
  {code: 'FI', nameEn: 'Finnish'},
  {code: 'DA', nameEn: 'Danish'},
  {code: 'HU', nameEn: 'Hungarian'},
  {code: 'CS', nameEn: 'Czech'},
  {code: 'SK', nameEn: 'Slovak'},
  {code: 'BG', nameEn: 'Bulgarian'},
  {code: 'HR', nameEn: 'Croatian'},
  {code: 'SR', nameEn: 'Serbian'},
  {code: 'LT', nameEn: 'Lithuanian'},
  {code: 'LV', nameEn: 'Latvian'},
  {code: 'ET', nameEn: 'Estonian'},
  {code: 'TH', nameEn: 'Thai'},
  {code: 'MS', nameEn: 'Malay'},
  {code: 'ID', nameEn: 'Indonesian'},
  {code: 'TL', nameEn: 'Tagalog'},
  {code: 'FA', nameEn: 'Persian'},
  {code: 'UR', nameEn: 'Urdu'},
  {code: 'MY', nameEn: 'Burmese'},
  {code: 'KM', nameEn: 'Khmer'},
  {code: 'AM', nameEn: 'Amharic'},
  {code: 'SW', nameEn: 'Swahili'},
  {code: 'YO', nameEn: 'Yoruba'},
  {code: 'IG', nameEn: 'Igbo'},
  {code: 'ZU', nameEn: 'Zulu'},
  {code: 'MT', nameEn: 'Maltese'},
  {code: 'GA', nameEn: 'Irish'},
  {code: 'IS', nameEn: 'Icelandic'},
  {code: 'MK', nameEn: 'Macedonian'},
  {code: 'SL', nameEn: 'Slovenian'},
  {code: 'AF', nameEn: 'Afrikaans'},
  {code: 'NE', nameEn: 'Nepali'},
  {code: 'MN', nameEn: 'Mongolian'},
  {code: 'BS', nameEn: 'Bosnian'},
  {code: 'KA', nameEn: 'Georgian'}
];
