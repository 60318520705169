import {Injectable} from '@angular/core';
import {Member} from "../swagger/model/member";
import {Game} from "../swagger/model/game";
import {RouteParamService} from "./route-param.service";

@Injectable({
  providedIn: 'root'
})
export class OrganizationContextService {

  constructor(private routeParamService: RouteParamService) {
  }

  private member: Member | undefined
  public games: Game[] | undefined

  setMemberContext(member: Member) {
    this.member = member;
  }

  delMemberContext() {
    this.member = undefined;
  }

  getMemberContext(): Member | undefined {
    return this.member;
  }

  //return the number of game note valide
  getNbGameNoteValide(): number {

    let nbGameNoteValide = 0;
    if (this.games) {
      for (let game of this.games) {
        console.log(game)
        if (!game.lienAccepte && game.developer?.id == this.routeParamService.getRouteParameterNumber('idOrganization')) {
          nbGameNoteValide++;
        }
      }
    }
    return nbGameNoteValide;
  }
}
