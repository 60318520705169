import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AchievementService } from './api/achievement.service';
import { AuthService } from './api/auth.service';
import { DevtoolService } from './api/devtool.service';
import { FeedbackService } from './api/feedback.service';
import { GameService } from './api/game.service';
import { GamePageService } from './api/gamePage.service';
import { NotificationService } from './api/notification.service';
import { OrganizationService } from './api/organization.service';
import { StatisticService } from './api/statistic.service';
import { SubscribeService } from './api/subscribe.service';
import { UserService } from './api/user.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AchievementService,
    AuthService,
    DevtoolService,
    FeedbackService,
    GameService,
    GamePageService,
    NotificationService,
    OrganizationService,
    StatisticService,
    SubscribeService,
    UserService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
