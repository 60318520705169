import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-conditions-popup',
  templateUrl: './conditions-popup.component.html',
  styleUrls: ['./conditions-popup.component.scss']
})
export class ConditionsPopupComponent {
  public accepte: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ConditionsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
