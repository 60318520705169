/**
 * IndiePass API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GameRights } from './gameRights';

export interface Member { 
    id?: number;
    email?: string;
    nickName?: string;
    valide?: boolean;
    role?: Member.RoleEnum;
    gameRights?: GameRights;
}
export namespace Member {
    export type RoleEnum = 'owner' | 'admin' | 'regular';
    export const RoleEnum = {
        Owner: 'owner' as RoleEnum,
        Admin: 'admin' as RoleEnum,
        Regular: 'regular' as RoleEnum
    };
}