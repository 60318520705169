import {Component, HostListener, OnInit} from '@angular/core';
import {environment} from "../../environments/environment";
import {GameService} from "../swagger/api/game.service";
import {GameLight} from "../swagger/model/gameLight";
import {FilterService} from "../services/filter.service";
import {FiltresInterne} from "../../model/FiltresInterne";
import {GameSearchResult} from "../swagger/model/gameSearchResult";
import {UserService} from "../swagger/api/user.service";
import {GeneralService} from "../general.service";
import {ItemLibrary} from "../swagger/model/itemLibrary";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-list-game',
  templateUrl: './list-game.component.html',
  styleUrls: ['./list-game.component.scss']
})
export class ListGameComponent implements OnInit {
  public games: GameSearchResult[] = [];
  public filtreForce: string;
  public libraryItems: ItemLibrary[] = [];
  public isLargeScreen: boolean;
  public isInLibrary: boolean = false;
  public canShowFilters: boolean = false;
  public isInWeb: boolean = true;
  private filtersSubscription: Subscription;
  public isFetchingGames: boolean = false;

  constructor( private gameService: GameService, private router: Router, private generalService: GeneralService, private userService: UserService, private filterService: FilterService) {

    this.isInWeb = !this.generalService.getIsinclient();


  }

  ngOnInit(): void {

    if (this.router.url === '/library') {
      this.isInLibrary = true;
    }
    console.log(this.filtreForce)

    this.canShowFilters = true
    this.filtersSubscription = this.filterService.currentFilters.subscribe(filters => {
      console.log(filters)
      this.fetchGames(filters);
    });
    this.checkScreenWidth();

  }

  ngOnDestroy(): void {
    if (this.filtersSubscription) {
      this.filtersSubscription.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  private checkScreenWidth() {
    this.isLargeScreen = window.innerWidth >= 1440;
  }

  private fetchGames(filters: FiltresInterne) {
    this.games = [];
    if (this.isInLibrary && !this.libraryItems?.length) {
      this.userService.getUserLibrary(this.generalService.getUserId()).subscribe(items => {
        this.libraryItems = [...items];
      }).add(() => {
        this.isFetchingGames = false;
      })
    } else if (!this.isInLibrary) {
      this.isFetchingGames = true;

      this.gameService.getGames(undefined, undefined, filters.genres, filters.solo, filters.multiplayer, filters.new, filters.leavingSoon, undefined, undefined, 60).subscribe(games => {
        this.games = [...games];
      }).add(() => {
        this.isFetchingGames = false;
      });
    }
  }
  presentation: boolean = environment.presentation;

  readonly environment = environment;
}
