import {Component, OnInit, ViewChild} from '@angular/core';
import {Game} from "../../../swagger/model/game";
import {OrganizationService} from "../../../swagger/api/organization.service";
import {RouteParamService} from "../../../services/route-param.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {CreerJeuPopupComponent} from "../editeur/creer-jeu-popup/creer-jeu-popup.component";
import {GameService} from "../../../swagger/api/game.service";
import {MatTableDataSource} from "@angular/material/table";
import {Achievement} from "../../../swagger/model/achievement";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {OrganizationContextService} from "../../../services/organization-context.service";
import {IndiepassCodesEnum} from "../../../../model/enums/indiepass-codes-enum";
import {ActionsEnum} from "../../../snack-bar/enum-actions";
import {PannelsClassEnum} from "../../../snack-bar/pannels-class-enum";
import {UserService} from "../../../swagger/api/user.service";
import {GeneralService} from "../../../general.service";
import {SnackBarService} from "../../../services/snack-bar.service";

@Component({
  selector: 'app-games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.scss']
})
export class GamesComponent implements OnInit {
  public games: Game[] = [];
  dataSource: MatTableDataSource<Game>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  displayedColumns: string[] = ['title', 'publisher', 'developer', 'status', 'actions'];
  private name: string = "";
  public orgid: number = -1;
  public userLibraryGameIds: Set<number> = new Set();

  constructor(private organizationService: OrganizationService, private gameService: GameService,private userService: UserService,
              public organizationContextService : OrganizationContextService,  public generalService : GeneralService, public snackBarService : SnackBarService,
              private routeParamService: RouteParamService, private router: Router, public dialog: MatDialog, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.orgid = this.routeParamService.getRouteParameterNumber('idOrganization')!;
    this.userService.getUserLibrary(this.generalService.getUserId()).subscribe(libraryItems => {
      this.userLibraryGameIds = new Set(libraryItems.map(item => item.idGame!));
      this.getJeuxPublisher();
    }, error => {
      // Gérer les erreurs si nécessaire
    });
  }
  isGameInLibrary(gameId: number): boolean {
    return this.userLibraryGameIds.has(gameId);
  }
  creerJeu() {
    const dialogRef = this.dialog.open(CreerJeuPopupComponent, {
      width: '550px',
      data: {name: this.name},
    });
    dialogRef.afterClosed().subscribe(game => {
      this.gameService.createGame(game, game.tagDev, game.tagEditor).subscribe(result => {
        this.getJeuxPublisher();
      })
    });
  }

  goToGameManager(game: Game) {
    this.router.navigate(['./' + game?.id + '/manage/game'], {relativeTo: this.route});
  }

  addToLibrarie(game: Game) {
    this.userService.addGameToLibrary(this.generalService.getUserId(), game!.id!).subscribe(() => {
      this.userLibraryGameIds.add(game.id!);
    }, error => {
      this.snackBarService.openSnackBar(IndiepassCodesEnum.UPDATE_KO, ActionsEnum.Ok, PannelsClassEnum.error);
    })  }

  updateLinkState(game: Game, value: boolean) {
    this.organizationService.acceptLink(game?.id!, value).subscribe(value => {
      this.getJeuxPublisher();
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getJeuxPublisher() {
    this.games = [];
    this.organizationService.getGamesForPartner(this.routeParamService.getRouteParameterNumber('idOrganization')!).subscribe(jeux => {
      if (jeux) {
        for (let game of jeux) {
          this.games.push(game);
        }
      }
      this.games = this.games.sort((a, b) => {
        // Premièrement, trier par 'lienaccepte', false avant true
        if (a.lienAccepte === false && b.lienAccepte === true) {
          return -1;
        }
        if (a.lienAccepte === true && b.lienAccepte === false) {
          return 1;
        }

        // Si 'lienaccepte' est le même, trier alphabétiquement par 'title'
        const titleA = a.title!.toUpperCase(); // Supposons que 'title' ne soit jamais null
        const titleB = b.title!.toUpperCase();

        if (titleA < titleB) {
          return -1;
        }
        if (titleA > titleB) {
          return 1;
        }
        return 0;
      });
      this.games = [...this.games];
      this.dataSource.data = this.games;
    })
  }

}
