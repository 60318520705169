import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CookieService} from "../cookie.service";
import {GeneralService} from "../general.service";
import {MatDialog} from "@angular/material/dialog";
import {environment} from "../../environments/environment";
import {TranslateService} from "@ngx-translate/core";

import {Envs} from "../../model/enums/envs";
import {AuthService} from "../swagger/api/auth.service";
import {UserService} from "../swagger/api/user.service";
import {Utilisateur} from "../swagger/model/utilisateur";
import {SubscribeService} from "../swagger/api/subscribe.service";
import {SnackBarService} from "../services/snack-bar.service";
import {IndiepassCodesEnum} from "../../model/enums/indiepass-codes-enum";
import {ActionsEnum} from "../snack-bar/enum-actions";
import {PannelsClassEnum} from "../snack-bar/pannels-class-enum";
import {IndiepassModalsService} from "../services/indiepass-modals.service";
import {IndiePassModalMessage, IndiePassModalTitle} from "../services/indiepass-modals/indiepass-modals.component";

export interface DialogData {
  titre: string;
  name: string;
}

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.scss']
})
export class ProfilComponent implements OnInit {
  public user: Utilisateur
  selected = 'fr';
  public baseUrl = environment.baseUrlAPI;
  public environmentIsProduction = environment.production
  public environment = environment.environment
  public envs = Envs;
  public isLoadingAbonnement: boolean = false;
  public isLoadingResetPassword: boolean = false;

  //public user: User

  constructor(public dialog: MatDialog, private route: ActivatedRoute, private cookie: CookieService, private indiePassModalService: IndiepassModalsService,
              private router: Router, public generalService: GeneralService, public translate: TranslateService, private snackBarService: SnackBarService,
              private userService: UserService, private subscribeService: SubscribeService, private authService: AuthService) {
    try {
      this.userService.getUser(this.generalService.getUserInfos().id!).subscribe(data => {
        this.user = data as Utilisateur;
      })
    }
    catch (erreur)
    {
      this.router.navigate(["login"])
    }
  }

  ngOnInit(): void {
  }

  useLanguage(language: string): void {
    this.cookie.setCookieWithName("lang", language, 365);
    this.translate.use(language.toString());
  }

  logout(): void {
    this.authService.logout().subscribe(data => {
      this.cookie.delete_cookie("user_infos");
      this.cookie.delete_cookie("access_token");
      this.generalService.getUserInfos().id
      this.router.navigate(['/home']).then(() => window?.location.reload());
    })
  }

  askDeleteAccount(): void {
    this.indiePassModalService.confirmationCustom(IndiePassModalMessage.MD_MSG_CONFIRM_DELETE_ACCOUNT,IndiePassModalTitle.MD_TITLE_DELETE_ACCOUNT, false).subscribe(result => {
      if(result){
        this.userService.askDeleteAccount(this.generalService.getUserInfos().id!).subscribe(data => {
          this.logout();
        })
      }
    })
  }

  public subscribe() {
    this.isLoadingAbonnement = true;
    this.subscribeService.createCheckout("indiepass").subscribe(data => {
      if (data) {
        window.location.href = data
        this.isLoadingAbonnement = false;

      }
    }, error => {
      this.isLoadingAbonnement = false;

    });

  }

  public manage() {
    this.isLoadingAbonnement = true;

    this.subscribeService.goToCustomerPortal().subscribe(data => {
      if (data?.link) {
        window.location.href = data.link
        this.isLoadingAbonnement = false;

      }
    }, error => {
      this.isLoadingAbonnement = false;
    });
  }

  public updateNewsletter(value: any) {
    this.user.newsletter = value.checked
    this.userService.manageNewsletter(this.user).subscribe(data => {

    }, error => {
      console.log(error)
      if (error.status != 429) {
        this.snackBarService.openSnackBarErrorFallBack(IndiepassCodesEnum.UPDATE_KO, error)
      }
      this.user.newsletter = !value.checked

    })
  }

  public updateNewsletterDev(value: any) {
    this.user.newsletterDev = value.checked
    this.userService.manageNewsletter(this.user).subscribe(data => {

    }, error => {
      this.user.newsletterDev = !value.checked
      this.snackBarService.openSnackBarErrorFallBack(IndiepassCodesEnum.UPDATE_KO, error)

    })
  }

  public resetPasword() {
    if (this?.user?.email) {
      this.isLoadingResetPassword = true;
      this.authService.askResetPassword(this.user.email).subscribe(date => {
        this.isLoadingResetPassword = false;
        this.snackBarService.openSnackBar(IndiepassCodesEnum.MAIL_SEND_OK, ActionsEnum.Ok, PannelsClassEnum.info)
      }, error => {
        this.isLoadingResetPassword = false;
      })
    } else {
      this.snackBarService.openSnackBar(IndiepassCodesEnum.MAIL_SEND_KO, ActionsEnum.Ok, PannelsClassEnum.error)
    }
  }
  presentation: boolean = environment.presentation;

}
