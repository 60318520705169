/**
 * IndiePass API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { GamePage } from '../model/gamePage';
import { GamePageTranslation } from '../model/gamePageTranslation';
import { UploadObject } from '../model/uploadObject';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class GamePageService {

    protected basePath = 'https://api.indiepass.com/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create game page
     * 
     * @param idGame 
     * @param title 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createGamePage(idGame: number, title: string, observe?: 'body', reportProgress?: boolean): Observable<GamePage>;
    public createGamePage(idGame: number, title: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GamePage>>;
    public createGamePage(idGame: number, title: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GamePage>>;
    public createGamePage(idGame: number, title: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idGame === null || idGame === undefined) {
            throw new Error('Required parameter idGame was null or undefined when calling createGamePage.');
        }

        if (title === null || title === undefined) {
            throw new Error('Required parameter title was null or undefined when calling createGamePage.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idGame !== undefined && idGame !== null) {
            queryParameters = queryParameters.set('idGame', <any>idGame);
        }
        if (title !== undefined && title !== null) {
            queryParameters = queryParameters.set('title', <any>title);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GamePage>('post',`${this.basePath}/gamepages`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete game page by ID
     * 
     * @param idGamePage 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteGamePageById(idGamePage: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteGamePageById(idGamePage: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteGamePageById(idGamePage: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteGamePageById(idGamePage: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idGamePage === null || idGamePage === undefined) {
            throw new Error('Required parameter idGamePage was null or undefined when calling deleteGamePageById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/gamepages/${encodeURIComponent(String(idGamePage))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete game translation page by language code
     * 
     * @param idGamePage 
     * @param languageCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteGamePageTranslation(idGamePage: number, languageCode: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteGamePageTranslation(idGamePage: number, languageCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteGamePageTranslation(idGamePage: number, languageCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteGamePageTranslation(idGamePage: number, languageCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idGamePage === null || idGamePage === undefined) {
            throw new Error('Required parameter idGamePage was null or undefined when calling deleteGamePageTranslation.');
        }

        if (languageCode === null || languageCode === undefined) {
            throw new Error('Required parameter languageCode was null or undefined when calling deleteGamePageTranslation.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (languageCode !== undefined && languageCode !== null) {
            queryParameters = queryParameters.set('languageCode', <any>languageCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/gamepages/${encodeURIComponent(String(idGamePage))}/translations`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get game page by ID
     * 
     * @param idGamePage 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGamePageById(idGamePage: number, observe?: 'body', reportProgress?: boolean): Observable<GamePage>;
    public getGamePageById(idGamePage: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GamePage>>;
    public getGamePageById(idGamePage: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GamePage>>;
    public getGamePageById(idGamePage: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idGamePage === null || idGamePage === undefined) {
            throw new Error('Required parameter idGamePage was null or undefined when calling getGamePageById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GamePage>('get',`${this.basePath}/gamepages/${encodeURIComponent(String(idGamePage))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get game pages
     * 
     * @param idGame 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGamePages(idGame?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<GamePage>>;
    public getGamePages(idGame?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GamePage>>>;
    public getGamePages(idGame?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GamePage>>>;
    public getGamePages(idGame?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idGame !== undefined && idGame !== null) {
            queryParameters = queryParameters.set('idGame', <any>idGame);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<GamePage>>('get',`${this.basePath}/gamepages`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get game page by ID
     * 
     * @param idGamePage 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public submitGamePageById(idGamePage: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public submitGamePageById(idGamePage: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public submitGamePageById(idGamePage: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public submitGamePageById(idGamePage: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idGamePage === null || idGamePage === undefined) {
            throw new Error('Required parameter idGamePage was null or undefined when calling submitGamePageById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/gamepages/${encodeURIComponent(String(idGamePage))}/publish`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update game page translation by ID
     * 
     * @param body 
     * @param idGamePage 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateGamePageTranslation(body: GamePageTranslation, idGamePage: number, observe?: 'body', reportProgress?: boolean): Observable<GamePage>;
    public updateGamePageTranslation(body: GamePageTranslation, idGamePage: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GamePage>>;
    public updateGamePageTranslation(body: GamePageTranslation, idGamePage: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GamePage>>;
    public updateGamePageTranslation(body: GamePageTranslation, idGamePage: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateGamePageTranslation.');
        }

        if (idGamePage === null || idGamePage === undefined) {
            throw new Error('Required parameter idGamePage was null or undefined when calling updateGamePageTranslation.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GamePage>('put',`${this.basePath}/gamepages/${encodeURIComponent(String(idGamePage))}/translations`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Upload a ressource
     * 
     * @param idGamepage 
     * @param type 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadGamePageRessource(idGamepage: number, type: string, observe?: 'body', reportProgress?: boolean): Observable<UploadObject>;
    public uploadGamePageRessource(idGamepage: number, type: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UploadObject>>;
    public uploadGamePageRessource(idGamepage: number, type: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UploadObject>>;
    public uploadGamePageRessource(idGamepage: number, type: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idGamepage === null || idGamepage === undefined) {
            throw new Error('Required parameter idGamepage was null or undefined when calling uploadGamePageRessource.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling uploadGamePageRessource.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idGamepage !== undefined && idGamepage !== null) {
            queryParameters = queryParameters.set('id-gamepage', <any>idGamepage);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UploadObject>('post',`${this.basePath}/gamepages/upload/ressource`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
