<div *ngIf="libraryItem?.idGame" class="item" [style.background-image]="'linear-gradient( to right, rgb(0 0 0 / 50%), rgb(0 0 0 / 70%) ),' +
 'url('+environment.urlimgcdn+libraryItem?.idGame+'/'+libraryItem?.game?.gamePage?.graphics?.background?.fileName+')'">
  <div class="itema">
    <img  (load)="imageLoaded = true" [class.display-none]="!imageLoaded" class="game-cover" mat-card-image
         src="{{environment.urlimgcdn+libraryItem?.idGame+'/'+libraryItem?.game?.gamePage?.graphics?.cover?.fileName}}">
    <div *ngIf="!imageLoaded" [ngClass]="{'placeholder': true, 'animated-placeholder': !imageLoaded}"></div>
    <div class="content-item-library">
      <label class="title">{{ libraryItem?.game?.title }}</label>
      <div class="flex-row gap-15">
        <div class="flex-collum">
          <label class="libelle-item">{{'jeu.tempsDeJeuTotal' | translate}}</label>
          <label *ngIf="libraryItem?.secondesPlayed">{{ convertSeconds(libraryItem?.secondesPlayed!) }}</label>
        </div>
        <div class="flex-collum">
          <label class="libelle-item">{{'jeu.dernierLancement' | translate}}</label>
          <label>{{ libraryItem?.dateLastPlayed |date : 'longDate' }}</label>
        </div>
      </div>
    </div>
    <button class="btn-library" mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <a mat-menu-item [routerLink]="'/game/' + this.libraryItem.idGame">
        <mat-icon>description</mat-icon>
        <span>{{'jeu.pageDeJeu' | translate}}</span>
      </a>
      <button (click)="removeFromLibrary()" mat-menu-item>
        <mat-icon>delete</mat-icon>
        <span>{{'general.supprimer' | translate}}</span>
      </button>
    </mat-menu>
  </div>
</div>
