
// Interface pour un pays
export interface Country {
  name: string;
  nameEn: string;
  nameFr: string;
  code: string;
  isConsummerCountry: boolean;
  isDevelopperCountry: boolean;
}

// Exemple de liste de pays
export const COUNTRIES: Country[] = [
  {
    name: "Shqipëria",
    nameEn: "Albania",
    nameFr: "Albanie",
    code: "AL",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Angola",
    nameEn: "Angola",
    nameFr: "Angola",
    code: "AO",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Antigua and Barbuda",
    nameEn: "Antigua and Barbuda",
    nameFr: "Antigua-et-Barbuda",
    code: "AG",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "المملكة العربية السعودية",
    nameEn: "Saudi Arabia",
    nameFr: "Arabie Saoudite",
    code: "SA",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {
    name: "الجزائر",
    nameEn: "Algeria",
    nameFr: "Algérie",
    code: "DZ",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Argentina",
    nameEn: "Argentina",
    nameFr: "Argentine",
    code: "AR",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {
    name: "Հայաստան",
    nameEn: "Armenia",
    nameFr: "Arménie",
    code: "AM",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Australia",
    nameEn: "Australia",
    nameFr: "Australie",
    code: "AU",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {
    name: "Österreich",
    nameEn: "Austria",
    nameFr: "Autriche",
    code: "AT",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {
    name: "Azərbaycan",
    nameEn: "Azerbaijan",
    nameFr: "Azerbaïdjan",
    code: "AZ",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "The Bahamas",
    nameEn: "Bahamas",
    nameFr: "Bahamas",
    code: "BS",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "البحرين",
    nameEn: "Bahrain",
    nameFr: "Bahreïn",
    code: "BH",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "বাংলাদেশ",
    nameEn: "Bangladesh",
    nameFr: "Bangladesh",
    code: "BD",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "België",
    nameEn: "Belgium",
    nameFr: "Belgique",
    code: "BE",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {name: "Bénin", nameEn: "Benin", nameFr: "Bénin", code: "BJ", isConsummerCountry: false, isDevelopperCountry: true},
  {
    name: "འབྲུག་ཡུལ",
    nameEn: "Bhutan",
    nameFr: "Bhoutan",
    code: "BT",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Bolivia",
    nameEn: "Bolivia",
    nameFr: "Bolivie",
    code: "BO",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Bosna i Hercegovina",
    nameEn: "Bosnia and Herzegovina",
    nameFr: "Bosnie-Herzégovine",
    code: "BA",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Botswana",
    nameEn: "Botswana",
    nameFr: "Botswana",
    code: "BW",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Brasil",
    nameEn: "Brazil",
    nameFr: "Brésil",
    code: "BR",
    isConsummerCountry: true,
    isDevelopperCountry: false
  },
  {
    name: "Negara Brunei Darussalam",
    nameEn: "Brunei",
    nameFr: "Brunéi",
    code: "BN",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "България",
    nameEn: "Bulgaria",
    nameFr: "Bulgarie",
    code: "BG",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "កម្ពុជា",
    nameEn: "Cambodia",
    nameFr: "Cambodge",
    code: "KH",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Canada",
    nameEn: "Canada",
    nameFr: "Canada",
    code: "CA",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {name: "Chile", nameEn: "Chile", nameFr: "Chili", code: "CL", isConsummerCountry: false, isDevelopperCountry: true},
  {
    name: "Colombia",
    nameEn: "Colombia",
    nameFr: "Colombie",
    code: "CO",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Costa Rica",
    nameEn: "Costa Rica",
    nameFr: "Costa Rica",
    code: "CR",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Côte d'Ivoire",
    nameEn: "Ivory Coast",
    nameFr: "Côte d'Ivoire",
    code: "CI",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Hrvatska",
    nameEn: "Croatia",
    nameFr: "Croatie",
    code: "HR",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {
    name: "Κύπρος",
    nameEn: "Cyprus",
    nameFr: "Chypre",
    code: "CY",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {
    name: "Česko",
    nameEn: "Czech Republic",
    nameFr: "République tchèque",
    code: "CZ",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Danmark",
    nameEn: "Denmark",
    nameFr: "Danemark",
    code: "DK",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "República Dominicana",
    nameEn: "Dominican Republic",
    nameFr: "République dominicaine",
    code: "DO",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Ecuador",
    nameEn: "Ecuador",
    nameFr: "Équateur",
    code: "EC",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {name: "مصر", nameEn: "Egypt", nameFr: "Égypte", code: "EG", isConsummerCountry: false, isDevelopperCountry: true},
  {
    name: "El Salvador",
    nameEn: "El Salvador",
    nameFr: "Le Salvador",
    code: "SV",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "الإمارات العربية المتحدة",
    nameEn: "United Arab Emirates",
    nameFr: "Émirats Arabes Unis",
    code: "AE",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "España",
    nameEn: "Spain",
    nameFr: "Espagne",
    code: "ES",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {
    name: "Eesti",
    nameEn: "Estonia",
    nameFr: "Estonie",
    code: "EE",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {
    name: "ኢትዮጵያ",
    nameEn: "Ethiopia",
    nameFr: "Éthiopie",
    code: "ET",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Suomi",
    nameEn: "Finland",
    nameFr: "Finlande",
    code: "FI",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {
    name: "France",
    nameEn: "France",
    nameFr: "France",
    code: "FR",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {name: "Gabon", nameEn: "Gabon", nameFr: "Gabon", code: "GA", isConsummerCountry: false, isDevelopperCountry: true},
  {
    name: "The Gambia",
    nameEn: "Gambia",
    nameFr: "Gambie",
    code: "GM",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Deutschland",
    nameEn: "Germany",
    nameFr: "Allemagne",
    code: "DE",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {name: "Ghana", nameEn: "Ghana", nameFr: "Ghana", code: "GH", isConsummerCountry: false, isDevelopperCountry: true},
  {name: "Ελλάδα", nameEn: "Greece", nameFr: "Grèce", code: "GR", isConsummerCountry: true, isDevelopperCountry: true},
  {
    name: "Guatemala",
    nameEn: "Guatemala",
    nameFr: "Guatemala",
    code: "GT",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Guyana",
    nameEn: "Guyana",
    nameFr: "Guyane",
    code: "GY",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "香港",
    nameEn: "Hong Kong",
    nameFr: "Hong Kong",
    code: "HK",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Magyarország",
    nameEn: "Hungary",
    nameFr: "Hongrie",
    code: "HU",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Ísland",
    nameEn: "Iceland",
    nameFr: "Islande",
    code: "IS",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {name: "भारत", nameEn: "India", nameFr: "Inde", code: "IN", isConsummerCountry: true, isDevelopperCountry: true},
  {
    name: "Indonesia",
    nameEn: "Indonesia",
    nameFr: "Indonésie",
    code: "ID",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {
    name: "Ireland",
    nameEn: "Ireland",
    nameFr: "Irlande",
    code: "IE",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {name: "ישראל", nameEn: "Israel", nameFr: "Israël", code: "IL", isConsummerCountry: false, isDevelopperCountry: true},
  {name: "Italia", nameEn: "Italy", nameFr: "Italie", code: "IT", isConsummerCountry: true, isDevelopperCountry: true},
  {
    name: "Jamaica",
    nameEn: "Jamaica",
    nameFr: "Jamaïque",
    code: "JM",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {name: "日本", nameEn: "Japan", nameFr: "Japon", code: "JP", isConsummerCountry: true, isDevelopperCountry: true},
  {
    name: "الأردن",
    nameEn: "Jordan",
    nameFr: "Jordanie",
    code: "JO",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Қазақстан",
    nameEn: "Kazakhstan",
    nameFr: "Kazakhstan",
    code: "KZ",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {name: "Kenya", nameEn: "Kenya", nameFr: "Kenya", code: "KE", isConsummerCountry: false, isDevelopperCountry: true},
  {
    name: "대한민국",
    nameEn: "South Korea",
    nameFr: "Corée du Sud",
    code: "KR",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {
    name: "الكويت",
    nameEn: "Kuwait",
    nameFr: "Koweït",
    code: "KW",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "ສາທາລະນະລັດ ປະຊາທິປະໄຕ ປະຊາຊົນລາວ",
    nameEn: "Laos",
    nameFr: "Laos",
    code: "LA",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Latvija",
    nameEn: "Latvia",
    nameFr: "Lettonie",
    code: "LV",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {
    name: "Liechtenstein",
    nameEn: "Liechtenstein",
    nameFr: "Liechtenstein",
    code: "LI",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Lietuva",
    nameEn: "Lithuania",
    nameFr: "Lituanie",
    code: "LT",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {
    name: "Luxembourg",
    nameEn: "Luxembourg",
    nameFr: "Luxembourg",
    code: "LU",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {
    name: "澳門",
    nameEn: "Macau",
    nameFr: "RAS de Macao Chine",
    code: "MO",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Северна Македонија",
    nameEn: "North Macedonia",
    nameFr: "Macédoine du Nord",
    code: "MK",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Madagascar",
    nameEn: "Madagascar",
    nameFr: "Madagascar",
    code: "MG",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Malaysia",
    nameEn: "Malaysia",
    nameFr: "Malaisie",
    code: "MY",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {name: "Malta", nameEn: "Malta", nameFr: "Malte", code: "MT", isConsummerCountry: true, isDevelopperCountry: true},
  {
    name: "Mauritius",
    nameEn: "Mauritius",
    nameFr: "Maurice",
    code: "MU",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "México",
    nameEn: "Mexico",
    nameFr: "Mexique",
    code: "MX",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {
    name: "Moldova",
    nameEn: "Moldova",
    nameFr: "Moldavie",
    code: "MD",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Monaco",
    nameEn: "Monaco",
    nameFr: "Monaco",
    code: "MC",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Монгол улс",
    nameEn: "Mongolia",
    nameFr: "Mongolie",
    code: "MN",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "المغرب",
    nameEn: "Morocco",
    nameFr: "Maroc",
    code: "MA",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Moçambique",
    nameEn: "Mozambique",
    nameFr: "Mozambique",
    code: "MZ",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Namibia",
    nameEn: "Namibia",
    nameFr: "Namibie",
    code: "NA",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Nederland",
    nameEn: "Netherlands",
    nameFr: "Pays-Bas",
    code: "NL",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {
    name: "New Zealand",
    nameEn: "New Zealand",
    nameFr: "Nouvelle-Zélande",
    code: "NZ",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {name: "Niger", nameEn: "Niger", nameFr: "Niger", code: "NE", isConsummerCountry: false, isDevelopperCountry: true},
  {
    name: "Nigeria",
    nameEn: "Nigeria",
    nameFr: "Nigeria",
    code: "NG",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Norge",
    nameEn: "Norway",
    nameFr: "Norvège",
    code: "NO",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {name: "عمان", nameEn: "Oman", nameFr: "Oman", code: "OM", isConsummerCountry: false, isDevelopperCountry: true},
  {
    name: "O'zbekiston",
    nameEn: "Uzbekistan",
    nameFr: "Ouzbékistan",
    code: "UZ",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "پاکستان",
    nameEn: "Pakistan",
    nameFr: "Pakistan",
    code: "PK",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Panamá",
    nameEn: "Panama",
    nameFr: "Panama",
    code: "PA",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Paraguay",
    nameEn: "Paraguay",
    nameFr: "Paraguay",
    code: "PY",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {name: "Perú", nameEn: "Peru", nameFr: "Pérou", code: "PE", isConsummerCountry: false, isDevelopperCountry: true},
  {
    name: "Pilipinas",
    nameEn: "Philippines",
    nameFr: "Philippines",
    code: "PH",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Polska",
    nameEn: "Poland",
    nameFr: "Pologne",
    code: "PL",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {
    name: "Portugal",
    nameEn: "Portugal",
    nameFr: "Portugal",
    code: "PT",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {name: "قطر", nameEn: "Qatar", nameFr: "Qatar", code: "QA", isConsummerCountry: false, isDevelopperCountry: true},
  {
    name: "România",
    nameEn: "Romania",
    nameFr: "Roumanie",
    code: "RO",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Rwanda",
    nameEn: "Rwanda",
    nameFr: "Rwanda",
    code: "RW",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Saint Lucia",
    nameEn: "Saint Lucia",
    nameFr: "Sainte-Lucie",
    code: "LC",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "San Marino",
    nameEn: "San Marino",
    nameFr: "Saint-Marin",
    code: "SM",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "المملكة العربية السعودية",
    nameEn: "Saudi Arabia",
    nameFr: "Arabie Saoudite",
    code: "SA",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Sénégal",
    nameEn: "Senegal",
    nameFr: "Sénégal",
    code: "SN",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Србија",
    nameEn: "Serbia",
    nameFr: "Serbie",
    code: "RS",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Singapore",
    nameEn: "Singapore",
    nameFr: "Singapour",
    code: "SG",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Slovensko",
    nameEn: "Slovakia",
    nameFr: "Slovaquie",
    code: "SK",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {
    name: "Slovenija",
    nameEn: "Slovenia",
    nameFr: "Slovénie",
    code: "SI",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {
    name: "South Africa",
    nameEn: "South Africa",
    nameFr: "Afrique du Sud",
    code: "ZA",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {
    name: "ශ්‍රී ලංකා",
    nameEn: "Sri Lanka",
    nameFr: "Sri Lanka",
    code: "LK",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Sverige",
    nameEn: "Sweden",
    nameFr: "Suède",
    code: "SE",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Schweiz",
    nameEn: "Switzerland",
    nameFr: "Suisse",
    code: "CH",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {name: "臺灣", nameEn: "Taiwan", nameFr: "Taïwan", code: "TW", isConsummerCountry: false, isDevelopperCountry: true},
  {
    name: "Tanzania",
    nameEn: "Tanzania",
    nameFr: "Tanzanie",
    code: "TZ",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "ประเทศไทย",
    nameEn: "Thailand",
    nameFr: "Thaïlande",
    code: "TH",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {
    name: "Trinidad and Tobago",
    nameEn: "Trinidad and Tobago",
    nameFr: "Trinité-et-Tobago",
    code: "TT",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "تونس",
    nameEn: "Tunisia",
    nameFr: "Tunisie",
    code: "TN",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Türkiye",
    nameEn: "Turkey",
    nameFr: "Turquie",
    code: "TR",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {
    name: "United Kingdom",
    nameEn: "United Kingdom",
    nameFr: "Royaume-Uni",
    code: "GB",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {
    name: "United States",
    nameEn: "United States",
    nameFr: "États-Unis",
    code: "US",
    isConsummerCountry: true,
    isDevelopperCountry: true
  },
  {
    name: "Uruguay",
    nameEn: "Uruguay",
    nameFr: "Uruguay",
    code: "UY",
    isConsummerCountry: false,
    isDevelopperCountry: true
  },
  {
    name: "Việt Nam",
    nameEn: "Vietnam",
    nameFr: "Viêt Nam",
    code: "VN",
    isConsummerCountry: false,
    isDevelopperCountry: true
  }

];
