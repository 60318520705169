import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {FormControl} from "@angular/forms";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ActivatedRoute, NavigationStart, ResolveStart, Router} from "@angular/router";
import {environment} from "../../environments/environment";
import {CreationJeu} from "../../model/creation-jeu";
import {filter, map, Observable, startWith, Subscription} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {UploadImageComponent} from "./upload-image/upload-image.component";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ipImage} from "../../model/ipImage";
import {ImageService} from "../services/image.service";
import {SnackBarComponent} from "../snack-bar/snack-bar.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {GamePageService} from "../swagger/api/gamePage.service";
import {GamePageTranslation} from "../swagger/model/gamePageTranslation";
import {GamePage} from "../swagger/model/gamePage";
import {GamePageTranslationCreation} from "../swagger/model/gamePageTranslationCreation";
import {Images} from "../swagger/model/images";
import {ImageGamePage} from "../swagger/model/imageGamePage";
import {IndiepassModalsService} from "../services/indiepass-modals.service";
import {tap} from "rxjs/operators";
import {SnackBarService} from "../services/snack-bar.service";
import {IndiepassCodesEnum} from "../../model/enums/indiepass-codes-enum";
import {ActionsEnum} from "../snack-bar/enum-actions";
import {PannelsClassEnum} from "../snack-bar/pannels-class-enum";
import {GameService} from "../swagger/api/game.service";
import {GameLight} from "../swagger/model/gameLight";
import {MatSelectChange} from "@angular/material/select";
import {FileService} from "../services/file.service";
import {GeneralService} from "../general.service";

@Component({
  selector: 'app-creation',
  templateUrl: './creation.component.html',
  styleUrls: ['./creation.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        right: '0%'
      })),
      state('closed', style({
        right: '100%'
      })),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
      transition('open <=> closed', [
        animate('0.5s')
      ]),
    ])]
})
export class CreationComponent implements OnInit, OnDestroy {
  private devApi = environment.baseUrlAPI + '/protected/dev';
  public imgCouverturePresente: boolean = false;
  @ViewChild('fileInputVideo', {static: true}) fileInputVideo: HTMLInputElement;
  @ViewChild('screenShots', {static: false, read: ElementRef}) private screenShots: ElementRef;
  public videoLoading: boolean = false;

  public idGame: number;
  public idGamePage: number;
  public canShowVideo: boolean = true;
  private canSlide = true;
  private canSavePercentage: boolean = true;
  public showLanguagePanel: boolean = false;
  public languageSelected: string = "EN";
  public viewOnly: boolean = true;
  myControl = new FormControl('English');
  language: string[] = ["English", "Français"];
  filteredOptions: Observable<string[]>;
  creationJeuOld: GamePageTranslation;
  creationJeu: GamePageTranslationCreation;
  gamePage: GamePage;
  game: GameLight;
  videoUrl: any;
  videoForm = new FormControl();
  imageSrc: string = "";
  isOpen = true;
  uploadProgress = 0;
  private navigationSubscription: Subscription
  private forceNavigation: boolean = false;
  availableLanguages = [
    {libelle: 'English', code: 'EN'},
    {libelle: 'Français', code: 'FR'},
    {libelle: 'Spanish', code: 'SP'},
    {libelle: 'Deutsch', code: 'DE'}];

  constructor(public sanitizer: DomSanitizer, private route: ActivatedRoute, private fileService: FileService, public generalService: GeneralService,
              private indiePassModalService: IndiepassModalsService,
              private gamePageService: GamePageService, private gameService: GameService, public snackBarService: SnackBarService,
              public dialog: MatDialog, private _snackBar: MatSnackBar,
              private imageService: ImageService, private router: Router) {
    this.route.params.subscribe(params => {
      this.idGamePage = +params['idGamePage'];
      this.idGame = +params['idGame'];
    });
  }

  ngOnInit(): void {

    this.navigationSubscription = this.router.events.pipe(
      filter(event => event instanceof ResolveStart),
      tap((value) => this.canNavigate(value))
    ).subscribe();


    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const language = typeof value === 'string' ? value : value?.language;
        return language ? this._filter(language as string) : this.language.slice();
      }),
    );
    this.creationJeu = {};
    this.creationJeu.gamePageTranslation = {};
    this.creationJeu.gamePageTranslation.graphics = {};
    this.creationJeu.gamePageTranslation.description = "";
    this.creationJeu.gamePageTranslation.graphics.screenshots = [];
    this.initDataJeu()

  }

  onLanguageSelect(newLang: MatSelectChange, oldLangue: string): void {
    this.changeLanguage(newLang + "", oldLangue);

  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  canNavigate(url: any) {
    if (this.canSave() && !this.forceNavigation) {
      this.router.navigateByUrl(this.router.url);
      this.indiePassModalService.confirmationQuitWithoutSave().subscribe(value => {
        if (value) {
          this.forceNavigation = true;
          this.router.navigate([url.url]);
        }
      });
    }
  }

  testArray(array: any) {
    return Array.isArray(array)
  }

  displayFn(language: string): string {
    return language && language ? language : '';
  }

  private _filter(name: string): string[] {
    const filterValue = name.toLowerCase();

    return this.language.filter(language => language.toLowerCase().includes(filterValue));
  }

  canSave() {
    if (this.canSavePercentage) {
      return (JSON.stringify(this.creationJeu.gamePageTranslation) !== JSON.stringify(this.creationJeuOld) || (this.gamePage?.urlTrailer !== this.creationJeu.gamePageTranslation?.video))
        && this.areAllImagesLoaded()
        && !this.videoLoading;
    } else {
      return false;
    }
  }

  duplicatePromoPicturesFromEnglish() {
    this.gamePage.translations?.forEach(translation => {
      if (translation.languageCode === "EN") {
        console.log(translation.graphics?.icon)
        this.creationJeu.gamePageTranslation!.graphics!.cover = {...translation.graphics?.cover};
        this.creationJeu.gamePageTranslation!.graphics!.icon = {...translation.graphics?.icon};
        this.creationJeu.gamePageTranslation!.graphics!.background = {...translation.graphics?.background};
        this.creationJeu.gamePageTranslation!.graphics!.featured = {...translation.graphics?.featured};
      }
    });
  }

  duplicateIllustrationsFromEnglish() {
    this.gamePage.translations?.forEach(translation => {
      console.log(translation.graphics?.screenshots)
      if (translation.languageCode === "EN" && translation.graphics?.screenshots) {
        console.log(translation.graphics?.screenshots)
        this.creationJeu.gamePageTranslation!.graphics!.screenshots = [...translation.graphics?.screenshots];

      }
    });
  }

  previousValue: string = '';

  limitLineBreaks(textarea: HTMLTextAreaElement, event: Event): void {
    const maxLineBreaks = 9;
    let currentValue = textarea.value;
    let lineBreaksCount = (currentValue.match(/\n/g) || []).length;

    if (lineBreaksCount > maxLineBreaks) {
      // Restaurer la valeur précédente qui était sous la limite de sauts de ligne
      event.preventDefault(); // Annuler l'ajout du dernier caractère
      textarea.value = this.previousValue;
    } else {
      // Mettre à jour la valeur précédente si elle est dans la limite
      this.previousValue = currentValue;
    }
  }

  getScrollAmount(): number {
    const scroller = this.screenShots.nativeElement as HTMLElement;
    if (scroller && scroller.children.length > 0) {
      const item = scroller.children[0] as HTMLElement;
      return item.offsetWidth; // Ajouter une marge si nécessaire
    }
    return 0;
  }

  goRight() {
    if (this.canSlide) {
      this.canSlide = false;
      const scroller = document.getElementById("screenShots");
      const scrollValue = this.getScrollAmount(); // Utilisez la nouvelle méthode ici
      scroller!.scrollBy({left: scrollValue, top: 0, behavior: 'smooth'})
      this.delay(300).then(() => {
        this.canSlide = true;
      })
    }
  }

  goLeft() {
    if (this.canSlide) {
      this.canSlide = false;
      const scroller = document.getElementById("screenShots");
      const scrollValue = this.getScrollAmount(); // Utilisez la nouvelle méthode ici
      scroller?.scrollBy({left: -scrollValue, top: 0, behavior: 'smooth'});
      this.delay(300).then(() => {
        this.canSlide = true;
      });
    }
  }

  isValidUrl(value: string): boolean {
    try {
      new URL(value);
      return true;
    } catch (_) {
      return false;
    }
  }


  changeLanguage(langue: string, oldLangue: string) {
    if (this.canSave()) {
      this.indiePassModalService.confirmationQuitWithoutSave().subscribe(value => {
        if (value) {
          this.gamePage.urlTrailer = this.creationJeu.gamePageTranslation!.video
          this.languageSelected = langue;
          this.canShowVideo = false;

          this.initDataJeu();
        } else {
          this.languageSelected = oldLangue
        }
      });
    } else {
      this.languageSelected = langue;
      this.initDataJeu();
    }

  }

  //delete gamePageTranslation by language

  initDataJeu() {
    this.gamePageService.getGamePageById(this.idGamePage).subscribe(data => {
      if (data) {
        this.gamePage = {...data}
        this.canShowVideo = true;

        this.viewOnly = this.gamePage.state === "EXPLOITED";
        this.creationJeu.gamePageTranslation = this.getByLanguage(this.gamePage.translations!, this.languageSelected)
        this.creationJeuOld = {...this.creationJeu?.gamePageTranslation};
        this.creationJeuOld.graphics = {...this.creationJeu?.gamePageTranslation?.graphics};

      }
    }, () => {
      this.forceNavigation = true;
    });
    this.gameService.getGameById(this.idGame).subscribe(game => {
      if (game) {
        this.game = game;
      }
    }, () => {
    });

  }

  handleMissingImage(event: any) {
    if (this.creationJeu?.gamePageTranslation?.graphics?.cover !== undefined) {
      //   this.creationJeu.gamePageTranslation.graphics.cover.fileName = undefined;
    }
  }

  handleMissingImageScreenShot(event: any) {
    if (this.creationJeu?.gamePageTranslation?.graphics?.screenshots !== undefined) {
      this.creationJeu.gamePageTranslation.graphics.screenshots = []
    }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  updateVideoUrl(id: string) {
    // Appending an ID to a YouTube URL is safe.
    // Always make sure to construct SafeValue objects as
    // close as possible to the input data so
    // that it's easier to check if the value is safe.
    let dangerousVideoUrl = 'https://www.youtube.com/embed/' + id;
    return this.sanitizer.bypassSecurityTrustResourceUrl(dangerousVideoUrl);
  }

  sauvegarger() {
    if (this.gamePage?.id && this.creationJeu.gamePageTranslation) {
      this.creationJeu.gamePageTranslation.video = this.gamePage.urlTrailer;
      this.gamePageService.updateGamePageTranslation(this.creationJeu.gamePageTranslation, this.gamePage.id).subscribe(data => {
        this.initDataJeu();
      });
    }
  }

  uploader(file: File, type: string) {
    const formData = new FormData();
    formData.append(type, file);
    const headers = new HttpHeaders().set('Content-Type', 'image/jpeg');
    // this.http.post(this.devApi + "/upload?id=" + this.gamePage.id + "&type=" + type, {}).subscribe(data => {
    this.gamePageService.uploadGamePageRessource(this.gamePage.id!, type).subscribe(data => {
      this.canSavePercentage = false;
      this.creationJeu.couverturePercentage = 0.1;
      let oui: any = data;
      let path = oui.path
      if (type === "mp4") {
        this.videoLoading = true; // Début du chargement de la vidéo
      }
      this.imageService.uploadImage(file, oui.linkUpload, headers).subscribe(
        (progress) => {
          if (type === "mp4") {
            this.canShowVideo = false;
            this.creationJeu.videoPercentage = progress
            this.gamePage.urlTrailer = path;
          }
          // @ts-ignore
          this.creationJeu.couverturePercentage = progress
          this.uploadProgress = progress;
          this.canSavePercentage = false;
        },
        (error) => {
          if (type === "mp4") {
            this.videoLoading = false; // Erreur de chargement de la vidéo
          }
          this.canSavePercentage = true;
          // @ts-ignore
          delete this.creationJeu.couvertureURL;
        },
        () => {
          this.canSavePercentage = true;
          this.uploadProgress = 0;
          if (this.creationJeu.gamePageTranslation!.graphics === undefined) {
            this.creationJeu.gamePageTranslation!.graphics = {};
          }
          if (type === "cover") {

            this.creationJeu.gamePageTranslation!.graphics.cover = {}
            this.creationJeu.gamePageTranslation!.graphics.cover!.fileName = path;
            this.creationJeu.gamePageTranslation!.graphics.cover!.loading = true;
            this.checkImage(this.creationJeu.gamePageTranslation!.graphics.cover);

          }
          if (type === "icon") {

            this.creationJeu.gamePageTranslation!.graphics.icon = {}
            this.creationJeu.gamePageTranslation!.graphics.icon!.fileName = path;
            this.creationJeu.gamePageTranslation!.graphics.icon!.loading = true;
            this.checkImage(this.creationJeu.gamePageTranslation!.graphics.icon);

          }
          if (type === "featured") {

            this.creationJeu.gamePageTranslation!.graphics.featured = {}
            this.creationJeu.gamePageTranslation!.graphics.featured!.fileName = path;
            this.creationJeu.gamePageTranslation!.graphics.featured!.loading = true;
            this.checkImage(this.creationJeu.gamePageTranslation!.graphics.featured);

          }
          if (type === "background") {
            this.creationJeu.gamePageTranslation!.graphics.background = {}
            this.creationJeu.gamePageTranslation!.graphics.background!.fileName = path;
            this.creationJeu.gamePageTranslation!.graphics.background!.loading = true;
            this.checkImage(this.creationJeu.gamePageTranslation!.graphics.background);

          }
          if (type === "mp4") {
            this.creationJeu.videoPercentage = undefined
            this.checkVideo();

          }
        }
      );
    });
  }

  uploaderVideo(file: File) {
    this.uploader(file, "mp4")
  }

  showModalUploadImages() {
    const dialogRef = this.dialog.open(UploadImageComponent, {
      data: {
        screenshots: this?.creationJeu?.gamePageTranslation?.graphics?.screenshots,
        idJeu: this.idGame,
        idGamePage: this.idGamePage
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.creationJeu.gamePageTranslation!.graphics!.screenshots = result;
        this.creationJeu = {...this.creationJeu};
        document.documentElement.style.setProperty('--num-of-slide', result.length);
      }
    });
  }

  test() {
    let video = this.videoForm.value;
    video = video.split("watch?v=").pop() + "";
    video = video.split("&")[0] + "";
    // this.videoUrl = this.updateVideoUrl(video)
  }

  imageUpload(event: any, type: string) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file]: File[] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (type === 'f')
          this.imageSrc = reader.result as string;

        if (type === 'back_ground') {
          this.creationJeu.gamePageTranslation!.graphics!.background!.fileName = reader?.result + "";

        } else if (type === 'cover') {
          this.fileService.checkImageSize(file, 1280, 720).then(() => {
            this.uploader(file, "cover")
          }).catch(() => {
            this.errorSizeImage();
          });
        } else if (type === 'background') {
          this.fileService.checkImageSize(file, 1280, 720).then(() => {
            this.uploader(file, "background")
          }).catch(() => {
            this.errorSizeImage();
          });
        } else if (type === 'icon') {
          this.fileService.checkImageSize(file, 128, 128).then(() => {
            this.uploader(file, "icon")
          }).catch(() => {
            this.errorSizeImage();
          });
        } else if (type === 'featured') {
          this.fileService.checkImageSize(file, 720, 960).then(() => {
            this.uploader(file, "featured")
          }).catch(() => {
            this.errorSizeImage();
          });
        } else if (type === 'video') {
          if (this.fileService.isVideoFile(file)) {
            this.uploaderVideo(file)
          }
        }
      }
    }
  }

  errorSizeImage() {
    this.snackBarService.openSnackBar(IndiepassCodesEnum.IMAGE_SIZE_KO, ActionsEnum.Ok, PannelsClassEnum.error)

  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.canSave()) {
      $event.returnValue = 'You have unsaved changes. Are you sure you want to quit ?';
    }
  }

  checkImage = (image: ImageGamePage) => {

    const img = new Image();
    img.src = environment.urlimgcdn + this.idGame + "/" + image?.fileName + "?class=screenshot";
    img.onload = () => {
      image.loading = false;
    };
    img.onerror = () => {
      setTimeout(this.checkImage, 2500, image);
    };
  };
  checkVideo = () => {
    const video = document.createElement('video');
    video.src = environment.urlimgcdn + this.idGame + '/video/' + this.gamePage?.urlTrailer
    video.onerror = () => {
      setTimeout(this.checkVideo, 2500);
    };
    video.onloadeddata = () => {
      this.canShowVideo = true;
      this.videoLoading = false; // Vidéo chargée


    };

  };

  getByLanguage(translations: GamePageTranslation[], languageCode: string): GamePageTranslation {
    const translation = translations.find(t => t.languageCode === languageCode);

    if (translation) {
      return translation;
    }


    return {
      languageCode: languageCode, // Ou 'undefined' si vous ne souhaitez pas définir de code par défaut
      description: '',
      video: '',
      graphics: {}, // Remplacer par les valeurs par défaut appropriées pour l'interface Images
    };
  }


  deleteByLanguage(): boolean {
    if (this.gamePage.translations !== undefined && this.languageSelected !== "EN") {
      this.indiePassModalService.confirmationDelete().subscribe(value => {
        if (value) {
          const index = this.gamePage.translations!.findIndex(t => t.languageCode === this.languageSelected);
          if (index !== -1) {
            this.gamePage.translations!.splice(index, 1);
            this.gamePageService.deleteGamePageTranslation(this.gamePage.id!, this.languageSelected).subscribe(data => {
            });

            this.languageSelected = "EN";
            this.creationJeu.gamePageTranslation = this.getByLanguage(this.gamePage.translations!, this.languageSelected)

          }
          this.languageSelected = "EN";
          this.creationJeu.gamePageTranslation = this.getByLanguage(this.gamePage.translations!, this.languageSelected)
          return false;
        } else {
          return false;
        }

      });


    }
    return false;

  }

  areAllImagesLoaded(): boolean {
    const graphics = this.creationJeu?.gamePageTranslation?.graphics;
    if (!graphics) return true;

    const images = [
      graphics.cover,
      graphics.icon,
      graphics.background,
      graphics.featured,
      ...(graphics.screenshots || [])
    ];

    return images.every(image => !image?.loading);
  }

  isDescriptionValid(): boolean {
    if (this.creationJeu.gamePageTranslation?.description) {
      return this.creationJeu.gamePageTranslation?.description?.length >= 50;
    }
    return false;
  }

  isValidScreenshotCount(): boolean {
    const screenshots = this.creationJeu.gamePageTranslation?.graphics?.screenshots;
    if (screenshots && screenshots.length >= 0) {
      return screenshots && screenshots.length >= 3 && screenshots.length <= 7;
    }
    return false;
  }

  readonly environment = environment;
}
