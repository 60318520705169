<div *ngIf="gameLight && gameLight?.id && gameLight.gamePage?.id && !isLoading">
  <div id="background" [ngStyle]="{background:
'url(' + environment.urlimgcdn +gameLight?.id+'/'+gameLight?.gamePage?.graphics?.background?.fileName+ ')'}"
       xmlns="http://www.w3.org/1999/html">
    <div class="layer">

    </div>
  </div>

  <div class="wrapper-gamepage">
    <div class="wrapper">
      <div>
        <div class=" containerTitreMobile">
          <h1 *ngIf="gameLight">{{ gameLight.title }}</h1>
          <button *ngIf="canAddGameToLibrary" (click)="addToLibrary()" class="add-to-library-btn-mobile" type="button"
                  mat-raised-button>{{ "general.ajouter" | translate }}
          </button>
          <button [disabled]="true" *ngIf="!canAddGameToLibrary && userId" (click)="removeFromLibrary()"
                  class="add-to-library-btn-mobile"
                  type="button" mat-raised-button>{{ 'general.DejaAjoute' | translate }}
          </button>
        </div>
        <app-carrousel *ngIf="gameLight" [gameId]="gameLight?.id!"
                       [images]="gameLight?.gamePage!.graphics!.screenshots!"
                       [video]="gameLight?.gamePage!.video!"></app-carrousel>
        <app-game-details class="pcOnly" *ngIf="gameLight" [game]="gameLight"></app-game-details>
      </div>
      <div class="bottom">

        <button *ngIf="canAddGameToLibrary" (click)="addToLibrary()" class="add-to-library-btn" type="button"
                mat-raised-button>{{ "general.ajouter" | translate }}
        </button>

        <button [disabled]=" true" *ngIf="!canAddGameToLibrary && userId"
                class="add-to-library-btn" type="button"
                mat-raised-button
                (mouseover)="isHovering = true"
                (mouseleave)="isHovering = false"
                (click)="removeFromLibrary()"
                [ngClass]="{'hovera': isHovering}">
          {{ ('general.DejaAjoute' | translate) }}
        </button>


        <div class="right-zone-wrapper">

          <div class="right-zone">
            <div class="featrued-img">
              <img (load)="coverIsLoading = false" [class.display-none]="coverIsLoading"
                   [src]="environment?.urlimgcdn!+gameLight?.id!+'/'+gameLight?.gamePage?.graphics?.featured?.fileName+'?class=screenshot'">
              <div *ngIf="coverIsLoading" class="animated-placeholder featrued-img-placeholder "></div>
            </div>
            <p class="description ">
              {{ gameLight?.gamePage?.description }}
            </p>
          </div>
          <div class="infos">
            <div>
              {{ "general.editeur" | translate }}: {{ gameLight?.publisher?.name }}
            </div>
            <div>
              {{ "general.developpeur" | translate }}: {{ gameLight?.developer?.name }}
            </div>

            <mat-chip-list class="list-info">
              <mat-chip *ngIf="gameLight.solo"> {{ "general.solo" | translate }}</mat-chip>
              <mat-chip *ngIf="gameLight.localMulti"> {{ "general.multiLocal" | translate }}</mat-chip>
              <mat-chip *ngIf="gameLight.onlineMulti"> {{ "general.multiEnLigne" | translate }}</mat-chip>
            </mat-chip-list>
            <mat-chip-list #chipList>
              <a *ngFor="let genre of gameLight?.genres" > <!--[routerLink]="'/list-game'" -->
                <mat-chip>{{ genre.name }}</mat-chip>
              </a>
            </mat-chip-list>
            <app-achievements *ngIf="gameLight && achievements " [achievements]="achievements"
                              [gameLight]="gameLight"></app-achievements>
          </div>
        </div>
        <app-game-details class="mobileOnly" *ngIf="gameLight" [game]="gameLight"></app-game-details>
      </div>
    </div>


  </div>


</div>

