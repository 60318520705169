/**
 * IndiePass API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Certificate } from '../model/certificate';
import { DownloadLink } from '../model/downloadLink';
import { Game } from '../model/game';
import { GameLight } from '../model/gameLight';
import { GameRelease } from '../model/gameRelease';
import { GameSearchResult } from '../model/gameSearchResult';
import { Member } from '../model/member';
import { UploadObject } from '../model/uploadObject';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class GameService {

    protected basePath = 'https://api.indiepass.com/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Add member to a game by email
     * 
     * @param idGame 
     * @param emailMember 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addMemberGameRightsByEmail(idGame: number, emailMember: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public addMemberGameRightsByEmail(idGame: number, emailMember: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public addMemberGameRightsByEmail(idGame: number, emailMember: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public addMemberGameRightsByEmail(idGame: number, emailMember: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idGame === null || idGame === undefined) {
            throw new Error('Required parameter idGame was null or undefined when calling addMemberGameRightsByEmail.');
        }

        if (emailMember === null || emailMember === undefined) {
            throw new Error('Required parameter emailMember was null or undefined when calling addMemberGameRightsByEmail.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (emailMember !== undefined && emailMember !== null) {
            queryParameters = queryParameters.set('emailMember', <any>emailMember);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/games/${encodeURIComponent(String(idGame))}/member`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create game
     * 
     * @param body 
     * @param devTag 
     * @param editorTag 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createGame(body: Game, devTag: string, editorTag: string, observe?: 'body', reportProgress?: boolean): Observable<Game>;
    public createGame(body: Game, devTag: string, editorTag: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Game>>;
    public createGame(body: Game, devTag: string, editorTag: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Game>>;
    public createGame(body: Game, devTag: string, editorTag: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createGame.');
        }

        if (devTag === null || devTag === undefined) {
            throw new Error('Required parameter devTag was null or undefined when calling createGame.');
        }

        if (editorTag === null || editorTag === undefined) {
            throw new Error('Required parameter editorTag was null or undefined when calling createGame.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (devTag !== undefined && devTag !== null) {
            queryParameters = queryParameters.set('devTag', <any>devTag);
        }
        if (editorTag !== undefined && editorTag !== null) {
            queryParameters = queryParameters.set('editorTag', <any>editorTag);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Game>('post',`${this.basePath}/games`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete the gameRelease
     * 
     * @param idGame 
     * @param idRelease 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteGameRelease(idGame: number, idRelease: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteGameRelease(idGame: number, idRelease: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteGameRelease(idGame: number, idRelease: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteGameRelease(idGame: number, idRelease: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idGame === null || idGame === undefined) {
            throw new Error('Required parameter idGame was null or undefined when calling deleteGameRelease.');
        }

        if (idRelease === null || idRelease === undefined) {
            throw new Error('Required parameter idRelease was null or undefined when calling deleteGameRelease.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/games/${encodeURIComponent(String(idGame))}/releases/${encodeURIComponent(String(idRelease))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete member rights
     * 
     * @param idGame 
     * @param idMember 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMemberGameRights(idGame: number, idMember: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteMemberGameRights(idGame: number, idMember: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteMemberGameRights(idGame: number, idMember: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteMemberGameRights(idGame: number, idMember: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idGame === null || idGame === undefined) {
            throw new Error('Required parameter idGame was null or undefined when calling deleteMemberGameRights.');
        }

        if (idMember === null || idMember === undefined) {
            throw new Error('Required parameter idMember was null or undefined when calling deleteMemberGameRights.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idMember !== undefined && idMember !== null) {
            queryParameters = queryParameters.set('idMember', <any>idMember);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/games/${encodeURIComponent(String(idGame))}/memberRights`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Ask for a certificate to play game
     * 
     * @param idGames 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateCertificates(idGames: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<Array<Certificate>>;
    public generateCertificates(idGames: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Certificate>>>;
    public generateCertificates(idGames: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Certificate>>>;
    public generateCertificates(idGames: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idGames === null || idGames === undefined) {
            throw new Error('Required parameter idGames was null or undefined when calling generateCertificates.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idGames) {
            idGames.forEach((element) => {
                queryParameters = queryParameters.append('idGames', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Certificate>>('get',`${this.basePath}/games/certificates`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get game by ID
     * 
     * @param idGame 
     * @param actualVersion 
     * @param downloadingVersion 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDownloadLink(idGame: number, actualVersion?: string, downloadingVersion?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DownloadLink>>;
    public getDownloadLink(idGame: number, actualVersion?: string, downloadingVersion?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DownloadLink>>>;
    public getDownloadLink(idGame: number, actualVersion?: string, downloadingVersion?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DownloadLink>>>;
    public getDownloadLink(idGame: number, actualVersion?: string, downloadingVersion?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idGame === null || idGame === undefined) {
            throw new Error('Required parameter idGame was null or undefined when calling getDownloadLink.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (actualVersion !== undefined && actualVersion !== null) {
            queryParameters = queryParameters.set('actualVersion', <any>actualVersion);
        }
        if (downloadingVersion !== undefined && downloadingVersion !== null) {
            queryParameters = queryParameters.set('downloadingVersion', <any>downloadingVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DownloadLink>>('get',`${this.basePath}/games/${encodeURIComponent(String(idGame))}/download`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get game by ID
     * 
     * @param idGame 
     * @param devInfos 
     * @param langue 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGameById(idGame: number, devInfos?: boolean, langue?: string, observe?: 'body', reportProgress?: boolean): Observable<GameLight>;
    public getGameById(idGame: number, devInfos?: boolean, langue?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GameLight>>;
    public getGameById(idGame: number, devInfos?: boolean, langue?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GameLight>>;
    public getGameById(idGame: number, devInfos?: boolean, langue?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idGame === null || idGame === undefined) {
            throw new Error('Required parameter idGame was null or undefined when calling getGameById.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (devInfos !== undefined && devInfos !== null) {
            queryParameters = queryParameters.set('devInfos', <any>devInfos);
        }
        if (langue !== undefined && langue !== null) {
            queryParameters = queryParameters.set('langue', <any>langue);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GameLight>('get',`${this.basePath}/games/${encodeURIComponent(String(idGame))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get game releases
     * 
     * @param idGame 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGameRelease(idGame: number, observe?: 'body', reportProgress?: boolean): Observable<Array<GameRelease>>;
    public getGameRelease(idGame: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GameRelease>>>;
    public getGameRelease(idGame: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GameRelease>>>;
    public getGameRelease(idGame: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idGame === null || idGame === undefined) {
            throw new Error('Required parameter idGame was null or undefined when calling getGameRelease.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<GameRelease>>('get',`${this.basePath}/games/${encodeURIComponent(String(idGame))}/releases`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all games
     * 
     * @param sort 
     * @param name Sort order (e.g., name_asc, name_desc)
     * @param type 
     * @param isSoloPlayer soloPlayer game
     * @param isOnlineMultiPlayer online multiPlayer game
     * @param isLocalMultiPlayer local multiPlayer game
     * @param isNewGame the game is recent
     * @param isLeavingSoon the game will leave
     * @param developedByUser Jeux développés par l&#x27;utilisateur
     * @param limit Maximum number of games to return
     * @param offset Offset for pagination
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGames(sort?: string, name?: string, type?: Array<string>, isSoloPlayer?: boolean, isOnlineMultiPlayer?: boolean, isLocalMultiPlayer?: boolean, isNewGame?: boolean, isLeavingSoon?: boolean, developedByUser?: boolean, limit?: number, offset?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<GameSearchResult>>;
    public getGames(sort?: string, name?: string, type?: Array<string>, isSoloPlayer?: boolean, isOnlineMultiPlayer?: boolean, isLocalMultiPlayer?: boolean, isNewGame?: boolean, isLeavingSoon?: boolean, developedByUser?: boolean, limit?: number, offset?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GameSearchResult>>>;
    public getGames(sort?: string, name?: string, type?: Array<string>, isSoloPlayer?: boolean, isOnlineMultiPlayer?: boolean, isLocalMultiPlayer?: boolean, isNewGame?: boolean, isLeavingSoon?: boolean, developedByUser?: boolean, limit?: number, offset?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GameSearchResult>>>;
    public getGames(sort?: string, name?: string, type?: Array<string>, isSoloPlayer?: boolean, isOnlineMultiPlayer?: boolean, isLocalMultiPlayer?: boolean, isNewGame?: boolean, isLeavingSoon?: boolean, developedByUser?: boolean, limit?: number, offset?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {












        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }
        if (type) {
            type.forEach((element) => {
                queryParameters = queryParameters.append('type', <any>element);
            })
        }
        if (isSoloPlayer !== undefined && isSoloPlayer !== null) {
            queryParameters = queryParameters.set('isSoloPlayer', <any>isSoloPlayer);
        }
        if (isOnlineMultiPlayer !== undefined && isOnlineMultiPlayer !== null) {
            queryParameters = queryParameters.set('isOnlineMultiPlayer', <any>isOnlineMultiPlayer);
        }
        if (isLocalMultiPlayer !== undefined && isLocalMultiPlayer !== null) {
            queryParameters = queryParameters.set('isLocalMultiPlayer', <any>isLocalMultiPlayer);
        }
        if (isNewGame !== undefined && isNewGame !== null) {
            queryParameters = queryParameters.set('isNewGame', <any>isNewGame);
        }
        if (isLeavingSoon !== undefined && isLeavingSoon !== null) {
            queryParameters = queryParameters.set('isLeavingSoon', <any>isLeavingSoon);
        }
        if (developedByUser !== undefined && developedByUser !== null) {
            queryParameters = queryParameters.set('developedByUser', <any>developedByUser);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<GameSearchResult>>('get',`${this.basePath}/games`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get team by game ID
     * 
     * @param idGame 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTeamByGameId(idGame: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Member>>;
    public getTeamByGameId(idGame: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Member>>>;
    public getTeamByGameId(idGame: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Member>>>;
    public getTeamByGameId(idGame: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idGame === null || idGame === undefined) {
            throw new Error('Required parameter idGame was null or undefined when calling getTeamByGameId.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Member>>('get',`${this.basePath}/games/${encodeURIComponent(String(idGame))}/members`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all games where the user is having a developer role
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserDevGames(observe?: 'body', reportProgress?: boolean): Observable<Array<GameLight>>;
    public getUserDevGames(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GameLight>>>;
    public getUserDevGames(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GameLight>>>;
    public getUserDevGames(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<GameLight>>('get',`${this.basePath}/games/dev`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Genereate a link to upload a release
     * 
     * @param idGame 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publishGame(idGame: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public publishGame(idGame: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public publishGame(idGame: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public publishGame(idGame: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idGame === null || idGame === undefined) {
            throw new Error('Required parameter idGame was null or undefined when calling publishGame.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/games/${encodeURIComponent(String(idGame))}/publish`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set the release to live
     * 
     * @param idGame 
     * @param idRelease 
     * @param idPath 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setReleaseTolive(idGame: number, idRelease: number, idPath?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public setReleaseTolive(idGame: number, idRelease: number, idPath?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public setReleaseTolive(idGame: number, idRelease: number, idPath?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public setReleaseTolive(idGame: number, idRelease: number, idPath?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idGame === null || idGame === undefined) {
            throw new Error('Required parameter idGame was null or undefined when calling setReleaseTolive.');
        }

        if (idRelease === null || idRelease === undefined) {
            throw new Error('Required parameter idRelease was null or undefined when calling setReleaseTolive.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idPath !== undefined && idPath !== null) {
            queryParameters = queryParameters.set('idPath', <any>idPath);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/games/${encodeURIComponent(String(idGame))}/releases/${encodeURIComponent(String(idRelease))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateGameInfos
     * 
     * @param body 
     * @param idGame 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateGamesInfos(body: GameLight, idGame: number, observe?: 'body', reportProgress?: boolean): Observable<GameLight>;
    public updateGamesInfos(body: GameLight, idGame: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GameLight>>;
    public updateGamesInfos(body: GameLight, idGame: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GameLight>>;
    public updateGamesInfos(body: GameLight, idGame: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateGamesInfos.');
        }

        if (idGame === null || idGame === undefined) {
            throw new Error('Required parameter idGame was null or undefined when calling updateGamesInfos.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GameLight>('post',`${this.basePath}/games/${encodeURIComponent(String(idGame))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update member rights for game by email
     * 
     * @param body 
     * @param idGame 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMemberGameRightsByEmail(body: Array<Member>, idGame: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateMemberGameRightsByEmail(body: Array<Member>, idGame: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateMemberGameRightsByEmail(body: Array<Member>, idGame: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateMemberGameRightsByEmail(body: Array<Member>, idGame: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateMemberGameRightsByEmail.');
        }

        if (idGame === null || idGame === undefined) {
            throw new Error('Required parameter idGame was null or undefined when calling updateMemberGameRightsByEmail.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/games/${encodeURIComponent(String(idGame))}/memberRights`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Genereate a link to upload a release
     * 
     * @param body 
     * @param location 
     * @param idGame 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadGameRelease(body: GameRelease, location: string, idGame: number, observe?: 'body', reportProgress?: boolean): Observable<UploadObject>;
    public uploadGameRelease(body: GameRelease, location: string, idGame: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UploadObject>>;
    public uploadGameRelease(body: GameRelease, location: string, idGame: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UploadObject>>;
    public uploadGameRelease(body: GameRelease, location: string, idGame: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling uploadGameRelease.');
        }

        if (location === null || location === undefined) {
            throw new Error('Required parameter location was null or undefined when calling uploadGameRelease.');
        }

        if (idGame === null || idGame === undefined) {
            throw new Error('Required parameter idGame was null or undefined when calling uploadGameRelease.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (location !== undefined && location !== null) {
            queryParameters = queryParameters.set('location', <any>location);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UploadObject>('post',`${this.basePath}/games/${encodeURIComponent(String(idGame))}/releases`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Allow the filemanger to update the status of the release
     * 
     * @param idGame 
     * @param major 
     * @param minor 
     * @param patch 
     * @param apiKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadedRelease(idGame: number, major: number, minor: number, patch: number, apiKey: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public uploadedRelease(idGame: number, major: number, minor: number, patch: number, apiKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public uploadedRelease(idGame: number, major: number, minor: number, patch: number, apiKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public uploadedRelease(idGame: number, major: number, minor: number, patch: number, apiKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idGame === null || idGame === undefined) {
            throw new Error('Required parameter idGame was null or undefined when calling uploadedRelease.');
        }

        if (major === null || major === undefined) {
            throw new Error('Required parameter major was null or undefined when calling uploadedRelease.');
        }

        if (minor === null || minor === undefined) {
            throw new Error('Required parameter minor was null or undefined when calling uploadedRelease.');
        }

        if (patch === null || patch === undefined) {
            throw new Error('Required parameter patch was null or undefined when calling uploadedRelease.');
        }

        if (apiKey === null || apiKey === undefined) {
            throw new Error('Required parameter apiKey was null or undefined when calling uploadedRelease.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (major !== undefined && major !== null) {
            queryParameters = queryParameters.set('major', <any>major);
        }
        if (minor !== undefined && minor !== null) {
            queryParameters = queryParameters.set('minor', <any>minor);
        }
        if (patch !== undefined && patch !== null) {
            queryParameters = queryParameters.set('patch', <any>patch);
        }
        if (apiKey !== undefined && apiKey !== null) {
            queryParameters = queryParameters.set('apiKey', <any>apiKey);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/games/${encodeURIComponent(String(idGame))}/releases/webhook`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
