<div class="white-theme wrapper-popup">
  <h1 mat-dialog-title>{{'popup.ajouterUnMembre' | translate}}</h1>
  <div>
    <form>
      <mat-form-field class="email-field">
        <mat-label>Email</mat-label>
        <input type="email" matInput [formControl]="emailFormControl" placeholder="Ex. pat@example.com">
        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
          {{'register.emailInvalid' | translate}}
        </mat-error>
        <mat-error *ngIf="emailFormControl.hasError('required')">
          Email is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </form>

    <div mat-dialog-actions>
      <button mat-flat-button color="warn" (click)="onNoClick()">{{'general.annuler' | translate}}</button>
      <button [appDisableButton]="isLoading" [disabled]="!emailFormControl.valid" (click)="addmember()" mat-flat-button color="primary">
        {{'general.valider' | translate}}
      </button>
    </div>
  </div>
</div>
