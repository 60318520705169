<div class="white-theme ip-center">
  <h1 mat-dialog-title>{{ 'creerJeuPopup.nouvelleEditionDeJeu' |translate }}</h1>
  <div mat-dialog-content>
    <form [formGroup]="jeuForm" class="mat-typography">

      <mat-form-field appearance="fill">
        <mat-label>{{ 'creerJeuPopup.titreDuJeu' |translate }}</mat-label>
        <input matInput formControlName="title">
      </mat-form-field>
      <br>

      <mat-checkbox formControlName="isDeveloppeur" (click)=" this.jeuForm.get('tagDev')?.setValue(null)">
        {{ 'creerJeuPopup.jeSuisLeDeveloppeurDuJeu' |translate }}
      </mat-checkbox>

      <br>
      <div *ngIf="!jeuForm.get('isDeveloppeur')?.value">
        <p class="pre-line justify">
          {{ 'creerJeuPopup.afinDeTravailler' |translate }}
          <br>
          <mat-icon>warning</mat-icon>
          <i>{{ 'creerJeuPopup.seulLediteurEstPaye' |translate }}</i>

        </p>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label> {{ 'general.tagDuDeveloppeur' |translate }}</mat-label>
          <input formControlName="tagDev" type="text" matInput [formControl]="myControl">
        </mat-form-field>
      </div>
    </form>
  </div>


  <div mat-dialog-actions>
    <button color="warn" mat-raised-button (click)="onNoClick()">{{ 'general.annuler' |translate }}</button>
    <button [disabled]="!canValidate()" mat-raised-button (click)="close()" color="primary">
      {{ 'general.valider' |translate }}
    </button>
  </div>
</div>
