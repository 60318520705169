import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {NotificationService} from "../swagger/api/notification.service";
import {GeneralService} from "../general.service";
import {Notification} from "../swagger/model/notification";
import {MatMenu} from "@angular/material/menu";

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  @Input() notifications: Notification[] = [];
  @Output() updateUnreadCount = new EventEmitter<void>();

  constructor(private notificationService: NotificationService, private generalService: GeneralService) {
  }

  ngOnInit(): void {}

  markAsRead(notification: Notification): void {
    if (!notification.isRead) {
      let notificationMaj: Notification = notification
      notificationMaj.isRead = true
      this.notificationService.updateNotificationReadStatus(notificationMaj, this.generalService.getUserId(), notification.id!).subscribe(() => {
        notification.isRead = notificationMaj.isRead
        this.updateUnreadCount.emit();
      });
    }
  }

  markAsNotRead(notification: Notification): void {
    if (notification.isRead) {
      let notificationMaj: Notification = notification
      notificationMaj.isRead = false
      this.notificationService.updateNotificationReadStatus(notificationMaj, this.generalService.getUserId(), notification.id!).subscribe(() => {
        notification.isRead = notificationMaj.isRead
        this.updateUnreadCount.emit();

      });
    }
  }

  deleteNotification(notification: Notification): void {
    this.notificationService.deleteNotification(this.generalService.getUserId(), notification.id!).subscribe(() => {
      notification.isRead = true;
      this.updateUnreadCount.emit();
      this.notifications = this.notifications.filter(n => n.id !== notification.id!);
      //this.unreadCount = this.notifications.filter(n => !n.isRead).length;
    });
  }

  markAllAsRead(): void {
    this.notifications.forEach(notification => {
      if (!notification.isRead) {
        this.markAsRead(notification);
      }
    });
  }

}
