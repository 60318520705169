import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DialogData} from "../../../../profil/profil.component";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {GeneralService} from "../../../../general.service";
import {HttpClient} from "@angular/common/http";
import {OrganizationService} from "../../../../swagger/api/organization.service";
import {RouteParamService} from "../../../../services/route-param.service";
import {Organization} from "../../../../swagger/model/organization";

@Component({
    selector: 'app-creer-jeu-popup',
    templateUrl: './creer-jeu-popup.component.html',
    styleUrls: ['./creer-jeu-popup.component.scss']
})
export class CreerJeuPopupComponent implements OnInit {
    jeuForm: FormGroup;
    private organization: Organization;
    // @ts-ignore
    myControl = new FormControl<string | Developper>('');

    constructor(public generalService: GeneralService, private http: HttpClient,
                private organizationService: OrganizationService, public dialog: MatDialog, private routeParamService: RouteParamService,
                public dialogRef: MatDialogRef<CreerJeuPopupComponent>,
                @Inject(MAT_DIALOG_DATA) public data: DialogData, private fb: FormBuilder) {
        this.jeuForm = this.fb.group({
            title: [''],
            isDeveloppeur: true,
            tagDev: null,
            tagEditor: null
        })
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    canValidate() {
        if (!(this.jeuForm.get('title')?.value && this.jeuForm.get('title')?.value.length > 0)) {
            return false
        }

        if (this.jeuForm.get("isDeveloppeur")?.value === true) {
            return true;
        } else if (this.jeuForm.get('tagDev')?.value) {
            return true;
        }
        return false;
    }

    ngOnInit(): void {
        this.organizationService.getOrganization(this.routeParamService.getRouteParameterNumber('idOrganization')!).subscribe(organization => {
            this.organization = organization;
            this.jeuForm.get('tagDev')?.setValue(this.organization.tag);

        });
    }

    close() {
        this.jeuForm.get('tagEditor')?.setValue(this.organization.tag);
        if (this.jeuForm.get("isDeveloppeur")?.value === true) {
            this.jeuForm.get('tagDev')?.setValue(this.organization.tag);

        }
        this.dialogRef.close(this.jeuForm.value);
    }
}
