<div class="language-support-container">
  <!--div class="modes">
    <div *ngIf="game?.solo">Solo</div>
    <div *ngIf="game?.localMulti">Local multiplayer</div>
    <div *ngIf="game?.onlineMulti">Online multiplayer</div>
  </div> -->

  <table class="language-table" *ngIf="game?.languages?.length && game?.languages?.length!>0">
    <thead>
    <tr>
      <th></th>
      <th> {{ "general.interface" | translate }}</th>
      <th>{{ "general.audio" | translate }}</th>
      <th>{{ "general.sous-titre" | translate }}</th>
    </tr>
    </thead>
    <tbody class="table-languages">
    <tr *ngFor="let language of visibleLanguages; let i = index">
      <td>{{ language.code! | languageName }}</td>
      <td class="value">
        <div class="checkmark" *ngIf="language.interfaceSupported">&#10004;</div>
      </td>
      <td class="value">
        <div class="checkmark" *ngIf="language.audioSupported">&#10004;</div>
      </td>
      <td class="value">
        <div class="checkmark" *ngIf="language.subtitlesSupported">&#10004;</div>
      </td>
    </tr>
    </tbody>
  </table>

  <button *ngIf="game.languages!.length > 3" mat-icon-button (click)="toggleLanguages()">
    <mat-icon>{{ showAllLanguages ? 'expand_less' : 'expand_more' }}</mat-icon>
  </button>
</div>
