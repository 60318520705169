import { Injectable } from '@angular/core';
import {IndiepassCodesEnum} from "../../model/enums/indiepass-codes-enum";
import {ActionsEnum} from "../snack-bar/enum-actions";
import {PannelsClassEnum} from "../snack-bar/pannels-class-enum";
import {SnackBarService} from "./snack-bar.service";

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private snackBarService : SnackBarService) { }
  checkImageSize(file: File, widht: number, height: number) {
    return new Promise<void>((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        if (img.width === widht && img.height === height) {
          resolve();
        } else {
          this.snackBarService.openSnackBar(IndiepassCodesEnum.IMAGE_SIZE_KO, ActionsEnum.Ok, PannelsClassEnum.error)

          reject();
        }
      };
    });
  }
   isVideoFile(file: File): boolean {
    const videoMimeTypes = [
      'video/mp4',
      'video/avi',
      'video/webm',
      // Ajoutez d'autres types MIME de vidéos si nécessaire
    ];

    return videoMimeTypes.includes(file.type);
  }
}
