import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, switchMap} from 'rxjs';
import {AuthService} from "../swagger/api/auth.service";
import {GeneralService} from "../general.service";
import {RouteParamService} from "../services/route-param.service";

@Injectable({
  providedIn: 'root'
})
export class PartenaireGuard implements CanActivate {
  constructor(private authService: AuthService, private generalService: GeneralService, private router: Router,
              private routeParamService: RouteParamService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.generalService.getOrganizationId() != null && this.generalService.getMemberId() != null
      && this.routeParamService.getRouteParameterNumberInActivatedRouteSnapshot("idOrganization", next) === this.generalService.getOrganizationId()) {
      return true;
    }
    return this.authService.changeOrganizationContext(
      this.routeParamService.getRouteParameterNumberInActivatedRouteSnapshot("idOrganization", next)!).pipe(source => source.pipe(
      switchMap(() => {
        if (this.generalService.getOrganizationId() != null && this.generalService.getMemberId() != null
          && this.routeParamService.getRouteParameterNumberInActivatedRouteSnapshot("idOrganization", next) === this.generalService.getOrganizationId()) {
          return [true];
        } else {
          this.router.navigate(['/']);
          return [false];
        }
      })
    ))
  }


}
