import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TextResponseInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const acceptHeader = req.headers.get('Accept');
    if (acceptHeader === 'text/plain') {
      const clonedRequest = req.clone({ responseType: 'text' as 'json' });
      return next.handle(clonedRequest);
    }
    return next.handle(req);
  }
}
