<div class="white-theme wrapper-popup">
  <h1 mat-dialog-title>{{'popup.modifierRoleMembre' | translate}}</h1>
  <div>

    <form>
      <div class="radio-options">
        <mat-radio-group fxLayoutGap="20px" name="role" aria-label="Select a role" [(ngModel)]="member.role">
          <mat-radio-button value="admin">{{'general.admin' | translate}}</mat-radio-button>
          <mat-radio-button value="regular">{{'general.membre' | translate}}</mat-radio-button>
        </mat-radio-group>
      </div>
    </form>

    <div mat-dialog-actions>
      <button (click)="onNoClick()" mat-raised-button color="warn">
        {{'general.annuler' | translate}}
      </button>
      <button (click)="save()" mat-raised-button  color="primary">
        {{'general.valider' | translate}}
      </button>
    </div>
  </div>
</div>
